import log from "loglevel"
import Lottie from "lottie-react"
import React, { PropsWithChildren } from "react"
import { fixLottieColor } from "../Lottie/Helpers"
import oops from "../Lottie/Oops.json"
import { API } from "../network/API"
import { cls } from "../Shared/cls"
import style from "./ErrorBoundary.module.css"
import { objectOfError } from "./objectOfError"

export class ExtendedError extends Error {
	public reloadPage: boolean

	constructor(msg: string, reloadPage: boolean) {
		super(msg)
		this.reloadPage = reloadPage

		if (Object.setPrototypeOf) {
			Object.setPrototypeOf(this, ExtendedError.prototype)
		}
	}
}

/**
 * This component doesn't have a logger, since if we end up here, something is broken, and we cannot really rely on anything else.
 */

type ErrorBoundaryProps = PropsWithChildren
export type ErrorBoundaryState = {
	hasError: boolean
	reportSuccessful?: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: any) {
		super(props)
		this.state = { hasError: false, reportSuccessful: undefined }
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
		this.setState({
			...this.state,
			hasError: true,
		})
		this.logUncaughtError(event.reason, undefined)
	}

	private async logUncaughtError(error: Error, errorInfo: React.ErrorInfo | undefined) {
		if (process.env.NODE_ENV !== "production") {
			function printStackTrace(error: any) {
				console.error("", error)
				if (error.cause) {
					console.group("  caused by:")
					printStackTrace(error.cause)
					console.groupEnd()
				}
			}

			printStackTrace(error)
		}

		if (process.env.NODE_ENV === "production" || process.env.REACT_APP_POST_ERROR_TO_BACKEND === "true") {
			const errorObject = objectOfError(error)
			const dumpedLocalStorage = this.dumpLocalStorage()
			await API.post("/errors-v1/uncaught", {
				error: errorObject,
				errorInfo: errorInfo,
				location: window.location,
				localStorage: dumpedLocalStorage,
			})
				.then(() => {
					this.setState({ ...this.state, reportSuccessful: true })
				})
				.catch((failure) => {
					this.setState({ ...this.state, reportSuccessful: false })
					log.error(failure)
				})
				.finally(() => {
					if (errorObject?.name === "ChunkLoadError") {
						window.location.reload()
					}
				})
		}

		let reloadPage = false
		if (error instanceof ExtendedError) {
			reloadPage = error.reloadPage
		}

		if (reloadPage) {
			window.location.reload()
		}
	}

	private dumpLocalStorage() {
		try {
			let redactedLocalStorageDump = Object.entries(localStorage).map(([key, value]) => {
				if (key.endsWith(".auth-token")) {
					return [key, "*******"]
				}
				return [key, value]
			})
			return Object.fromEntries(redactedLocalStorageDump)
		} catch (error) {
			//Ignore
			return null
		}
	}

	componentDidMount() {
		window.addEventListener("unhandledrejection", this.promiseRejectionHandler)
	}

	componentWillUnmount() {
		window.removeEventListener("unhandledrejection", this.promiseRejectionHandler)
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		this.logUncaughtError(error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className={style.wrapper}>
					<h1 className={style.header}>
						Oops!
						<br />
						Något gick fel.
					</h1>
					<p className={style.description}>
						Vi håller på att åtgärda problemet.
						<br />
						Testa att{" "}
						<button type="button" onClick={() => window.location.reload()} style={{ cursor: "pointer" }}>
							ladda om sidan
						</button>{" "}
						eller kom tillbaka senare.
					</p>
					<div className={style.lottieWrapper}>
						<Lottie animationData={fixLottieColor(oops)} autoPlay loop />
					</div>
					<div
						className={cls(
							style.reportStatus,
							{ [style.reportSuccessful]: this.state.reportSuccessful === true },
							{ [style.reportFailed]: this.state.reportSuccessful === false },
						)}
					/>
				</div>
			)
		}

		return this.props.children
	}
}
