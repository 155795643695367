import { faClock } from "@fortawesome/free-regular-svg-icons"
import { faArrowsLeftRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useJsApiLoader } from "@react-google-maps/api"
import { exhaustive } from "exhaustive"
import { isArray, some } from "lodash"
import { DateTime } from "luxon"
import { useState } from "react"
import { v4 } from "uuid"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { PreferredVATRenderPolicy } from "../../../Client/consumerCatalogConfigurationTypes"
import { OrderDateTime } from "../../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { contactInformationCreatedOrder } from "../../../CustomerPortal/OrderDetails/OrderDetails"
import { OrderPriceDetails } from "../../../CustomerPortal/OrderDetails/OrderPriceDetails/OrderPriceDetails"
import { DatumIcon, ZonkartaIcon } from "../../../Icons/Icon"
import { ProductImageType } from "../../../ProductDefinitions"
import { cls } from "../../../Shared/cls"
import {
	addAddressAndExactDeliveryMarkersToMap,
	GoogleMapComponent,
	maybeSetMapBoundsForExactDelivery,
} from "../../../Shared/GoogleMapComponent/GoogleMapComponent"
import { when } from "../../../Shared/when"
import { FinalizeButton } from "../../Components/Form/Buttons/Buttons"
import orderConfirmStyle from "../../Components/OrderConfirmCheckout/OrderConfirmCheckout.module.css"
import { ProductImage } from "../../Components/ProductImage/ProductImage"
import { Mbt } from "../../Components/Text/Mbt/Mbt"
import { SbtH2 } from "../../Components/Text/SbtH2/SbtH2"
import { SbtH4 } from "../../Components/Text/SbtH4/SbtH4"
import { productServiceUnitToHumanText } from "../../OrderContainer/Logic"
import orderContainerStyle from "../../OrderContainer/OrderContainer.module.css"
import { googleMiniMapOptions, libraries } from "../../ProjectInputModule/ProjectInputModule"
import { unitFormatter } from "../../unit-formatter"
import { AnonymousOrderCollection } from "../AnonymousOrderResponse"
import style from "./OrderCompletedSummary.module.css"

type Props = {
	collection: AnonymousOrderCollection
}

/**
 * Takes a string and tries to parse it as yyyy-MM-dd (default format)
 * Invalid input give an empty return value
 * If the input is valid it's formatted in swedish
 * @param date
 * @param format
 */
export function getDateAsHugeFormat(date?: string, format: string = "yyyy-MM-dd"): string {
	if (!date) {
		return ""
	}

	const parsed = DateTime.fromFormat(date, format, {
		zone: "Europe/Stockholm",
	})

	if (!parsed.isValid) {
		return ""
	}

	return parsed.setLocale("sv-SE").toLocaleString(DateTime.DATE_HUGE)
}
export function completedOrderItemDateElementContent(date: OrderDateTime) {
	if (date.date) {
		return <span>{getDateAsHugeFormat(date.date)}</span>
	} else if (date.dateSlot) {
		return (
			<span>
				<span>{date.dateSlot.name}</span>
				<div style={{ fontSize: "12px", display: "flex", gap: "2px" }}>
					<span>{date.dateSlot.fromDate}</span>{" "}
					{date.dateSlot.fromDate !== date.dateSlot.toDate ? (
						<>
							<FontAwesomeIcon icon={faArrowsLeftRight} />
							<span>{date.dateSlot.toDate}</span>
						</>
					) : null}
				</div>
			</span>
		)
	}

	return null
}

export const OrderCompletedSummary = ({ collection }: Props) => {
	const client = useClient()

	const [showSummarySection, setShowSummarySection] = useState<boolean>(false)

	const { isLoaded: isGoogleMapsLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		libraries: libraries,
		language: "sv",
	})

	return (
		<div className={style.wrapper} key={collection.collectionNumber}>
			<div className={style.header}>Beställningen har skickats till {client.clientInfo.clientName}</div>
			<div className={style.subHeader}>En bekräftelse skickas till din mail när beställningen är godkänd</div>
			<div className={style.subHeader}>
				<strong>Viktigt! Mailet kan hamna i din skräppost!</strong>
			</div>

			<div className={style.flexCenter} style={{ marginTop: "40px" }}>
				<FinalizeButton
					className={style.showSummaryButton}
					onClick={() => {
						setShowSummarySection(!showSummarySection)
					}}>
					{showSummarySection ? "Dölj summering" : "Se summering"}
				</FinalizeButton>
			</div>

			{showSummarySection ? (
				<div className={style.summarySectionWrapper}>
					{collection.orders.map((order) => {
						return (
							<div key={v4()} className={orderConfirmStyle.reviewSection}>
								<SbtH2 styles={{ marginBottom: "10px", wordBreak: "break-all" }}>Leveransinformation</SbtH2>

								<div className={orderConfirmStyle.addressAndMiniMapWrapper}>
									<div className={orderConfirmStyle.address}>
										<strong>{order.address.street}, </strong>
										{order.address.zipcode ? order.address.zipcode + " " : ""}
										{order.address.city}
									</div>
									{order.marking ? (
										<div
											className={orderConfirmStyle.markingAndContactInfoText}
											style={{ marginTop: "10px" }}>
											<strong>{order.marking}</strong>
										</div>
									) : null}
									<div
										className={orderConfirmStyle.markingAndContactInfoText}
										style={{ marginTop: order.marking ? undefined : "10px" }}>
										<div>{contactInformationCreatedOrder(order)}</div>
									</div>
									{isGoogleMapsLoaded && order.address.coordinates ? (
										<>
											<hr className={orderConfirmStyle.mapAndAddressDelimiter} />
											<GoogleMapComponent
												mapContainerClassName={cls(orderConfirmStyle.mapContainer)}
												center={order.address.coordinates}
												zoom={19}
												onLoad={(map) => {
													addAddressAndExactDeliveryMarkersToMap(map, order)
													maybeSetMapBoundsForExactDelivery(map, order)
												}}
												options={googleMiniMapOptions()}
											/>
										</>
									) : null}
								</div>

								<div className={orderConfirmStyle.orderItemDateAndTimeWrapper}>
									<div className={orderConfirmStyle.orderItemDateAndTime}>
										<DatumIcon className={orderConfirmStyle.dateAndTimeInfoIcon} size={18} />
										{completedOrderItemDateElementContent(order.datetime)}
									</div>
									<div className={orderConfirmStyle.orderItemDateAndTime}>
										<FontAwesomeIcon className={orderConfirmStyle.timeIcon} icon={faClock} />
										<span>{order.datetime.timeExact || order.datetime.timeDescription}</span>
									</div>
								</div>

								<div
									className={orderContainerStyle.orderItemZone}
									style={{ backgroundColor: "var(--module-box-color)" }}>
									<div className={orderContainerStyle.orderItemZoneName}>
										{order.transportZoneName ? (
											<>
												Transportzon: <strong>{order.transportZoneName}</strong>
											</>
										) : (
											<strong>Ingen transportzon identifierad</strong>
										)}
									</div>
									<div style={{ display: "grid" }}>
										<ZonkartaIcon
											className={orderContainerStyle.zoneMapIconWrapper}
											iconClassName={orderContainerStyle.zoneMapIcon}
											size={44}
										/>
									</div>
								</div>

								<br />

								<div className={orderConfirmStyle.articleTextWrapper}>
									<SbtH2>Varor</SbtH2>
								</div>
								<div>
									{order.products.map((product) => {
										return (
											<div key={v4()} className={orderConfirmStyle.productInfoBox}>
												<div className={orderConfirmStyle.productInfoBoxNameAndCategory}>
													<span className={orderConfirmStyle.productInfoBoxMarkingText}>
														<Mbt>{unitFormatter(product.productName)}</Mbt>
													</span>
													{product.packagingMethod ? (
														<Mbt>{product.packagingMethod.name}</Mbt>
													) : null}
													{product.serviceName ? <Mbt>{product.serviceName}</Mbt> : null}
													{product.wasteName ? <Mbt>{product.wasteName}</Mbt> : null}
												</div>
												<div className={orderConfirmStyle.amountCellWrapper}>
													<span className={orderConfirmStyle.tableCellWrapper}>
														<span className={orderConfirmStyle.orderItemProductAmount}>
															{product.amount}
															&nbsp;
															{productServiceUnitToHumanText(product.unit)}
														</span>
													</span>
												</div>
												<div style={{ display: "table" }}>
													<span className={orderConfirmStyle.tableCellWrapper}>
														{product.productImages ? (
															"url" in product.productImages.main ? (
																<div
																	style={{
																		display: "flex",
																		height: "100%",
																		maxHeight: "80px",
																	}}
																	className={orderConfirmStyle.basketItemProductImage}>
																	<img
																		style={{
																			maxWidth: "100%",
																			height: "100%",
																			margin: "0 auto",
																			objectFit: "contain",
																		}}
																		src={product.productImages.main.url}
																		alt="Produktbild"
																	/>
																</div>
															) : (
																<ProductImage
																	client={client}
																	categoryName={product.categoryName}
																	image={
																		ProductImageType[
																			product.productImages.main.typeImage
																		]
																	}
																	wrapperClassName={
																		orderConfirmStyle.basketItemProductImage
																	}
																/>
															)
														) : (
															<ProductImage
																image={undefined}
																categoryName={product.categoryName}
																className={style.productImage}
															/>
														)}
													</span>
												</div>
											</div>
										)
									})}
								</div>

								{order.orderArticles.length > 0 ||
								some(order.products, (product) => product.productArticles.length > 0) ? (
									<>
										<div
											style={{ marginTop: "35px", justifyContent: "flex-start", gap: "10px" }}
											className={orderConfirmStyle.articleTextWrapper}>
											<SbtH2>Priser</SbtH2>{" "}
											{order.consumerGroupSettings &&
												exhaustive(order.consumerGroupSettings, "type", {
													Contract: () => {
														return null
													},
													Prepay: (it) => {
														return (
															<SbtH4 styles={{ marginBottom: 0, marginTop: "auto" }}>
																(
																{when(it.preferredVATRenderPolicy, {
																	[PreferredVATRenderPolicy.ExcludeVAT]: () => {
																		return "exkl. moms"
																	},
																	[PreferredVATRenderPolicy.IncludeVAT]: () => {
																		return "inkl. moms"
																	},
																})}
																)
															</SbtH4>
														)
													},
												})}
										</div>
										<div>
											<OrderPriceDetails order={order} />
										</div>
									</>
								) : null}

								{isArray(order.files) ? (
									<div className={style.attachments}>
										<div className={orderConfirmStyle.articleTextWrapper}>
											<SbtH2>Uppladdade bilagor</SbtH2>
										</div>
										<div className={style.orderImagesWrapper}>
											{order.files.map((file) => {
												if (file.type === "AnonymousOrderFileImage") {
													return (
														<img
															key={file.url}
															src={file.url}
															alt=""
															onClick={() => {
																window.open(file.url, "_blank")
															}}
														/>
													)
												} else {
													return null
												}
											})}
										</div>
									</div>
								) : null}
							</div>
						)
					})}
				</div>
			) : null}
		</div>
	)
}
