import { exhaustive } from "exhaustive"
import { map } from "lodash"
import { FC } from "react"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { ProductSelectionMode } from "../../../Client/FeatureTypes"
import { cls } from "../../../Shared/cls"
import { MbactH1 } from "../../Components/Text/MbactH1/MbactH1"
import style from "../OrderContainer.module.css"

type Props = {
	selectedCategory: string | null
	selectedServiceId: string | null
	onSetSelectedServiceId: (selectedCategory: string, serviceId: string) => void
}

export const ServiceSelection: FC<Props> = ({ selectedCategory, selectedServiceId, onSetSelectedServiceId }) => {
	const client = useClient()

	let category = selectedCategory ? client.findCategoryByName(selectedCategory) : null

	if (!category) {
		return null
	}

	return exhaustive(category, "type", {
		GoodsCategory: () => {
			return null
		},
		WasteCategory: (it) => {
			const fullPage =
				client.features.orderUiProductSelectionMode === ProductSelectionMode.FullPage &&
				!!it.productSelectionConfig &&
				it.productSelectionConfig.steps.length > 0

			const showServiceSelector = it.serviceIds.length > 1 && !fullPage

			if (showServiceSelector) {
				return (
					<div className={style.section}>
						<div className={style.sectionH1}>
							<MbactH1>Tjänst</MbactH1>
						</div>
						<div className={style.serviceCell}>
							{map(it.services, (x, index) => {
								return selectedServiceId === null || selectedServiceId !== x.id ? (
									<div
										key={x.id + index}
										className={cls(style.sectionBox, style.serviceSelector)}
										onClick={() => {
											onSetSelectedServiceId(category!.name, x.id)
										}}>
										<span className={style.serviceName}>{x.name}</span>
									</div>
								) : (
									<div
										key={x.name + index}
										className={cls(style.sectionBox, style.serviceSelector, style.selectedSectionBox)}>
										<span className={style.serviceName}>{x.name}</span>
									</div>
								)
							})}
						</div>
					</div>
				)
			}

			return null
		},
	})
}
