import { faArrowRightArrowLeft, faArrowRightToBracket, faCashRegister } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	CustomersIcon,
	EmailIcon,
	HandPointingIcon,
	InternetIcon,
	ListaOrdersIcon,
	PinNoFillIcon,
	TelefonIcon,
} from "Icons/Icon"
import { ReactComponent as PoweredBySkrappy } from "Orders/Layout/PoweredBySkrappy.svg"
import { ReactComponent as SkrappyLogoMini } from "Orders/Layout/SkrappyLogoMini.svg"
import { FC, useEffect, useRef, useState } from "react"
import { z } from "zod"
import { AbsolutCentered } from "../AbsolutCentered/AbsolutCentered"
import { MeResponseConsumerType } from "../Auth/Auth.types"
import { useAuth } from "../Auth/AuthContext"
import { useClient } from "../Client/ClientAndUserProvider"
import { ConsumerProjectMode, useConsumerCatalog } from "../Client/ConsumerCatalogContext"
import { LoginDialog } from "../CustomerPortal/Login/LoginDialog"
import { SelectActiveConsumer } from "../CustomerPortal/SelectActiveConsumer/SelectActiveConsumer"
import { OrganisationsnummerIcon } from "../Icons/Icon"
import { NavigatorLink } from "../Navigator/NavigatorLink"
import { useNavigator } from "../Navigator/useNavigator"
import { API } from "../network/API"
import { removeModalOpenClass } from "../Orders/Components/ModulePopup/ModulePopup"
import { convertTextToInitials } from "../Orders/Helpers"
import { PwaInstallPrompt, PwaManifest } from "../PwaInstallPrompt/PwaInstallPrompt"
import { GetQuantityCalculators } from "../QuantityCalculator/QuantityCalculator"
import { cls } from "../Shared/cls"
import { EventQueue } from "../Shared/eventQueue"
import { useBrandedLocalStorage } from "../Shared/useBrandedLocalStorage"
import style from "./Sidebar.module.css"
import { SidebarBottomAction, SidebarBottomActionWrapper } from "./SidebarBottomAction"

type Props = {
	display: boolean
	onHide: () => void
	onShow: () => void
}
export const Sidebar: FC<Props> = ({ display, onHide, onShow }) => {
	const auth = useAuth()
	const client = useClient()
	const navigator = useNavigator()
	const consumerCatalog = useConsumerCatalog()

	const [showLogin, setShowLogin] = useState(false)
	const [loginRedirectTo, setLoginRedirectTo] = useState<string | null>(null)
	const [tick, setTick] = useState<number>(0)
	const [showSelectActiveConsumer, setShowSelectActiveConsumer] = useState(false)
	const showSelectActiveConsumerRef = useRef(showSelectActiveConsumer)
	showSelectActiveConsumerRef.current = showSelectActiveConsumer
	const [clientLogoUrl, setClientLogoUrl] = useState<string | null>(null)
	const [isOrderTab, setIsOrderTab] = useState(false)
	const [hasQuantityCalculators, setHasQuantityCalculators] = useState(false)

	const [selectedConsumerId, setSelectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		API.getWithRetries<PwaManifest>(`/pwa-manifest-v1/${client.identifier}`).then(
			(resp) => {
				const url =
					resp.icons.find((x) => x.sizes === "192x192")?.src ||
					resp.icons.find((x) => x.sizes === "180x180")?.src ||
					resp.icons.find((x) => x.sizes === "32x32")?.src

				setClientLogoUrl(url ?? null)
			},
			() => {},
		)

		const closeAllModalsEventId = EventQueue.addEventListener("closeAllModals", () => {
			setShowLogin(false)
			setLoginRedirectTo(null)
			setShowSelectActiveConsumer(false)
		})
		const openLoginEventId = EventQueue.addEventListener("openLoginModal", (obj) => {
			setShowLogin(true)
			if (obj.hasOwnProperty("redirectTo")) {
				setLoginRedirectTo((obj as any).redirectTo)
			}
		})
		document.addEventListener("click", handleClickOutside, true)
		return () => {
			document.removeEventListener("click", handleClickOutside, true)
			EventQueue.removeEventListener(closeAllModalsEventId)
			EventQueue.removeEventListener(openLoginEventId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		API.getWithRetries<GetQuantityCalculators>(`/order-ui/quantity-calculators-v1/${client.identifier}`).then(
			(res) => {
				setHasQuantityCalculators(res.calculators.length > 0)
			},
			() => {},
		)
	}, [client.identifier])

	useEffect(() => {
		const eventId = EventQueue.addEventListener("show-select-consumer", () => {
			setShowSelectActiveConsumer(true)
		})

		return () => {
			EventQueue.removeEventListener(eventId)
		}
	}, [])

	function handleClickOutside(event: Event) {
		const headerSidebarIconElement = document.getElementById("mainLayoutHeaderSidebarIcon")
		if (
			ref.current &&
			!ref.current.contains(event.target as Node) &&
			headerSidebarIconElement &&
			!headerSidebarIconElement.contains(event.target as Node) &&
			!showSelectActiveConsumerRef.current
		) {
			if (onHide) {
				onHide()
			}
		}
	}

	function sidebarItems() {
		if (!auth.IsLoggedIn || !auth.Me) {
			return (
				<>
					{orderPageItem()}
					{otherLinks()}
					{clientInformationSection()}
				</>
			)
		}

		return (
			<>
				{orderPageItem()}
				{auth.Me.type === "Consumer" ? consumerItems() : null}
				{otherLinks()}
				{clientInformationSection()}
			</>
		)
	}

	function orderPageItem(): JSX.Element {
		return (
			<NavigatorLink
				to={`order`}
				end={true}
				className={({ isActive }) => {
					setTimeout(() => {
						setIsOrderTab(isActive)
					}, 100)
					return cls(style.menuItem, style.orderPageMenuItem, {
						[style.hiddenMenuItem]: isActive,
					})
				}}
				onClick={() => {
					if (onHide) {
						onHide()
					}
				}}
				style={{ gap: display ? undefined : "2px" }}>
				<span className={style.menuItemIcon}>
					<HandPointingIcon size={display ? 22 : 26} />
				</span>
				<span className={cls(style.menuItemTextStrong)}>Bokningssida</span>
				<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>Boka</span>
			</NavigatorLink>
		)
	}

	function clientInformationSection() {
		return (
			<>
				{!isOrderTab ? <hr className={style.loggedInOnly} /> : null}
				<div
					className={style.clientInformationSection}
					style={{
						paddingTop: auth.IsLoggedInConsumer || !isOrderTab ? "10px" : undefined,
					}}>
					<div className={cls(style.menuItem, style.bigMenuItem)}>
						<span
							className={cls(style.profilePicture, style.clientLogo)}
							onClick={() => {
								onShow()
							}}>
							<img src={clientLogoUrl || undefined} alt="" />
						</span>
						<span
							className={style.menuItemTextStrong}
							style={{ display: "flex", justifyContent: "space-between", paddingRight: "15px", gap: "10px" }}>
							<span>
								{client.clientInfo.clientName}
								<br />
								<span style={{ fontWeight: 400, fontSize: "16px" }}>
									{client.clientInfo.organizationNumber}
								</span>
							</span>
						</span>
					</div>
					{clientItems()}
					<span
						className={style.clientInformation}
						style={
							auth.IsLoggedInClient && display
								? {
										paddingTop: "10px",
										display: "block",
								  }
								: undefined
						}>
						{client.clientInfo.contact.phoneNumber ? (
							<a href={"tel:" + client.clientInfo.contact.phoneNumber} className={style.menuItem}>
								<span className={style.menuItemIcon}>
									<TelefonIcon size={22} />
								</span>
								<span className={cls(style.menuItemTextStrong, style.oneLineClamp)}>
									{client.clientInfo.contact.phoneNumber}
								</span>
								<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>Ring</span>
							</a>
						) : null}
						{client.clientInfo.contact.email ? (
							<a href={"mailto:" + client.clientInfo.contact.email} className={style.menuItem}>
								<span className={style.menuItemIcon}>
									<EmailIcon size={22} />
								</span>
								<span className={cls(style.menuItemTextStrong, style.oneLineClamp)}>
									{client.clientInfo.contact.email}
								</span>
								<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>
									Maila
								</span>
							</a>
						) : null}
						{client.clientInfo.homepage ? (
							<a
								rel="noreferrer"
								href={client.clientInfo.homepage}
								target="_blank"
								className={style.menuItem}>
								<span className={style.menuItemIcon}>
									<InternetIcon size={22} />
								</span>
								<span className={cls(style.menuItemTextStrong, style.oneLineClamp)}>
									{client.clientInfo.homepage}
								</span>
								<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>
									Hemsida
								</span>
							</a>
						) : null}
						{client.clientInfo.address?.street || client.clientInfo.address?.city ? (
							<a
								rel="noreferrer"
								href={
									"https://maps.google.com/?q=" +
									encodeURIComponent(
										`${client.clientInfo.address?.street}${
											client.clientInfo.address?.city ? ", " + client.clientInfo.address?.city : ""
										}`,
									)
								}
								target="_blank"
								className={style.menuItem}>
								<span className={style.menuItemIcon}>
									<PinNoFillIcon size={22} />
								</span>
								<span className={style.menuItemTextStrong}>
									{client.clientInfo.address?.street}
									{client.clientInfo.address?.city ? ", " + client.clientInfo.address?.city : null}
								</span>
								<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>
									Adress
								</span>
							</a>
						) : null}
					</span>
				</div>
			</>
		)
	}

	function clientItems() {
		if (!auth.IsLoggedInClient) {
			return null
		}

		return (
			<>
				<NavigatorLink
					to={`my-pages/customer-details`}
					className={({ isActive }) => {
						return cls(style.menuItem, {
							[style.selectedMenuItem]: isActive,
						})
					}}
					onClick={() => {
						if (onHide) {
							onHide()
						}
					}}>
					<span className={style.menuItemIcon}>
						<OrganisationsnummerIcon size={22} />
					</span>
					<span className={style.menuItemTextStrong}>Mitt företag</span>
					<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>Företag</span>
				</NavigatorLink>
				<NavigatorLink
					to={`my-pages/orders`}
					onClick={() => {
						if (onHide) {
							onHide()
						}
					}}
					className={({ isActive }) => {
						return cls(style.menuItem, {
							[style.selectedMenuItem]: isActive,
						})
					}}>
					<span className={style.menuItemIcon}>
						<ListaOrdersIcon size={22} />
					</span>
					<span className={style.menuItemTextStrong}>Lista ordrar</span>
					<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>Ordrar</span>
				</NavigatorLink>
				<NavigatorLink
					to={`my-pages/consumers`}
					className={({ isActive }) => {
						return cls(style.menuItem, {
							[style.selectedMenuItem]: isActive,
						})
					}}
					onClick={() => {
						if (onHide) {
							onHide()
						}
					}}>
					<span className={style.menuItemIcon}>
						<CustomersIcon size={22} />
					</span>
					<span className={style.menuItemTextStrong}>Kunder</span>
					<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>Kunder</span>
				</NavigatorLink>
				<hr />
			</>
		)
	}

	function consumerItems() {
		if (!auth.Me || !auth.IsLoggedInConsumer) {
			return null
		}

		if (!selectedConsumerId) {
			setSelectedConsumerId(auth.Me.consumers[0]?.id)
			return null
		}

		const consumer = auth.Me.consumers.find((x) => x.id === selectedConsumerId)
		if (!consumer) {
			setSelectedConsumerId(auth.Me.consumers[0]?.id)
			return null
		}

		return (
			<>
				{!isOrderTab ? <hr /> : null}
				<div
					className={cls(style.menuItem, style.bigMenuItem)}
					style={{ marginTop: !isOrderTab ? "20px" : "30px" }}>
					<span
						className={style.profilePicture}
						onClick={() => {
							onShow()
						}}>
						{consumer.profilePicture ? (
							<img
								src={consumer.profilePicture}
								onError={() => {
									consumer.profilePicture = undefined
									setTick((tick) => tick + 1)
								}}
								alt=""
							/>
						) : (
							<AbsolutCentered>
								<span style={{ color: "white" }}>{convertTextToInitials(consumer.name)}</span>
							</AbsolutCentered>
						)}
					</span>
					<span
						className={style.menuItemTextStrong}
						style={{ display: "flex", justifyContent: "space-between", paddingRight: "15px", gap: "10px" }}>
						<span className={style.userIdentifierWrapper}>
							<span title={consumer.name}>{consumer.name}</span>
							<br />
							<span title={consumer.identifier} style={{ fontWeight: 300, fontSize: "16px" }}>
								{consumer.identifier}
							</span>
						</span>
						{auth.Me.consumers.length > 1 ? (
							<FontAwesomeIcon
								className={style.switchConsumerIcon}
								style={{ alignSelf: "center" }}
								onClick={() => {
									setShowSelectActiveConsumer(true)
									if (onHide) {
										onHide()
									}
								}}
								icon={faArrowRightArrowLeft}
							/>
						) : null}
					</span>
				</div>
				<NavigatorLink
					to={`my-pages/customer-details`}
					className={({ isActive }) => {
						return cls(style.menuItem, {
							[style.selectedMenuItem]: isActive,
						})
					}}
					onClick={() => {
						if (onHide) {
							onHide()
						}
					}}>
					<span className={style.menuItemIcon}>
						<OrganisationsnummerIcon size={22} />
					</span>
					<span className={style.menuItemTextStrong}>
						{consumer.type === MeResponseConsumerType.Company ? "Mitt företag" : "Mitt kundkonto"}
					</span>
					<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>
						{consumer.type === MeResponseConsumerType.Company ? "Företag" : "Kund"}
					</span>
				</NavigatorLink>
				<NavigatorLink
					to={`my-pages/orders`}
					onClick={() => {
						if (onHide) {
							onHide()
						}
					}}
					className={({ isActive }) => {
						return cls(style.menuItem, {
							[style.selectedMenuItem]: isActive,
						})
					}}>
					<span className={style.menuItemIcon}>
						<ListaOrdersIcon size={22} />
					</span>
					<span className={style.menuItemTextStrong}>Lista ordrar</span>
					<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>Ordrar</span>
				</NavigatorLink>
				<NavigatorLink
					to={`my-pages/projects`}
					onClick={() => {
						if (onHide) {
							onHide()
						}
					}}
					className={({ isActive }) => {
						return cls(style.menuItem, {
							[style.selectedMenuItem]: isActive,
						})
					}}>
					<span className={style.menuItemIcon}>
						<ListaOrdersIcon size={22} />
					</span>
					<span className={style.menuItemTextStrong}>
						{consumerCatalog.features.projectMode === ConsumerProjectMode.FullProject ? "Projekt" : "Adresser"}
					</span>
					<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>
						{consumerCatalog.features.projectMode === ConsumerProjectMode.FullProject ? "Projekt" : "Adresser"}
					</span>
				</NavigatorLink>
				{isOrderTab ? <hr /> : null}
			</>
		)
	}

	function otherLinks() {
		if (!hasQuantityCalculators) {
			return null
		}
		return (
			<>
				{isOrderTab ? null : <hr />}
				<NavigatorLink
					to={`/${client.identifier}/quantity-calculator`}
					style={{ marginTop: "10px" }}
					className={({ isActive }) => {
						return cls(style.menuItem, {
							[style.selectedMenuItem]: isActive,
						})
					}}
					onClick={() => {
						if (onHide) {
							onHide()
						}
					}}>
					<span className={style.menuItemIcon}>
						<FontAwesomeIcon icon={faCashRegister} />
					</span>
					<span className={style.menuItemTextStrong}>Mängdberäknare </span>
					<span className={cls(style.collapsedOnly, style.clientInformationCollapsedText)}>Beräkna</span>
				</NavigatorLink>
				{isOrderTab ? <hr /> : null}
			</>
		)
	}

	function sidebarLoginButton() {
		if (auth.IsLoggedIn) {
			return null
		}

		if (client.features.orderUiLoginPlacement !== "SideMenuBottom") {
			return null
		}

		return (
			<>
				<SidebarBottomAction
					title={"Administratör?"}
					narrowTitle={"Admin?"}
					buttonText={"Logga in"}
					buttonIcon={<FontAwesomeIcon icon={faArrowRightToBracket} />}
					buttonAction={() => {
						EventQueue.dispatchEvent("openLoginModal", { redirectTo: "order" })
					}}
				/>
			</>
		)
	}

	function loginModal() {
		return (
			<>
				{showLogin ? (
					<LoginDialog
						onClose={(reason) => {
							setShowLogin(false)
							removeModalOpenClass()

							if (reason === "loggedIn") {
								setTick(tick + 1)
								setTimeout(() => {
									navigator.open(loginRedirectTo || "my-pages/orders")
									setLoginRedirectTo(null)
								}, 200)
							}
						}}
					/>
				) : null}
			</>
		)
	}

	return (
		<>
			{loginModal()}
			{showSelectActiveConsumer ? (
				<SelectActiveConsumer
					onClose={() => {
						setShowSelectActiveConsumer(false)
					}}
				/>
			) : null}
			<div
				ref={ref}
				className={cls(
					style.sidebarWrapper,
					{
						[style.loggedOut]: !auth.IsLoggedIn,
					},
					{
						[style.loggedIn]: auth.IsLoggedIn,
					},
					{
						[style.sidebarWrapperShown]: display,
					},
					{
						[style.sidebarWrapperHidden]: !display,
					},
				)}>
				<div className={style.sidebarInnerWrapper}>
					<div className={style.menuItemsWrapper}>{sidebarItems()}</div>

					<div className={style.sidebarBottomWrapper}>
						<div className={style.sidebarBottomActions}>
							<SidebarBottomActionWrapper>
								{sidebarLoginButton()}
								<PwaInstallPrompt isSidebar={true} />
							</SidebarBottomActionWrapper>
						</div>
						<PoweredBySkrappy className={style.sidebarLogo} />
						<SkrappyLogoMini className={style.sidebarLogoMini} />
					</div>
				</div>
			</div>
		</>
	)
}
