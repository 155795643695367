import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import { useClient } from "Client/ClientAndUserProvider"
import { GetUserInvitation, InvitationDetails, UserDetails } from "CustomerPortal/UserInvitations/UsersInvitations"
import { API } from "network/API"
import { FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { ModulePopup, removeModalOpenClass } from "Orders/Components/ModulePopup/ModulePopup"
import { SbtH2 } from "Orders/Components/Text/SbtH2/SbtH2"
import { SbtH4 } from "Orders/Components/Text/SbtH4/SbtH4"
import { SbtInvalid } from "Orders/Components/Text/SbtInvalid/SbtInvalid"
import { SbtRHFError } from "Orders/Components/Text/SbtInvalid/SbtRHFError"
import { invalidPhoneNumberMessage, validatePhoneNumber } from "Orders/order-data-model"
import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { LabelledToggle } from "Shared/LabelledToggle/LabelledToggle"
import { z } from "zod"
import style from "./SendUserInvitation.module.css"

const phoneNumberSchema = z.object({
	phoneNumber: z
		.string()
		.min(3, invalidPhoneNumberMessage)
		.refine(validatePhoneNumber, { message: invalidPhoneNumberMessage }),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	message: z.string().optional(),
})

const emailSchema = z.object({
	email: z.string().email(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	message: z.string().optional(),
})

const UserInvitationSchema = emailSchema.or(phoneNumberSchema)

type UserInvitationType = z.input<typeof UserInvitationSchema>

export type Props = {
	onClose?: () => void
	onCreate: (newUserRequest: GetUserInvitation) => void
	externalButton?: boolean
	showModal?: boolean
	consumerRef: string
}

enum SendMethod {
	Email,
	PhoneNumber,
}

export const SendUserRequestModal: FC<Props> = ({
	onCreate,
	externalButton,
	showModal: extShowModal,
	onClose: extOnClose,
	consumerRef,
}) => {
	const client = useClient()
	const [showModal, setShowModal] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [sendUserInvitationMethod, setSendUserInvitationMethod] = useState<SendMethod>(SendMethod.Email)

	useEffect(() => {
		setShowModal(!!extShowModal)
	}, [extShowModal])

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isValid },
	} = useForm<UserInvitationType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(UserInvitationSchema)(data, context, options)
		},
	})

	const onSubmit: SubmitHandler<UserInvitationType> = async (data: UserInvitationType) => {
		setIsSubmitting(true)
		const userDetails: UserDetails = {
			email: "email" in data ? data.email : undefined,
			phoneNumber: "phoneNumber" in data ? data.phoneNumber : undefined,
			firstName: data.firstName,
			lastName: data.lastName,
		}
		const postBody: InvitationDetails = {
			userDetails: userDetails,
			message: data.message,
		}

		return API.postWithRetries<GetUserInvitation>(
			`/customer-portal/user-invitation-v1/${client.identifier}/${consumerRef}`,
			postBody,
		)
			.then((res: GetUserInvitation) => {
				setIsSubmitting(false)
				onCreate(res)
				reset()
				setShowModal(false)
			})
			.catch(() => {
				setIsSubmitting(false)
			})
	}

	function getNotificationError() {
		if ("phoneNumber" in errors) {
			return <SbtRHFError error={errors.phoneNumber} />
		} else if ("email" in errors) {
			return <SbtRHFError error={errors.email} />
		} else {
			return <SbtInvalid></SbtInvalid>
		}
	}

	function toggleSendMethod() {
		setSendUserInvitationMethod(
			sendUserInvitationMethod === SendMethod.PhoneNumber ? SendMethod.Email : SendMethod.PhoneNumber,
		)
	}

	return (
		<div>
			{!externalButton ? (
				<button onClick={() => setShowModal(true)} className={style.newInviteButton}>
					<span className={style.newInviteButtonText}>Skicka inbjudan</span>
					<FontAwesomeIcon icon={faPlus} />
				</button>
			) : null}
			{showModal ? (
				<ModulePopup
					className={style.modal}
					onClose={() => {
						removeModalOpenClass()
						reset()
						setShowModal(false)
						if (extOnClose) {
							extOnClose()
						}
					}}>
					<div className={style.wrapper}>
						<div className={style.newSendInviteModalHeader}>
							<SbtH2>Skicka inbjudan</SbtH2>
						</div>
						<form
							style={{ overflowY: "auto" }}
							key="form_add_user_request"
							id="form_add_user_request"
							onSubmit={handleSubmit(onSubmit)}>
							<div style={{ overflowY: "auto" }}>
								{client.features.allowUserInvitesToBeSentWithSms ? (
									<LabelledToggle
										currentValue={sendUserInvitationMethod === SendMethod.PhoneNumber}
										onChange={() => toggleSendMethod()}
										falseLabel="E-post"
										trueLabel="Telefonnummer"
									/>
								) : null}

								{sendUserInvitationMethod === SendMethod.PhoneNumber ? (
									<label>
										<SbtH4>Telefonnummer*</SbtH4>
										<input
											className={style.newSendInviteModalInputField}
											{...register("phoneNumber")}
											placeholder="Fyll i telefonnummer"
										/>
										{getNotificationError()}
									</label>
								) : (
									<label>
										<SbtH4>E-post*</SbtH4>
										<input
											className={style.newSendInviteModalInputField}
											{...register("email")}
											placeholder="Fyll i e-postaddress"
										/>
										{getNotificationError()}
									</label>
								)}
								<label>
									<SbtH4>Förnamn</SbtH4>
									<input
										className={style.newSendInviteModalInputField}
										{...register("firstName")}
										placeholder="Förnamn"
									/>
									<SbtRHFError error={errors.firstName} />
								</label>
								<label>
									<SbtH4>Efternamn</SbtH4>
									<input
										className={style.newSendInviteModalInputField}
										{...register("lastName")}
										placeholder="Efternamn"
									/>
									<SbtRHFError error={errors.lastName} />
								</label>
								<label>
									<SbtH4>Meddelande</SbtH4>
									<textarea
										className={style.newSendInviteModalInputTextArea}
										{...register("message")}
										placeholder="Meddelande till personen"
									/>
									<SbtRHFError error={errors.message} />
								</label>
							</div>
							<FinalizeButton className={style.finalize} disabled={!isValid || isSubmitting} type="submit">
								Skicka inbjudan
								{isSubmitting ? (
									<FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} />
								) : null}
							</FinalizeButton>
						</form>
					</div>
				</ModulePopup>
			) : null}
		</div>
	)
}
