export type ProductDefinition = {
	id: string // This is Nullable in the BE spec, we should change that
	name: string // Presentation name of product type
	type: ProductType
	typeImage: ProductImageType
	dimensions: ProductDimensions
	services: Service[]
	transportation: Transportation
	allowedWaste: { [id: WasteId]: WasteType }
	timeSlots: TimeSlot[]
	order: number
}

export type Transportation = {
	name: string
	type: TransportationType
	// TODO: Below should really be renamed and refactored to reflect it's
	//  about dimensions. As it stands right now it's just pure data that
	//  part of the UI is concerned about for including in images. While it
	//  needs to be stored in the backend, there's no way to validate this
	//  data in any shape or form.
	data: { [key: string]: string }
}

export type ProductDimensions = {
	volume?: Measurement
	maxWeight?: Measurement
	length?: Measurement
	width?: Measurement
	height?: Measurement
	weight?: Measurement
}

export type Measurement = {
	value: number
	unit: string
}

export type Service = {
	name: string
	type: ServiceType
	maxQuantity: number
	maxWasteType: number
}

export type WasteId = string

export type WasteType = {
	id: WasteId
	order: number
	name: string
	info: string
	typeImage: WasteTypeImages
}

export type TimeSlot = {
	name: string
	specificTime: boolean
}

export enum ProductType {
	Container = "Container",
	Bag = "Bag",
	Vessel = "Vessel",
	Material = "Material",
	MaterialBag = "MaterialBag",
}

//FIXME, should this be deprecated or used as key of in TransportationDetails?
export enum TransportationType {
	CraneTruck = "CraneTruck",
	SkipLoaderTruck = "SkipLoaderTruck",
	RollOfDumpsterTruck = "RollOfDumpsterTruck",
	TailGateLiftTruck = "TailGateLiftTruck",
	DeliveryTruck = "DeliveryTruck",
	TipperTruck = "TipperTruck",
}

export enum ServiceType {
	Deployment = "Deployment",
	Pickup = "Pickup",
	Exchange = "Exchange",
}

export enum ProductImageType {
	BagLarge = "BagLarge",
	BagMedium = "BagMedium",
	BagSmall = "BagSmall",
	BigBagExtraLarge = "BigBagExtraLarge",
	BigBagMega = "BigBagMega",
	LD10 = "LD10",
	LD5 = "LD5",
	LDD10 = "LDD10",
	LDL8 = "LDL8",
	LDL8WOH = "LDL8WOH",
	LDT10 = "LDT10",
	LV11K = "LV11K",
	LV12 = "LV12",
	LV15K = "LV15K",
	LV22 = "LV22",
	LV30 = "LV30",
	LVT15 = "LVT15",
	LVT15K = "LVT15K",
	LVT25 = "LVT25",
	Vessel190L = "Vessel190L",
	Vessel370L = "Vessel370L",
	Vessel600LWOL = "Vessel600LWOL",
	Vessel660L = "Vessel660L",
	VesselBagMedium = "VesselBagMedium",
}

export enum WasteTypeImages {
	Asbestos = "Asbestos",
	Asphalt = "Asphalt",
	BlueConcrete = "BlueConcrete",
	Cardboard = "Cardboard",
	ClayBricks = "ClayBricks",
	CleanSoil = "CleanSoil",
	Combustible = "Combustible",
	Compost = "Compost",
	Concrete = "Concrete",
	ConcreteBlocks = "ConcreteBlocks",
	ContaminatedSoil = "ContaminatedSoil",
	DemolitionDrywall = "DemolitionDrywall",
	Drywall = "Drywall",
	Electronics = "Electronics",
	FillingMass = "FillingMass",
	FluorescentLightTube = "FluorescentLightTube",
	GardenWaste = "GardenWaste",
	GlassBottle = "GlassBottle",
	GlassWare = "GlassWare",
	ImpureFillingMaterial = "ImpureFillingMaterial",
	Insulation = "Insulation",
	Metal = "Metal",
	MixedMass = "MixedMass",
	MixedWaste = "MixedWaste",
	OtherWaste = "OtherWaste",
	ParkAndGarden = "ParkAndGarden",
	Plastic = "Plastic",
	ReinforcedConcrete = "ReinforcedConcrete",
	Sand = "Sand",
	SanitaryWare = "SanitaryWare",
	Stumps = "Stumps",
	UncleanSoil = "UncleanSoil",
	Unsortable = "Unsortable",
	WetMass = "WetMass",
	Wood = "Wood",
}
