import { useJsApiLoader } from "@react-google-maps/api"
import { forEach } from "lodash"
import { FC, useEffect, useRef, useState } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { ConsumerCatalogInstance, ConsumerProjectMode } from "../../Client/ConsumerCatalogContext"
import { AccentButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { removeModalOpenClass } from "../../Orders/Components/ModulePopup/ModulePopup"
import {
	googleMiniMapOptions,
	libraries,
	markerDefaultHtml,
	markerDefaultOptions,
} from "../../Orders/ProjectInputModule/ProjectInputModule"
import {
	GoogleMapComponent,
	initGoogleMapsCustomFullscreenControl,
} from "../../Shared/GoogleMapComponent/GoogleMapComponent"
import { GetProject } from "../CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import style from "./CustomerPortalProjectsPlottedMap.module.css"

type CustomerPortalProjectsPlottedMapProps = {
	projects: GetProject[] | null
	hoveredProject: GetProject | null
	selectedProject: GetProject | null
	onProjectSelect: (project: GetProject) => void
	consumerCatalog: ConsumerCatalogInstance
}

type MapMarkersToProject = { [projectId: string]: google.maps.marker.AdvancedMarkerElement | undefined }
export const CustomerPortalProjectsPlottedMap: FC<CustomerPortalProjectsPlottedMapProps> = (
	props: CustomerPortalProjectsPlottedMapProps,
) => {
	const [projects, setProjects] = useState<GetProject[] | null>(props.projects)
	const [hoveredProject, setHoveredProject] = useState<GetProject | null>(props.hoveredProject)

	const [clickedProject, setClickedProject] = useState<GetProject | null>(null)
	const selectedProjectRef = useRef(props.selectedProject)
	const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null)

	const mapMarkersRef = useRef<MapMarkersToProject>({})

	const { isLoaded: isGoogleMapsLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		libraries: libraries,
		language: "sv",
	})

	useEffect(() => {
		setProjects(props.projects)
		if (mapInstance) {
			addMapMarkers(mapInstance, props.projects ?? [])
			setMapBounds(mapInstance, props.projects ?? [])
		}
	}, [props.projects])

	useEffect(() => {
		selectedProjectRef.current = props.selectedProject
	}, [props.selectedProject])

	useEffect(() => {
		if (props.hoveredProject) {
			const propsHoveredMarker = mapMarkersRef.current[props.hoveredProject.id]
			if (!propsHoveredMarker) {
				return
			}
			const stateHoveredMarker = hoveredProject ? mapMarkersRef.current[hoveredProject.id] : null

			if (hoveredProject && (!clickedProject || clickedProject.id !== hoveredProject.id) && stateHoveredMarker) {
				stateHoveredMarker.content = markerDefaultHtml()
				propsHoveredMarker.zIndex = null
			}
			setHoveredProject(props.hoveredProject)

			if (clickedProject?.id !== props.hoveredProject.id) {
				propsHoveredMarker.content = markerDefaultHtml(undefined, true, 42)
				propsHoveredMarker.zIndex = 1000
			}
		} else if (hoveredProject && (!clickedProject || clickedProject.id !== hoveredProject.id)) {
			const stateHoveredMarker = mapMarkersRef.current[hoveredProject.id]
			if (!stateHoveredMarker) {
				return
			}
			stateHoveredMarker.content = markerDefaultHtml()
			stateHoveredMarker.zIndex = null
			setHoveredProject(null)
		}
	}, [props.hoveredProject])

	function onMapLoad(map: google.maps.Map) {
		setMapInstance(map)
		initGoogleMapsCustomFullscreenControl(map)
		addMapMarkers(map, projects ?? [])
		setMapBounds(map, projects ?? [])
	}

	function setMapBounds(map: google.maps.Map, projects: GetProject[]) {
		if (!map || !projects) {
			return
		}

		const bounds = new google.maps.LatLngBounds()

		projects.forEach((project) => {
			if (project.address.coordinates) {
				bounds.extend(project.address.coordinates)
			}
		})

		map.fitBounds(bounds, { top: 100, left: 60, right: 60, bottom: 30 })
	}

	function addMapMarkers(map: google.maps.Map, projects: GetProject[]) {
		if (!map || !projects) {
			mapMarkersRef.current = {}
			return
		}

		forEach(mapMarkersRef.current, (x) => {
			if (x) {
				x.map = null
			}
		})

		mapMarkersRef.current = {}

		// Create an info window to share between markers.
		const infoWindow = new google.maps.InfoWindow({})
		infoWindow.addListener("closeclick", () => {
			setClickedProject((oldProject) => {
				if (oldProject) {
					const marker = mapMarkersRef.current[oldProject.id]
					if (marker) {
						marker.content = markerDefaultHtml()
					}
				}
				return null
			})
		})

		projects.forEach((project) => {
			if (project.address.coordinates) {
				const marker = new google.maps.marker.AdvancedMarkerElement(markerDefaultOptions())
				marker.map = map
				marker.position = project.address.coordinates
				marker.gmpClickable = true
				// Add a click listener for each marker, and set up the info window.
				marker.addListener("click", function () {
					setClickedProject((oldProject) => {
						if (oldProject) {
							const marker = mapMarkersRef.current[oldProject.id]
							if (marker) {
								marker.content = markerDefaultHtml()
							}
							if (oldProject.id === project.id) {
								infoWindow.close()
								return null
							}
						}
						return project
					})
					setHoveredProject(project)
					infoWindow.close()
					infoWindow.setContent(
						renderToStaticMarkup(
							<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
								<div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "4px" }}>
									{props.consumerCatalog.features.projectMode === ConsumerProjectMode.FullProject
										? project.marking || project.address.street
										: project.address.street}
								</div>
								<div style={{ fontSize: "16px", marginBottom: "10px" }}>
									{project.address.street}, {project.address.zipCode ? project.address.zipCode + " " : ""}
									{project.address.city}
								</div>
								<AccentButton id={project.id + "_btn"}>Välj</AccentButton>
							</div>,
						),
					)
					google.maps.event.addListener(infoWindow, "domready", () => {
						const someButton = document.getElementById(project.id + "_btn")
						if (someButton) {
							someButton.addEventListener("click", () => {
								props.onProjectSelect(project)
								setClickedProject((clickedProject) => {
									if (clickedProject) {
										const marker = mapMarkersRef.current[clickedProject.id]

										if (marker) {
											marker.content = markerDefaultHtml()
											marker.zIndex = null
										}
									}

									return null
								})
								setHoveredProject(null)
								infoWindow.close()
							})
						}
					})
					marker.content = markerDefaultHtml(undefined, undefined, 42)
					marker.zIndex = 1001
					infoWindow.open(map, marker)
				})
				mapMarkersRef.current[project.id] = marker
			}
		})
	}

	if (!projects || projects.length === 0 || !isGoogleMapsLoaded) {
		return null
	}

	return (
		<GoogleMapComponent
			onUnmount={() => {
				removeModalOpenClass()
			}}
			mapContainerClassName={style.mapContainer}
			center={undefined}
			zoom={19}
			onLoad={onMapLoad}
			options={googleMiniMapOptions(true, "cooperative")}
		/>
	)
}
