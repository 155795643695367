import { TransportationType, WasteTypeImages } from "ProductDefinitions"

export type TemplateModelHolder = {
	wasteTypeTemplates: WasteTypeDefinitionTemplate[]
	transportationTemplates: TransportationDefinitionTemplate[]
	timeSlotTemplates: TimeSlotDefinitionTemplate[]
	serviceTemplates: ServiceDefinitionTemplate[]
	productTemplates: ProductDefinitionTemplate[]
}

export type WasteTypeDefinitionTemplate = {
	name: string
	info: string
	typeImage: WasteTypeImages
}

export type TransportationDefinitionTemplate = {
	name: string
	transportationDetails: TransportationDetailsTemplate
	constraints: TransportationConstraintsTemplate
}

export type TimeSlotDefinitionTemplate = {
	name: string
	specificTime: boolean
	description?: string
	startTime?: string
	endTime?: string
}

export type ServiceDefinitionTemplate = {
	name: string
	quantityType: QuantityTypeTemplateEnum
}

export enum QuantityTypeTemplateEnum {
	Waste = "Avfall",
	Amount = "Amount",
}

export enum AllowedOnboardingFeatureEnum {
	Container = "Container",
	Vessel = "Vessel",
	Bag = "Bag",
	Other = "Other",
}

export type ProductDefinitionTemplate = {
	name: string
	typeImage: string
	dimensions: ProductDefinitionDimensionTemplate
	description: string
	productType: AllowedOnboardingFeatureEnum
}

export type TransportationDetailsTemplate = {
	type: TransportationType
	height?: number
	length?: number
	width?: number
	containerLength?: number
	tailGateLength?: number
	maxReach?: number
}

export type TransportationConstraintsTemplate = {
	max: number
}

export type ProductDefinitionDimensionTemplate = {
	volume?: {
		value: number
		unit: string
	}
	maxWeight?: {
		value: number
		unit: string
	}
	length?: {
		value: number
		unit: string
	}
	width?: {
		value: number
		unit: string
	}
	height?: {
		value: number
		unit: string
	}
	weight?: {
		value: number
		unit: string
	}
}
