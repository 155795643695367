import React, { ReactNode, useContext } from "react"
import { AbsolutCentered } from "../AbsolutCentered/AbsolutCentered"
import { ExtendedError } from "../ErrorBoundary/ErrorBoundary"
import { Loader } from "../Loader/Loader"
import { getLogger } from "../Logging/getLogger"
import { throwIllegalState } from "../Shared/throwIllegalState"
import { when } from "../Shared/when"
import { ArticleTree, ArticleTreeType } from "./articleTrees/ArticleTreeDataModel"
import { useClient } from "./ClientAndUserProvider"
import {
	ConsumerCatalogConfiguration,
	MissingPricePolicy,
	PreferredVATRenderPolicy,
	PrepayConfiguration,
} from "./consumerCatalogConfigurationTypes"
import { useConsumer } from "./ConsumerContext"
import { ConsumerCatalogContextType, useConsumerConfiguration } from "./useConsumerConfiguration"

const logger = getLogger("ConsumerCatalogContext")

export type GetConsumerCatalogConfigurationResponse = {
	configuration: ConsumerCatalogConfiguration
	articleTrees: ArticleTree[]
	features: ConsumerFeatures
}

export type ConsumerFeatures = {
	projectMode: ConsumerProjectMode
	fullProjectMarkingMode?: FullProjectMarkingMode
}

export enum FullProjectMarkingMode {
	Optional = "Optional",
	Required = "Required",
}

export enum ConsumerProjectMode {
	SimpleAddress = "SimpleAddress",
	FullProject = "FullProject",
}
export class ConsumerCatalogInstance {
	readonly configuration: ConsumerCatalogConfiguration
	readonly articleTrees: ArticleTree[]
	readonly features: ConsumerFeatures

	readonly staticProductPricing: ArticleTree | undefined
	readonly transportPricing: ArticleTree | undefined
	readonly datePricing: ArticleTree | undefined
	readonly preferredVATRenderPolicy: PreferredVATRenderPolicy | undefined

	constructor(configuration: ConsumerCatalogConfiguration, articleTrees: ArticleTree[], features: ConsumerFeatures) {
		this.configuration = configuration
		this.articleTrees = articleTrees
		this.features = features

		this.staticProductPricing = articleTrees.find((x) => x.treeType === "StaticProductPricing")
		this.transportPricing = articleTrees.find((x) => x.treeType === "StaticTransportationPricing")
		this.datePricing = articleTrees.find((x) => x.treeType === "StaticDateSlotPricing")

		if (this.configuration.type === "Prepay") {
			this.preferredVATRenderPolicy = this.configuration.preferredVATRenderPolicy
		}
	}

	get configurationType(): ConsumerCatalogConfiguration["type"] {
		return this.configuration.type
	}

	get missingPricePolicy(): PrepayConfiguration["missingPricePolicy"] {
		if (this.configuration.type === "Prepay") {
			return this.configuration.missingPricePolicy ?? MissingPricePolicy.Hide //Forward-compatible
		} else {
			logger.error("MissingPricePolicy looked up for CCC that doesn't support it!", this.configuration)
			return MissingPricePolicy.Hide
		}
	}

	get discountTrees(): ArticleTree[] {
		return this.articleTrees.filter((x) => x.treeType === ArticleTreeType.Discounts)
	}

	get markingRequired(): boolean {
		if (this.features.projectMode === ConsumerProjectMode.FullProject && this.features.fullProjectMarkingMode) {
			return when(this.features.fullProjectMarkingMode, {
				[FullProjectMarkingMode.Optional]: () => {
					return false
				},
				[FullProjectMarkingMode.Required]: () => {
					return true
				},
			})
		}

		return false
	}

	get pricesEnabled(): boolean {
		return this.articleTrees.length > 0 && this.configurationType !== "Contract"
	}

	get renderVAT(): boolean {
		return this.preferredVATRenderPolicy === PreferredVATRenderPolicy.IncludeVAT
	}
}

const ConsumerCatalogContext = React.createContext<ConsumerCatalogContextType>("loading")

type Props = {
	children: ReactNode
}

export function ConsumerCatalogContextProvider({ children }: Props) {
	const client = useClient()
	const consumer = useConsumer()

	const value = useConsumerConfiguration(client.identifier, consumer?.consumerId)

	if (value === "loading") {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	if (value === null) {
		// Use extended error to ensure this generates a reload and not crash-screen
		throw new ExtendedError(
			`Unable to resolve Consumer Catalog Configuration for, client: ${client.identifier}, consumer: ${
				consumer?.consumerId || "undefined"
			}`,
			true,
		)
	}

	logger.debug("ConsumerCatalogContext: ", value)
	return <ConsumerCatalogContext.Provider value={value}>{children}</ConsumerCatalogContext.Provider>
}

export function useConsumerCatalog(): ConsumerCatalogInstance {
	let context = useContext(ConsumerCatalogContext)
	if (context instanceof ConsumerCatalogInstance) {
		return context
	}

	throwIllegalState(`ConsumerCatalog Context not setup, was: ${context}`)
}
