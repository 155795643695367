import { toNumber } from "lodash"
import { FC, useState } from "react"
import style from "./IncrementorPill.module.css"

type IncrementorPillProps = {
	defaultValue: number | null
	max: number
	text: string
	onChange: (value: number | null, oldValue: number | null) => void
	autoFocus: boolean
	onBlur?: (value: number | null) => void
	multiplier: number
}

export const IncrementorPill: FC<IncrementorPillProps> = ({
	defaultValue,
	max,
	onChange: extOnChange,
	text,
	autoFocus,
	onBlur,
	multiplier = 1,
}) => {
	let value = defaultValue
	let oldValue: number | null = null

	function onChange(newValue: number | null, oldVal: number | null, element: HTMLInputElement): void {
		if (newValue !== null && newValue > max) {
			newValue = max
			element.value = (newValue * multiplier).toString()
		}

		extOnChange(newValue, oldVal)
		value = newValue
		oldValue = oldVal
	}

	return (
		<span className={style.incrementorPill} style={{ height: `45px` }}>
			<input
				autoFocus={autoFocus}
				type={"number"}
				defaultValue={defaultValue !== null ? defaultValue * multiplier : ""}
				onFocus={(e) => {
					e.target.select()
					e.target.addEventListener(
						"wheel",
						function (e) {
							e.preventDefault()
						},
						{ passive: false },
					)
				}}
				onChange={(event) => {
					if (event.target.value === "") {
						onChange(null, value, event.target)
					} else if (event.target.value === "0") {
						onChange(0, value, event.target)
					} else {
						let number = toNumber(event.target.value)
						if (number % multiplier !== 0) {
							return
						}
						onChange(toNumber(event.target.value) / multiplier, value, event.target)
					}
				}}
				onBlur={(event) => {
					let number = event.target.value ? toNumber(event.target.value) : null
					let result

					if (number !== null && number % multiplier !== 0) {
						if (number > 0) result = Math.ceil(number / multiplier) * multiplier
						else result = multiplier

						event.target.value = result.toString()
						onChange(result / multiplier, value, event.target)
					}

					if (number !== null && number === 0) {
						result = 0
					}

					if (onBlur && result !== undefined) {
						onBlur(result)
					}

					if (number === null) {
						event.target.value = oldValue?.toString() || ""
					}
				}}
				step={multiplier}
			/>
			&nbsp;
			<span className={style.unitText}>/ {text}</span>
		</span>
	)
}

type ExpandableIncrementorPillProps = Omit<IncrementorPillProps, "onBlur"> & {
	size: number
	onBlur?: (value: number | null) => void
}

export const ExpandableIncrementorPill: FC<ExpandableIncrementorPillProps> = ({
	defaultValue,
	max,
	onChange: extOnChange,
	text,
	size = 35,
	autoFocus,
	multiplier = 1,
	onBlur: extOnBlur,
}) => {
	const [value, setValue] = useState(defaultValue)
	const [showIncrementor, setShowIncrementor] = useState(value !== null)

	function onChange(value: number | null, oldValue: number | null) {
		if (value === null || value <= 0) {
			setShowIncrementor(false)
		}
		setValue(value)
		extOnChange(value, oldValue)
	}

	function onBlur(value: number | null) {
		if (value === null || value <= 0) {
			setShowIncrementor(false)
		}
		if (extOnBlur) {
			extOnBlur(value)
		}
	}

	return (
		<div className={style.expandableButtonWrapper} style={{ minHeight: `${size}px`, minWidth: `${size}px` }}>
			{!showIncrementor ? (
				<button
					onClick={(event) => {
						event.preventDefault()
						event.stopPropagation()
						onChange(1, 0)
						setShowIncrementor(true)
					}}
					type="button"
					style={{ minHeight: "34px", maxHeight: "34px", minWidth: "34px", maxWidth: "34px" }}
					className={style.expandableButton}>
					+
				</button>
			) : (
				<IncrementorPill
					defaultValue={value}
					max={max}
					text={text}
					onChange={onChange}
					autoFocus={autoFocus}
					onBlur={onBlur}
					multiplier={multiplier}
				/>
			)}
		</div>
	)
}
