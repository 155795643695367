import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { debounce, isEqual } from "lodash"
import { FC, FormEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { MeResponseConsumerData, MeResponseConsumerType } from "../../Auth/Auth.types"
import { useAuth } from "../../Auth/AuthContext"
import { ClientPortalNewConsumerModal } from "../../ClientPortal/ClientPortalNewConsumerModal/ClientPortalNewConsumerModal"
import { lets } from "../../Shared/lets"
import { FieldModuleBox } from "../Components/FieldModuleBox/FieldModuleBox"
import { FieldModuleBoxWrapper } from "../Components/FieldModuleBox/FieldModuleBoxWrapper"
import { FinalizeButton } from "../Components/Form/Buttons/Buttons"
import { ModulChooserButton } from "../Components/ModulChooserButton/ModulChooserButton"
import { ModulePopup, removeModalOpenClass } from "../Components/ModulePopup/ModulePopup"
import orderConfirmStyle from "../Components/OrderConfirmCustomerInformation/OrderConfirmCustomerInformation.module.css"
import { MbtH5 } from "../Components/Text/MbtH5/MbtH5"
import { convertTextToInitials } from "../Helpers"
import projectSelectStyle from "../ProjectSelectModule/ProjectSelectModule.module.css"
import style from "./ClientSelectActiveConsumerModal.module.css"

export type ClientSelectActiveConsumerModalProps = {
	selectedConsumerId: string | null
	onClose: () => void
	onDone: (consumerId: string) => void
}

export const ClientSelectActiveConsumerModal: FC<ClientSelectActiveConsumerModalProps> = ({
	selectedConsumerId,
	onClose: extOnClose,
	onDone: extOnDone,
}) => {
	const auth = useAuth()
	const [consumers, setConsumers] = useState<MeResponseConsumerData[]>(auth.Me?.consumers || [])
	const [selectedItem, setSelectedItem] = useState<string | null>(selectedConsumerId)
	const [showIndividuals, setShowIndividuals] = useState(
		consumers.find((x) => x.id === selectedConsumerId)?.type === MeResponseConsumerType.Person,
	)
	const [, setTick] = useState(0)
	const [showNewConsumerModal, setShowNewConsumerModal] = useState<boolean>(false)

	const firstLoad = useRef(true)
	const newCustomerButtonWrapperRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
	const consumersWrapperRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (!newCustomerButtonWrapperRef.current) return
		const resizeObserver = new ResizeObserver((entries) => {
			lets(entries[0], (firstEntry) => {
				const div = firstEntry.target as HTMLDivElement
				const childDivs = div.getElementsByTagName("div")
				if (div.offsetWidth <= 130) {
					div.style.justifyContent = "center"
					lets(childDivs[0]?.style, (it) => (it.display = "none"))
					lets(childDivs[1]?.style, (it) => {
						it.marginLeft = "0"
						it.paddingRight = "0"
					})
				} else {
					lets(childDivs[0]?.style, (it) => (it.display = "block"))
					lets(childDivs[1]?.style, (it) => {
						it.marginLeft = "auto"
						it.paddingRight = "0.8em"
					})
				}
			})
		})
		resizeObserver.observe(newCustomerButtonWrapperRef.current)
		return () => resizeObserver.disconnect()
	}, [])

	useEffect(() => {
		if (firstLoad.current) {
			firstLoad.current = false
			return
		}

		const me = auth.Me
		if (me) {
			setConsumers((x) => {
				if (!isEqual(me.consumers, x)) {
					const lastConsumer = me.consumers[me.consumers.length - 1]
					if (lastConsumer != null) {
						setShowIndividuals(lastConsumer.type === MeResponseConsumerType.Person)
						setSelectedItem(lastConsumer.id)
						setTimeout(() => {
							const ref = consumersWrapperRef.current
							if (ref) {
								ref.scrollTop = ref.scrollHeight
							}
						}, 50)
					}
				}

				return me.consumers
			})
		}
	}, [auth])

	function onClose() {
		if (!showNewConsumerModal) {
			removeModalOpenClass()
			extOnClose()
		}
	}

	function onDone() {
		if (!selectedItem) {
			return
		}

		onClose()
		extOnDone(selectedItem)
	}

	const searchDebounced = useCallback(
		debounce((event: FormEvent<HTMLInputElement>) => {
			if (event.target === null || (event.target as HTMLInputElement).value === "") {
				setConsumers(auth.Me?.consumers || [])
				return
			}

			const searchString: string = (event.target as HTMLInputElement).value.toLocaleLowerCase()

			setConsumers(
				consumers.filter(
					(y) =>
						y.name.toLocaleLowerCase().indexOf(searchString) > -1 ||
						y.identifier.toLocaleLowerCase().indexOf(searchString) > -1,
				),
			)
		}, 250),
		[consumers],
	)

	return (
		<>
			{showNewConsumerModal ? (
				<ClientPortalNewConsumerModal
					onAdd={() => {
						auth.refreshMeData()
					}}
					onClose={() => {
						setShowNewConsumerModal(false)
					}}
					externalButton={true}
					showModal={true}
					setToggleToCreateIndividual={showIndividuals}
				/>
			) : null}
			<ModulePopup className={style.modal} onClose={onClose}>
				<div className={style.wrapper}>
					<div className={style.header}>Välj kund</div>
					<div style={{ display: "flex", marginTop: "20px" }}>
						<div className={orderConfirmStyle.btnContainer} style={{ width: "auto" }}>
							<label className={orderConfirmStyle.btnIndividualOrCompanySwitch}>
								<input
									type="checkbox"
									name="individualOrCompanyFilter"
									id="individualOrCompanyFilter"
									value="1"
									checked={showIndividuals}
									onChange={(x) => setShowIndividuals(x.target.checked)}
								/>
								<label
									style={{ cursor: "pointer" }}
									htmlFor="individualOrCompanyFilter"
									data-on="Privatperson"
									data-off="Företag"
									className={orderConfirmStyle.btnIndividualOrCompanySwitchInner}
								/>
							</label>
						</div>
					</div>
					<div className={projectSelectStyle.searchAndNew}>
						<div className={projectSelectStyle.searchBox}>
							<span className={projectSelectStyle.searchBtn}>
								<FontAwesomeIcon icon={faSearch} />
							</span>
							<input
								type={"search"}
								className={projectSelectStyle.searchInput}
								placeholder={"Sök i alla kunder"}
								onInput={(x) => {
									searchDebounced(x)
								}}
							/>
						</div>
						<div
							ref={newCustomerButtonWrapperRef}
							className={projectSelectStyle.newProjectButton}
							onClick={() => {
								setShowNewConsumerModal(true)
							}}>
							<div className={projectSelectStyle.newProjectButtonText}>Ny kund</div>
							<div className={projectSelectStyle.newProjectButtonPlus}>+</div>
						</div>
					</div>
					<FieldModuleBoxWrapper className={style.fields} reference={consumersWrapperRef}>
						{consumers
							.filter(
								(x) =>
									x.type ===
									(showIndividuals ? MeResponseConsumerType.Person : MeResponseConsumerType.Company),
							)
							.map((consumerData) => {
								return (
									<FieldModuleBox
										className={style.item}
										key={consumerData.id}
										selected={selectedItem === consumerData.id}>
										<span className={style.profilePicture}>
											{consumerData.profilePicture ? (
												<img
													src={consumerData.profilePicture}
													onError={() => {
														consumerData.profilePicture = undefined
														setTick((tick) => tick + 1)
													}}
													alt=""
												/>
											) : (
												<AbsolutCentered>
													<span style={{ color: "white" }}>
														{convertTextToInitials(consumerData.name)}
													</span>
												</AbsolutCentered>
											)}
										</span>
										<div>
											<MbtH5>{consumerData.name}</MbtH5>
											<MbtH5 styles={{ fontWeight: 300, fontSize: "16px" }}>
												{consumerData.identifier}
											</MbtH5>
										</div>
										<ModulChooserButton
											selected={selectedItem === consumerData.id}
											onClick={() => {
												setSelectedItem(consumerData.id)
											}}>
											{selectedItem === consumerData.id ? "Vald" : "Välj"}
										</ModulChooserButton>
									</FieldModuleBox>
								)
							})}
					</FieldModuleBoxWrapper>
					<FinalizeButton disabled={!selectedItem} className={style.finalize} onClick={() => onDone()}>
						Klar
					</FinalizeButton>
				</div>
			</ModulePopup>
		</>
	)
}
