import { isEqual } from "lodash"
import React, { FC, useEffect, useRef, useState } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { v4 } from "uuid"
import { cls } from "../../../../Shared/cls"
import style from "./MultiStateSwitch.module.css"

type Option = {
	key: string
	value: string
}

export type Props = {
	options: Option[]
	onSelect: (option: Option) => void
}
export const MultiStateSwitch: FC<Props> = (props: Props) => {
	const id = useRef(v4())

	const [options, setOptions] = useState(props.options)
	const [selectedOption, setSelectedOption] = useState<string | null>(options[0]?.key || null)

	useEffect(() => {
		if (options[0]) {
			props.onSelect(options[0])
		}
	}, [])

	useEffect(() => {
		if (!isEqual(props.options, options)) {
			setOptions(props.options)
		}
	}, [props.options])

	useEffect(() => {
		document.head.insertAdjacentHTML(
			"beforeend",
			renderToStaticMarkup(
				<style
					className={`multiStateSwitch_${id.current}_style`}
					dangerouslySetInnerHTML={{
						__html: `
						${options
							.map((option, index) => {
								if (index === 0) {
									return `
									.multiStateSwitch_${id.current}:has(#switch_option_${option.key}:checked)::before {
										left: 5px;
									}
									`
								} else if (index === options.length - 1) {
									return `
									.multiStateSwitch_${id.current}:has(#switch_option_${option.key}:checked)::before {
										left: calc(${(100 / options.length) * index}% - 5px);
									}
									`
								} else {
									return `
									.multiStateSwitch_${id.current}:has(#switch_option_${option.key}:checked)::before {
										left: calc(${(100 / options.length) * index}%);
									}
									`
								}
							})
							.join("\n")}
						.multiStateSwitch_${id.current}::before {
							 width: ${options.length === 1 ? "calc(100% - 10px)" : 100 / options.length + "%"};
						}

						.multiStateSwitch_${id.current} label {
							 width: ${100 / options.length - 2}%;
							 max-width: ${100 / options.length - 2}%;
						}

						@container switchWrapper (max-width: 400px) {
							${options
								.map((option, index) => {
									if (index === 0) {
										return `
									.multiStateSwitch_${id.current}:has(#switch_option_${option.key}:checked)::before {
										left: unset;
										top: 5px
									}
									`
									} else if (index === options.length - 1) {
										return `
									.multiStateSwitch_${id.current}:has(#switch_option_${option.key}:checked)::before {
										left: unset;
										top: calc(${50 * (options.length - 1)}px + 5px);
									}
									`
									} else {
										return `
									.multiStateSwitch_${id.current}:has(#switch_option_${option.key}:checked)::before {
										left: unset;
										top: calc(${50 * index}px + 5px);
									}
									`
									}
								})
								.join("\n")}
						}
							`,
					}}></style>,
			),
		)

		const _id = id.current

		return () => {
			const elements = document.getElementsByClassName(`multiStateSwitch_${_id}_style`)
			Array.from(elements).forEach((x) => x.remove())
		}
	}, [options])

	return (
		<div className={style.wrapper}>
			<div className={cls(style.switch, `multiStateSwitch_${id.current}`)}>
				{options.map((option, index) => {
					const checked = selectedOption === option.key
					return (
						<React.Fragment key={id.current + "_wrapper" + index}>
							<input
								type="radio"
								id={`switch_option_${option.key}`}
								name={`multiStateSwitch_${id.current}`}
								defaultChecked={checked}
								readOnly={true}
								value={option.key}
								onClick={(e) => {
									setSelectedOption(option.key)
									props.onSelect(option)
								}}
							/>
							<label
								htmlFor={`switch_option_${option.key}`}
								data-selected={selectedOption === option.key}
								title={option.value}>
								<span className={"oneLineClamp"}>{option.value}</span>
							</label>
						</React.Fragment>
					)
				})}
			</div>
		</div>
	)
}
