import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import {
	CompanyConsumerDetails,
	GetConsumer,
	PrivateConsumerDetails,
} from "CustomerPortal/CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { isUndefined } from "lodash"
import { API } from "network/API"
import { FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "Orders/Components/ModulePopup/ModulePopup"
import { SbtH2 } from "Orders/Components/Text/SbtH2/SbtH2"
import { SbtH4 } from "Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "Orders/Components/Text/SbtInvalid/SbtRHFError"
import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useClient } from "../../Client/ClientAndUserProvider"
import { cls } from "../../Shared/cls"
import { LabelledToggle } from "../../Shared/LabelledToggle/LabelledToggle"
import style from "./ClientPortalNewConsumerModal.module.css"

const personNumberRegex = new RegExp("^(19|20)?[0-9]{6}[- ]?[0-9]{4}$")
export const optionalTextInput = (schema: z.ZodString, msg: string) =>
	z.union([z.string(), z.undefined()]).refine((val) => !val || schema.safeParse(val).success, msg)

const PrivateSpecificDetailsSchema = z.object({
	name: z.string().min(1),
	personNumber: optionalTextInput(z.string().regex(personNumberRegex), "Felaktigt format på personnummret"),
})

const BusinessSpecificDetailsSchema = z.object({
	name: z.string().min(1),
	orgNumber: z.string().optional(),
})

type PrivateSpecificDetails = z.input<typeof PrivateSpecificDetailsSchema>
type BusinessSpecificDetails = z.input<typeof BusinessSpecificDetailsSchema>

export type Props = {
	onClose?: () => void
	onAdd: (consumer: GetConsumer) => void
	externalButton?: boolean
	showModal?: boolean
	setToggleToCreateIndividual?: boolean
	newConsumerButtonClass?: string
}

type CreateConsumerResponse = {
	consumer: GetConsumer
}

export const ClientPortalNewConsumerModal: FC<Props> = ({
	onAdd,
	externalButton,
	showModal: extShowModal,
	onClose: extOnClose,
	setToggleToCreateIndividual,
	newConsumerButtonClass,
}) => {
	const client = useClient()
	const [creatingIndividualConsumer, setCreatingIndividualConsumer] = useState(false)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

	useEffect(() => {
		setShowModal(!!extShowModal)
	}, [extShowModal])

	useEffect(() => {
		if (!setToggleToCreateIndividual) {
			setCreatingIndividualConsumer(false)
			return
		}
		setCreatingIndividualConsumer(setToggleToCreateIndividual)
	}, [setToggleToCreateIndividual])

	const toggleNewConsumerType = () => {
		setCreatingIndividualConsumer((prevValue: boolean) => {
			return !prevValue
		})
	}

	const {
		register: registerIndividual,
		handleSubmit: handleSubmitIndividual,
		reset: resetIndividual,
		formState: { errors: errorNewIndividual, isValid: isValidNewIndividual },
	} = useForm<PrivateSpecificDetails>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(PrivateSpecificDetailsSchema)(data, context, options)
		},
	})

	const {
		register: registerOrganization,
		handleSubmit: handleSubmitOrganization,
		reset: resetOrganization,
		formState: { errors: errorNewOrganization, isValid: isValidNewOrganization },
	} = useForm<BusinessSpecificDetails>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(BusinessSpecificDetailsSchema)(data, context, options)
		},
	})

	function renderCustomObjectError(error: any, key: string) {
		if (!isUndefined(error) && "custom" in error && key in error.custom) {
			return <SbtRHFError error={error.custom[key]} />
		}
		return <div style={{ height: "1rem" }} />
	}

	function organizationInputFields() {
		return (
			<div>
				<label>
					<SbtH4>Företagsnamn*</SbtH4>
					<input
						className={style.newConsumerModalInputField}
						{...registerOrganization("name")}
						placeholder="Fyll i företagsnamn"
					/>
					{renderCustomObjectError(errorNewOrganization, "name")}
				</label>
				<label>
					<SbtH4>Organisationsnummer</SbtH4>
					<input
						className={style.newConsumerModalInputField}
						{...registerOrganization("orgNumber")}
						placeholder="Fyll i organisationsnummer"
					/>
					{renderCustomObjectError(errorNewOrganization, "orgNumber")}
				</label>
			</div>
		)
	}

	function individualInputFields() {
		return (
			<div>
				<label>
					<SbtH4>Namn*</SbtH4>
					<input
						className={style.newConsumerModalInputField}
						{...registerIndividual("name")}
						placeholder="Fyll i namn"
					/>
					{renderCustomObjectError(errorNewIndividual, "name")}
				</label>
				<label>
					<SbtH4>Personnummer</SbtH4>
					<input
						className={style.newConsumerModalInputField}
						{...registerIndividual("personNumber")}
						placeholder="Fyll i personnummer"
					/>
					{renderCustomObjectError(errorNewIndividual, "personNumber")}
				</label>
			</div>
		)
	}

	function compileConsumerDetails(
		data: PrivateConsumerDetails | BusinessSpecificDetails,
	): CompanyConsumerDetails | PrivateConsumerDetails | null {
		if ("personNumber" in data) {
			return {
				type: "PrivateConsumerDetails",
				name: data.name,
				personNumber: data.personNumber || "",
			}
		} else if ("orgNumber" in data) {
			return {
				type: "CompanyConsumerDetails",
				name: data.name,
				orgNumber: data.orgNumber || "",
			}
		}

		return null
	}

	const onSubmit: SubmitHandler<PrivateConsumerDetails | BusinessSpecificDetails> = async (
		data: PrivateConsumerDetails | BusinessSpecificDetails,
	) => {
		setIsSubmitting(true)

		const consumerDetails = compileConsumerDetails(data)
		if (!consumerDetails) {
			return
		}
		type SetConsumer = {
			consumerDetails: CompanyConsumerDetails | PrivateConsumerDetails
		}
		type CreateConsumerRequest = {
			consumer: SetConsumer
		}
		const postBody: CreateConsumerRequest = {
			consumer: {
				consumerDetails: consumerDetails,
			},
		}
		return API.postWithRetries<CreateConsumerResponse>(
			`/customer-portal/consumer-manager-v1/${client.identifier}`,
			postBody,
		)
			.then((res: CreateConsumerResponse) => {
				setIsSubmitting(false)
				onAdd(res.consumer)
				setCreatingIndividualConsumer(false)
				resetIndividual()
				resetOrganization()
				setShowModal(false)
				if (extOnClose) {
					extOnClose()
				}
			})
			.catch(() => {
				setIsSubmitting(false)
			})
	}

	return (
		<div>
			{!externalButton ? (
				<button onClick={() => setShowModal(true)} className={cls(style.newConsumerButton, newConsumerButtonClass)}>
					<div className={style.newConsumerButtonContent}>
						<span className={style.newConsumerButtonText}>Ny kund</span>
						<FontAwesomeIcon icon={faPlus} />
					</div>
				</button>
			) : null}
			{showModal ? (
				<ModulePopup
					className={style.modal}
					show={showModal}
					onClose={() => {
						resetIndividual()
						resetOrganization()
						setCreatingIndividualConsumer(false)
						setShowModal(false)
						if (extOnClose) {
							extOnClose()
						}
					}}>
					<div className={style.wrapper}>
						<div className={style.newConsumerModalHeader}>
							<SbtH2>Skapa en ny kund</SbtH2>
						</div>
						<div style={{ marginTop: "20px", marginBottom: "20px" }}>
							<LabelledToggle
								currentValue={creatingIndividualConsumer}
								onChange={toggleNewConsumerType}
								trueLabel="Privatperson"
								falseLabel="Företag"
							/>
						</div>
						{creatingIndividualConsumer ? (
							<form
								key="form_newConsumer_individual"
								id="form_newConsumer"
								onSubmit={handleSubmitIndividual(onSubmit)}>
								{individualInputFields()}
								<FinalizeButton disabled={!isValidNewIndividual || isSubmitting} type="submit">
									Spara
									{isSubmitting ? (
										<FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} />
									) : null}
								</FinalizeButton>
							</form>
						) : (
							<form
								key="form_newConsumer_org"
								id="form_newConsumer"
								onSubmit={handleSubmitOrganization(onSubmit)}>
								{organizationInputFields()}
								<FinalizeButton disabled={!isValidNewOrganization || isSubmitting} type="submit">
									Spara
									{isSubmitting ? (
										<FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} />
									) : null}
								</FinalizeButton>
							</form>
						)}
					</div>
				</ModulePopup>
			) : null}
		</div>
	)
}
