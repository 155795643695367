import { MouseEvent } from "react"
import { cls } from "../Shared/cls"
import { SvgComponent } from "../Shared/svgComponent"
import style from "./Icon.module.css"
import { ReactComponent as ArrowLeft } from "./Images/ArrowLeft.svg"
import { ReactComponent as ArrowRight } from "./Images/ArrowRight.svg"
import { ReactComponent as Ikon_BurgerIcon } from "./Images/Burgericon.svg"
import { ReactComponent as Foldable } from "./Images/Foldable.svg"
import { ReactComponent as Hand_Pointing } from "./Images/Hand_Pointing.svg"
import { ReactComponent as Ikon_Accepterad } from "./Images/Ikon_Accepterad.svg"
import { ReactComponent as Ikon_Antal } from "./Images/Ikon_Antal.svg"
import { ReactComponent as Ikon_Avslutad } from "./Images/Ikon_Avslutad.svg"
import { ReactComponent as Ikon_Bilaga } from "./Images/Ikon_Bilaga.svg"
import { ReactComponent as Ikon_Container } from "./Images/Ikon_Container.svg"
import { ReactComponent as Ikon_Cross } from "./Images/Ikon_Cross.svg"
import { ReactComponent as Ikon_Customers } from "./Images/Ikon_Customers.svg"
import { ReactComponent as Ikon_Datum } from "./Images/Ikon_Datum.svg"
import { ReactComponent as Ikon_Datumtid } from "./Images/Ikon_Datumtid.svg"
import { ReactComponent as Ikon_Edit } from "./Images/Ikon_Edit.svg"
import { ReactComponent as Ikon_Ej_hanterad } from "./Images/Ikon_Ej_hanterad.svg"
import { ReactComponent as Ikon_Email } from "./Images/Ikon_Email.svg"
import { ReactComponent as Ikon_Filtrera } from "./Images/Ikon_Filtrera.svg"
import { ReactComponent as Ikon_Fraktion_26 } from "./Images/Ikon_Fraktion-26.svg"
import { ReactComponent as Ikon_Fraktion_32 } from "./Images/Ikon_Fraktion-32.svg"
import { ReactComponent as Ikon_Hem } from "./Images/Ikon_Hem.svg"
import { ReactComponent as Ikon_Information } from "./Images/Ikon_Information.svg"
import { ReactComponent as Ikon_Internet } from "./Images/Ikon_Internet.svg"
import { ReactComponent as Ikon_Kärl } from "./Images/Ikon_Karl.svg"
import { ReactComponent as Ikon_Kommentar } from "./Images/Ikon_Kommentar.svg"
import { ReactComponent as Ikon_Kontakt } from "./Images/Ikon_Kontakt.svg"
import { ReactComponent as Ikon_Kundvagn } from "./Images/Ikon_Kundvagn.svg"
import { ReactComponent as Ikon_Laddaner } from "./Images/Ikon_Laddaner.svg"
import { ReactComponent as Ikon_ListaOrders } from "./Images/Ikon_ListaOrders.svg"
import { ReactComponent as Ikon_Liten_Kontakt } from "./Images/Ikon_Liten Kontakt.svg"
import { ReactComponent as Ikon_Märkning } from "./Images/Ikon_Markning.svg"
import { ReactComponent as Ikon_Nekad } from "./Images/Ikon_Nekad.svg"
import { ReactComponent as Ikon_Notifikation } from "./Images/Ikon_Notifikation.svg"
import { ReactComponent as Ikon_Organisationsnummer } from "./Images/Ikon_Organisationsnummer.svg"
import { ReactComponent as Ikon_Pen } from "./Images/Ikon_Pen.svg"
import { ReactComponent as Ikon_PilNer } from "./Images/Ikon_Pil ner.svg"
import { ReactComponent as Ikon_PilUpp } from "./Images/Ikon_Pil upp.svg"
import { ReactComponent as Ikon_Plats } from "./Images/Ikon_Plats.svg"
import { ReactComponent as Ikon_Plockservice } from "./Images/Ikon_Plockservice.svg"
import { ReactComponent as Ikon_Postort } from "./Images/Ikon_Postort.svg"
import { ReactComponent as Ikon_Rabatt } from "./Images/Ikon_Rabatt.svg"
import { ReactComponent as Ikon_Säck } from "./Images/Ikon_Sack.svg"
import { ReactComponent as Ikon_Skicka } from "./Images/Ikon_Skicka.svg"
import { ReactComponent as Ikon_Soptunna } from "./Images/Ikon_Soptunna.svg"
import { ReactComponent as Ikon_SokOrder } from "./Images/Ikon_SökOrder.svg"
import { ReactComponent as Ikon_Telefon } from "./Images/Ikon_Telefon.svg"
import { ReactComponent as Ikon_Tid } from "./Images/Ikon_Tid.svg"
import { ReactComponent as Ikon_Tjänster } from "./Images/Ikon_Tjanster.svg"
import { ReactComponent as Ikon_Trash } from "./Images/Ikon_Trash.svg"
import { ReactComponent as Ikon_Varukorg } from "./Images/Ikon_Varukorg.svg"
import { ReactComponent as Ikon_Zonkarta } from "./Images/Ikon_Zonkarta.svg"
import { ReactComponent as Onboarding_bags } from "./Images/Onboarding/Icon_bags.svg"
import { ReactComponent as Onboarding_businessDetails } from "./Images/Onboarding/Icon_businessDetails.svg"
import { ReactComponent as Onboarding_clientContactDetails } from "./Images/Onboarding/Icon_clientContactDetails.svg"
import { ReactComponent as Onboarding_comment } from "./Images/Onboarding/Icon_comment.svg"
import { ReactComponent as Onboarding_consumerContactDetails } from "./Images/Onboarding/Icon_consumerContactDetails.svg"
import { ReactComponent as Onboarding_container } from "./Images/Onboarding/Icon_container.svg"
import { ReactComponent as Onboarding_exampleTimeSlots } from "./Images/Onboarding/Icon_exampleTimeSlots.svg"
import { ReactComponent as Onboarding_mailForOrders } from "./Images/Onboarding/Icon_mailForOrders.svg"
import { ReactComponent as Onboarding_productLibrary } from "./Images/Onboarding/Icon_productLibrary.svg"
import { ReactComponent as Onboarding_question } from "./Images/Onboarding/Icon_question.svg"
import { ReactComponent as Onboarding_selectedProducts } from "./Images/Onboarding/Icon_selectedProducts.svg"
import { ReactComponent as Onboarding_selectedTimeSlots } from "./Images/Onboarding/Icon_selectedTimeSlots.svg"
import { ReactComponent as Onboarding_selectedWasteTypes } from "./Images/Onboarding/Icon_selectedWasteTypes.svg"
import { ReactComponent as Onboarding_timeSlots } from "./Images/Onboarding/Icon_timeSlots.svg"
import { ReactComponent as Onboarding_transportationTerms } from "./Images/Onboarding/Icon_transportationTerms.svg"
import { ReactComponent as Onboarding_transportLibrary } from "./Images/Onboarding/Icon_transportLibrary.svg"
import { ReactComponent as Onboarding_vessel } from "./Images/Onboarding/Icon_vessel.svg"
import { ReactComponent as Onboarding_wasteTypeLibrary } from "./Images/Onboarding/Icon_wasteTypeLibrary.svg"
import { ReactComponent as Tab_Icon_Chevron_Up } from "./Images/Onboarding/TabIcon_Chevron_Up.svg"
import { ReactComponent as Tab_Icon_CustomerDetails } from "./Images/Onboarding/TabIcon_CustomerDetails.svg"
import { ReactComponent as Tab_Icon_Resources } from "./Images/Onboarding/TabIcon_Resources.svg"
import { ReactComponent as Tab_Icon_TimeSlot } from "./Images/Onboarding/TabIcon_TimeSlot.svg"
import { ReactComponent as Tab_Icon_Transportation } from "./Images/Onboarding/TabIcon_Transportation.svg"
import { ReactComponent as Tab_Icon_WasteType } from "./Images/Onboarding/TabIcon_WasteType.svg"
import { ReactComponent as Ikon_PinNoFill } from "./Images/PinIconNotFilled.svg"
import { ReactComponent as Pinmap_Black } from "./Images/Pinmapp-black.svg"
import { ReactComponent as Pinmap_Purple } from "./Images/Pinmapp-purple.svg"
import { ReactComponent as Thin_Bag } from "./Images/Thin_Skrappy_Bag.svg"
import { ReactComponent as Thin_Container } from "./Images/Thin_Skrappy_Container.svg"
import { ReactComponent as Thin_Vessel } from "./Images/Thin_Skrappy_Vessel.svg"

type IconProps = {
	SvgComponent: SvgComponent
}

type DetailedIconProps = {
	size?: number | null
	className?: string
	iconClassName?: string
	onClick?: (event: MouseEvent<SVGSVGElement> | undefined) => void
	id?: string
}

export const Icon = ({ SvgComponent, size = 28, className, iconClassName, onClick, id }: IconProps & DetailedIconProps) => {
	const optionalProp = { style: {} }
	if (size !== null) {
		optionalProp.style = { width: size, height: size }
	}

	return (
		<div id={id} className={cls(style.iconWrapper, className)} {...optionalProp}>
			<SvgComponent className={cls(style.icon, iconClassName)} onClick={onClick} />
		</div>
	)
}

export const ArrowLeftIcon = (props: DetailedIconProps) => <Icon SvgComponent={ArrowLeft} {...props} />
export const ArrowRightIcon = (props: DetailedIconProps) => <Icon SvgComponent={ArrowRight} {...props} />
export const AntalIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Antal} {...props} />
export const ContainerIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Container} {...props} />
export const DatumIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Datum} {...props} />
export const DatumtidIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Datumtid} {...props} />
export const EditIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Edit} {...props} />
export const EmailIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Email} {...props} />
export const Fraktion26Icon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Fraktion_26} {...props} />
export const Fraktion32Icon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Fraktion_32} {...props} />
export const InformationIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Information} {...props} />
export const KärlIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Kärl} {...props} />
export const KommentarIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Kommentar} {...props} />
export const KontaktIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Kontakt} {...props} />
export const Liten_KontaktIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Liten_Kontakt} {...props} />
export const MarkingIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Märkning} {...props} />
export const OrganisationsnummerIcon = (props: DetailedIconProps) => (
	<Icon SvgComponent={Ikon_Organisationsnummer} {...props} />
)
export const PlatsIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Plats} {...props} />
export const PlockserviceIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Plockservice} {...props} />
export const PostortIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Postort} {...props} />
export const SäckIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Säck} {...props} />
export const SoptunnaIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Soptunna} {...props} />
export const TelefonIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Telefon} {...props} />
export const TidIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Tid} {...props} />
export const TjänsterIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Tjänster} {...props} />
export const VarukorgIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Varukorg} {...props} />
export const HomeIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Hem} {...props} />
export const PilUppIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_PilUpp} {...props} />
export const PilNerIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_PilNer} {...props} />
export const FoldableIcon = (props: DetailedIconProps) => <Icon SvgComponent={Foldable} {...props} />
export const ThinBagIcon = (props: DetailedIconProps) => <Icon SvgComponent={Thin_Bag} {...props} />
export const ThinContainerIcon = (props: DetailedIconProps) => <Icon SvgComponent={Thin_Container} {...props} />
export const ThinVesselIcon = (props: DetailedIconProps) => <Icon SvgComponent={Thin_Vessel} {...props} />
export const CrossIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Cross} {...props} />
export const TrashIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Trash} {...props} />
export const PenIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Pen} {...props} />
export const FiltreraIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Filtrera} {...props} />
export const KundvagnIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Kundvagn} {...props} />
export const ListaOrdersIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_ListaOrders} {...props} />
export const AccepteradOrderStatusIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Accepterad} {...props} />
export const AvslutadOrderStatusIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Avslutad} {...props} />
export const EjHanteradOrderStatusIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Ej_hanterad} {...props} />
export const NekadOrderStatusIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Nekad} {...props} />
export const BurgerIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_BurgerIcon} {...props} />
export const SökOrderIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_SokOrder} {...props} />
export const ZonkartaIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Zonkarta} {...props} />
export const LaddanerIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Laddaner} {...props} />
export const Question = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_question} {...props} />
export const Bags = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_bags} {...props} />
export const BusinessDetails = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_businessDetails} {...props} />
export const ClientContactDetails = (props: DetailedIconProps) => (
	<Icon SvgComponent={Onboarding_clientContactDetails} {...props} />
)
export const ConsumerContactDetails = (props: DetailedIconProps) => (
	<Icon SvgComponent={Onboarding_consumerContactDetails} {...props} />
)
export const Container = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_container} {...props} />
export const MailForOrders = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_mailForOrders} {...props} />
export const ProductsLibrary = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_productLibrary} {...props} />
export const WasteTypeLibrary = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_wasteTypeLibrary} {...props} />
export const SelectedProducts = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_selectedProducts} {...props} />
export const SelectedWasteTypes = (props: DetailedIconProps) => (
	<Icon SvgComponent={Onboarding_selectedWasteTypes} {...props} />
)
export const TimeSlots = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_timeSlots} {...props} />
export const ExampleTimeSlots = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_exampleTimeSlots} {...props} />
export const SelectedTimeSlots = (props: DetailedIconProps) => (
	<Icon SvgComponent={Onboarding_selectedTimeSlots} {...props} />
)
export const TransportationTerms = (props: DetailedIconProps) => (
	<Icon SvgComponent={Onboarding_transportationTerms} {...props} />
)
export const TransportLibrary = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_transportLibrary} {...props} />
export const Vessel = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_vessel} {...props} />
export const CommentIcon = (props: DetailedIconProps) => <Icon SvgComponent={Onboarding_comment} {...props} />

export const TabIconCustomerDetails = (props: DetailedIconProps) => (
	<Icon SvgComponent={Tab_Icon_CustomerDetails} {...props} />
)
export const TabIconResources = (props: DetailedIconProps) => <Icon SvgComponent={Tab_Icon_Resources} {...props} />
export const TabIconTimeSlot = (props: DetailedIconProps) => <Icon SvgComponent={Tab_Icon_TimeSlot} {...props} />
export const TabIconTransportation = (props: DetailedIconProps) => (
	<Icon SvgComponent={Tab_Icon_Transportation} {...props} />
)
export const TabIconWasteType = (props: DetailedIconProps) => <Icon SvgComponent={Tab_Icon_WasteType} {...props} />

export const TabIconChevronUp = (props: DetailedIconProps) => <Icon SvgComponent={Tab_Icon_Chevron_Up} {...props} />
export const InternetIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Internet} {...props} />
export const CustomersIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Customers} {...props} />
export const PinNoFillIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_PinNoFill} {...props} />
export const HandPointingIcon = (props: DetailedIconProps) => <Icon SvgComponent={Hand_Pointing} {...props} />
export const SendIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Skicka} {...props} />
export const BilagaIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Bilaga} {...props} />
export const NotifikationIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Notifikation} {...props} />
export const PinmapBlackIcon = (props: DetailedIconProps) => <Icon SvgComponent={Pinmap_Black} {...props} />
export const PinmapPurpleIcon = (props: DetailedIconProps) => <Icon SvgComponent={Pinmap_Purple} {...props} />

export const DiscountIcon = (props: DetailedIconProps) => <Icon SvgComponent={Ikon_Rabatt} {...props} />
