import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import { LoginResponse } from "Auth/Auth.types"
import { GetUserInvitation } from "CustomerPortal/UserInvitations/UsersInvitations"
import { isUndefined } from "lodash"
import { API, ServerError } from "network/API"
import { FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { SbtH2 } from "Orders/Components/Text/SbtH2/SbtH2"
import { SbtH4 } from "Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "Orders/Components/Text/SbtInvalid/SbtRHFError"
import { invalidPhoneNumberMessage, validatePhoneNumber } from "Orders/order-data-model"
import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"
import { HandleInvitationState } from "../HandleUserInvitation"
import style from "./../HandleUserInvitation.module.css"

export type CreateUserFormProps = {
	invitation: GetUserInvitation
	setState: (state: HandleInvitationState) => void
	setLoginDetails: (loginDetails: LoginResponse) => void
}

export const CreateUserForm: FC<CreateUserFormProps> = ({ invitation, setState, setLoginDetails }) => {
	const [queryParams] = useSearchParams()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const CreateUserSchema = z
		.object({
			email: z.string().email("Felaktigt format på e-post"),
			phoneNumber: z
				.string()
				.min(3, invalidPhoneNumberMessage)
				.refine(validatePhoneNumber, { message: invalidPhoneNumberMessage }),
			rawPassword: z.string().min(8, "Lösenordet måste vara minst 8 tecken långt"),
			rawPasswordAgain: z.string().min(8, "Lösenordet måste vara minst 8 tecken långt"),
			firstName: z.string().min(1),
			lastName: z.string().min(1),
		})
		.superRefine((val, ctx) => {
			if (val.rawPassword && val.rawPasswordAgain && val.rawPassword !== val.rawPasswordAgain) {
				ctx.addIssue({
					path: ["rawPasswordAgain"],
					code: z.ZodIssueCode.custom,
					message: "Lösenordet måste matcha i båda rutorna",
				})
			}
		})
	type CreateUserType = z.input<typeof CreateUserSchema>

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		formState: { errors, isValid },
	} = useForm<CreateUserType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(CreateUserSchema)(data, context, options)
		},
	})

	useEffect(() => {
		const email = isUndefined(invitation.requestDetails.userDetails.email)
			? ""
			: invitation.requestDetails.userDetails.email
		setValue("email", email)
		const phoneNumber = isUndefined(invitation.requestDetails.userDetails.phoneNumber)
			? ""
			: invitation.requestDetails.userDetails.phoneNumber
		setValue("phoneNumber", phoneNumber)
		const firstName = isUndefined(invitation.requestDetails.userDetails.firstName)
			? ""
			: invitation.requestDetails.userDetails.firstName
		setValue("firstName", firstName)
		const lastName = isUndefined(invitation.requestDetails.userDetails.lastName)
			? ""
			: invitation.requestDetails.userDetails.lastName
		setValue("lastName", lastName)
	}, [invitation])

	const acceptInvitation: SubmitHandler<CreateUserType> = async (data: CreateUserType) => {
		const inviteIdentifier = queryParams.get("inviteId")
		setIsSubmitting(true)

		const acceptInvitationData: AcceptInvitationData = {
			email: data.email,
			phoneNumber: data.phoneNumber,
			rawPassword: data.rawPassword,
			firstName: data.firstName,
			lastName: data.lastName,
		}

		const postBody: AcceptInvitationRequest = {
			acceptData: acceptInvitationData,
		}

		API.postWithRetries<LoginResponse>(
			`/customer-portal/user-invitation-v1/${inviteIdentifier}/accept`,
			postBody,
			{},
			1,
		)
			.then((loginResponse) => {
				setState(HandleInvitationState.CompleteNewUser)
				setIsSubmitting(false)
				setLoginDetails(loginResponse)
			})
			.catch((reason) => {
				if (reason instanceof ServerError) {
					if ("message" in reason.data) {
						const message = reason.data.message
						if (message === "phone_in_use") {
							setError("phoneNumber", { message: "Telefonnumret används redan" }, { shouldFocus: true })
						} else if (message === "email_in_use") {
							setError("email", { message: "E-postadressen används redan" }, { shouldFocus: true })
						}
					}
				}

				setIsSubmitting(false)
			})
	}

	return (
		<form key="form_accept_invite" id="form_accept_invite" onSubmit={handleSubmit(acceptInvitation)}>
			<SbtH2 styles={{ marginBottom: "1rem", marginTop: "0rem" }}>Skapa din användare</SbtH2>
			<div className={style.formRow}>
				<label className={style.formInputSection}>
					<SbtH4>Förnamn*</SbtH4>
					<input className={style.formInput} {...register("firstName")} placeholder="Förnamn" />
					<SbtRHFError error={errors.firstName} />
				</label>
				<label className={style.formInputSection}>
					<SbtH4>Efternamn*</SbtH4>
					<input className={style.formInput} {...register("lastName")} placeholder="Efternamn" />
					<SbtRHFError error={errors.lastName} />
				</label>
			</div>
			<label className={style.formInputSection}>
				<SbtH4>E-post*</SbtH4>
				<input className={style.formInput} {...register("email")} placeholder="Fyll i e-postaddress" />
				<SbtRHFError error={errors.email} />
			</label>
			<label className={style.formInputSection}>
				<SbtH4>Telefonnummer*</SbtH4>
				<input className={style.formInput} {...register("phoneNumber")} placeholder="0760000000" />
				<SbtRHFError error={errors.phoneNumber} />
			</label>
			<label className={style.formInputSection}>
				<SbtH4>Lösenord*</SbtH4>
				<input className={style.formInput} type="password" {...register("rawPassword")} />
				<SbtRHFError error={errors.rawPassword} />
			</label>
			<label className={style.formInputSection}>
				<SbtH4>Upprepa lösenord*</SbtH4>
				<input className={style.formInput} type="password" {...register("rawPasswordAgain")} />
				<SbtRHFError error={errors.rawPasswordAgain} />
			</label>
			<FinalizeButton disabled={!isValid || isSubmitting} type="submit">
				Acceptera inbjudan
				{isSubmitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
			</FinalizeButton>
		</form>
	)
}

type AcceptInvitationData = {
	email: string
	phoneNumber: string
	rawPassword: string
	firstName: string
	lastName: string
}

type AcceptInvitationRequest = {
	acceptData: AcceptInvitationData
}
