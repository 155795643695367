import { exhaustive } from "exhaustive"
import { isEmpty, map } from "lodash"
import { FC, useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { useLocation } from "react-router-dom"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ProductCategoryInstance } from "../../Client/ClientInstance"
import { useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import {
	GoodsProductDefinition,
	ProductSelectionConfigSecondaryStep,
	ProductSelectionConfigStep,
	ProductServiceUnit,
	WasteProductDefinition,
} from "../../Client/ProductDefinitionsByCategories"
import { ArrowLeftIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { cls } from "../../Shared/cls"
import { notNull } from "../../Shared/notNull"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { WidgetRendererV2 } from "../../Shared/Widget/Widgets"
import { FinalizeButton } from "../Components/Form/Buttons/Buttons"
import { MultiStateSwitch } from "../Components/Form/MultiStateSwitch/MultiStateSwitch"
import { getBy } from "../Helpers"
import {
	goodsCategoryPackagingSelectionElement,
	ProductInformationAndSelectionModule,
	ProductSelectionReturnValue,
	wasteCategoryAmountSelectionElement,
	wasteCategoryNonDiscreteAmountSelectionElement,
	wasteCategoryWasteSelectionElement,
} from "../ProductInformationAndSelectionModule/ProductInformationAndSelectionModule"
import style from "./FullPageProductSelection.module.css"
import {
	AmountWasteProductDefinitionWithPrice,
	ProductDefinitionWithPrice,
	resolveProductDefinitionsWithPriceData,
	WasteProductDefinitionWithPrice,
	WasteWasteProductDefinitionWithPrice,
} from "./resolveProductDefinitionsWithPriceData"

type Props = {
	product: WasteProductDefinition | GoodsProductDefinition
	category: ProductCategoryInstance
	onProductSelected: (data: ProductSelectionReturnValue[]) => void
	decrementProduct: (data: ProductSelectionReturnValue) => void
	onCancel: () => void
}
export const FullPageProductSelection: FC<Props> = (props: Props) => {
	const client = useClient()
	const auth = useAuth()
	const location = useLocation()
	const consumerCatalog = useConsumerCatalog()
	const isMobileSize = useMediaQuery({ query: `(max-width: 1100px)` })

	useEffect(() => {
		const element = document.getElementById("mainLayoutPageContent")
		if (element) {
			element.scrollTop = 0
		}
	}, [])

	const [showRegularBasket] = useBrandedLocalStorage("show-regular-basket", z.boolean(), {
		defaultValue: false,
	})

	const [primaryStepProduct, setPrimaryStepProduct] = useState<ProductDefinitionWithPrice | null>(() => {
		let serviceId = null

		if (props.category.type === "WasteCategory" && props.category.productSelectionConfig?.steps) {
			serviceId = (Object.values(props.category.productSelectionConfig.steps)[0]?.services || [])[0] || null
		}

		const ret = resolveProductDefinitionsWithPriceData([props.product], serviceId, consumerCatalog, client, auth)

		if (!ret || isEmpty(ret)) {
			return null
		}

		return ret[0] || null
	})
	const [secondaryStepProduct, setSecondaryStepProduct] = useState<ProductDefinitionWithPrice | null>(() => {
		let serviceId = null

		if (props.category.type === "WasteCategory" && props.category.productSelectionConfig?.steps) {
			const secondaryStep = (Object.values(props.category.productSelectionConfig.steps)[0]?.secondarySteps || [])[0]

			if (secondaryStep?.type === "Service") {
				serviceId = secondaryStep.serviceId
			}
		}

		const ret = resolveProductDefinitionsWithPriceData([props.product], serviceId, consumerCatalog, client, auth)

		if (!ret || isEmpty(ret)) {
			return null
		}

		return ret[0] || null
	})

	const [selectedPrimaryStep, setSelectedPrimaryStep] = useState<ProductSelectionConfigStep | null>(null)
	const [selectedPrimaryStepService, setSelectedPrimaryStepService] = useState<string | null>(null)

	const [selectedSecondaryStep, setSelectedSecondaryStep] = useState<ProductSelectionConfigSecondaryStep | null>(null)

	const [productSelectionReturnValues, setProductSelectionReturnValues] = useState<{
		[id: string]: ProductSelectionReturnValue
	}>({})

	function primaryStepSelectionBoxes() {
		if (!primaryStepProduct) {
			return null
		}

		return exhaustive(primaryStepProduct, "type", {
			WasteProductDefinition: (it) => {
				if (!it.category.productSelectionConfig) {
					return null
				}

				return (
					<div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
						<div className={style.selectionBox}>
							<div className={style.title}>{it.category.productSelectionConfig.stepsTitle}</div>
							<hr />
							<MultiStateSwitch
								onSelect={(option) => {
									if (!it.category.productSelectionConfig) {
										return
									}

									setProductSelectionReturnValues({})

									const step = getBy("id", option.key, it.category.productSelectionConfig.steps)
									if (step) {
										setSelectedPrimaryStep(step.value)

										const primaryStepService = (step.value?.services || [])[0]
										setSelectedPrimaryStepService(primaryStepService || null)

										const products = resolveProductDefinitionsWithPriceData(
											[props.product],
											primaryStepService || null,
											consumerCatalog,
											client,
											auth,
										)
										const product = products && products[0]
										if (product) {
											setPrimaryStepProduct(product)
										}

										if (step.value.secondarySteps && step.value.secondarySteps[0]) {
											setSelectedSecondaryStep(step.value.secondarySteps[0])
										} else if (!step.value.secondarySteps || step.value.secondarySteps.length === 0) {
											setSelectedSecondaryStep(null)
										}
									}
								}}
								options={map(it.category.productSelectionConfig.steps, (step) => {
									if (!step) {
										return null
									}
									return { key: step.id, value: step.name }
								}).filter(notNull)}
							/>
						</div>
						{primaryStepWasteSelectionElement(it)}
					</div>
				)
			},
			GoodsProductDefinition: (it) => {
				const title = it.category?.productSelectionConfig?.title
				const subTitle = it.category?.productSelectionConfig?.subTitle
				return (
					<div className={style.selectionBox}>
						<div className={style.title}>{title || "Leveranssätt"}</div>
						{subTitle ? <div className={style.subTitle}>{subTitle}</div> : null}
						<hr />
						<div className={cls(style.flexContentColumn, style.goodsSelectionContent)}>
							{
								goodsCategoryPackagingSelectionElement(
									it,
									productSelectionReturnValues[it.id] || null,
									(selectionReturnValue, action) => {
										if (isMobileSize) {
											if (!selectionReturnValue) {
												delete productSelectionReturnValues[it.id]
											} else {
												productSelectionReturnValues[it.id] = selectionReturnValue
											}
											setProductSelectionReturnValues(Object.assign({}, productSelectionReturnValues))
											return
										}

										const retVal = productSelectionReturnValues[it.id]

										const theNew: { [id: string]: ProductSelectionReturnValue } = {}
										if (selectionReturnValue && (action === "addClick" || action === "blur")) {
											theNew[it.id] = selectionReturnValue
											setProductSelectionReturnValues(Object.assign({}, theNew))
											props.onProductSelected(Object.values(theNew))
										} else if (selectionReturnValue && action === "removeClick" && retVal) {
											props.decrementProduct(retVal)
										} else if (selectionReturnValue === null && retVal) {
											props.decrementProduct(retVal)
										}
									},
									consumerCatalog,
								).element
							}
						</div>
					</div>
				)
			},
		})
	}

	function secondaryStepSelectionBoxes() {
		if (
			!secondaryStepProduct ||
			!selectedPrimaryStep ||
			!selectedPrimaryStep.secondarySteps ||
			(selectedPrimaryStep.secondarySteps && selectedPrimaryStep.secondarySteps.length === 0)
		) {
			return null
		}

		return exhaustive(secondaryStepProduct, "type", {
			WasteProductDefinition: (it) => {
				if (!it.category.productSelectionConfig) {
					return null
				}

				return (
					<div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
						<div className={style.selectionBox}>
							<div className={style.title}>{selectedPrimaryStep.secondaryStepsTitle}</div>
							<hr />
							{(selectedPrimaryStep.secondarySteps || []).length > 1 ? (
								<MultiStateSwitch
									onSelect={(option) => {
										if (!it.category.productSelectionConfig) {
											return
										}

										const secondaryStep = getBy(
											"id",
											option.key,
											selectedPrimaryStep.secondarySteps || [],
										)
										if (secondaryStep) {
											setSelectedSecondaryStep(secondaryStep.value)
											const products = resolveProductDefinitionsWithPriceData(
												[props.product],
												secondaryStep.value.type === "Service"
													? secondaryStep.value.serviceId
													: null,
												consumerCatalog,
												client,
												auth,
											)

											const product = products && products[0]
											if (product) {
												setSecondaryStepProduct(product)
											}
										}
									}}
									options={map(selectedPrimaryStep.secondarySteps || [], (step) => {
										if (!step) {
											return null
										}
										return { key: step.id, value: step.name }
									}).filter(notNull)}
								/>
							) : null}

							{secondaryStepWasteSelectionElement(it)}
						</div>
					</div>
				)
			},
			GoodsProductDefinition: () => {
				return null
			},
		})
	}

	function primaryStepWasteSelectionElement(product: WasteProductDefinitionWithPrice) {
		if (!selectedPrimaryStep) {
			return null
		}

		if (!selectedPrimaryStepService) {
			return null
		}

		return (
			<div className={style.selectionBox}>
				<div className={style.title}>{selectedPrimaryStep.title}</div>
				{selectedPrimaryStep.services && selectedPrimaryStep.services.length > 1 ? (
					<>
						<br />
						<MultiStateSwitch
							onSelect={(option) => {
								setSelectedPrimaryStepService(option.key)
								const products = resolveProductDefinitionsWithPriceData(
									[props.product],
									option.key,
									consumerCatalog,
									client,
									auth,
								)

								const product = products && products[0]
								if (product) {
									setPrimaryStepProduct(product)
								}
							}}
							options={map(selectedPrimaryStep.services || [], (serviceId) => {
								if (!serviceId) {
									return null
								}
								return { key: serviceId, value: product.category.services[serviceId]?.name || "" }
							}).filter(notNull)}
						/>
					</>
				) : null}
				<hr />
				<div className={cls(style.flexContentColumn, style.wasteSelectionContent)}>
					{exhaustive(product, "quantityType", {
						None: () => {
							return null
						},
						Waste: (product: WasteWasteProductDefinitionWithPrice) => {
							const productSelectReturnId = product.id + "_" + selectedPrimaryStepService
							return (
								wasteCategoryWasteSelectionElement(
									product,
									selectedPrimaryStepService,
									productSelectionReturnValues[productSelectReturnId] || null,
									(selectionReturnValue, action, clickedWasteTypeId) => {
										wasteCategoryWasteSelectionSetProductSelectionReturnValue(
											productSelectReturnId,
											selectionReturnValue,
											action,
											clickedWasteTypeId,
										)
									},
									"",
									null,
									consumerCatalog,
								)?.element || null
							)
						},
						Amount: (product: AmountWasteProductDefinitionWithPrice) => {
							const productSelectReturnId = product.id + "_" + selectedPrimaryStepService
							if (product.service.unit === ProductServiceUnit.Piece) {
								return (
									wasteCategoryAmountSelectionElement(
										product,
										selectedPrimaryStepService,
										(selectionReturnValue, action) => {
											wasteCategoryAmountSetProductSelectionReturnValue(
												productSelectReturnId,
												selectionReturnValue,
												action,
											)
										},
										consumerCatalog,
									)?.element || null
								)
							} else {
								return (
									wasteCategoryNonDiscreteAmountSelectionElement(
										product,
										selectedPrimaryStepService,
										(selectionReturnValue, action) => {
											wasteCategoryNonDiscreteAmountSetProductSelectionReturnValue(
												productSelectReturnId,
												selectionReturnValue,
												action,
											)
										},
										consumerCatalog,
									)?.element || null
								)
							}
						},
					})}
				</div>
			</div>
		)
	}

	function secondaryStepWasteSelectionElement(product: WasteProductDefinitionWithPrice) {
		if (!selectedSecondaryStep) {
			return null
		}

		return (
			<div style={{ marginTop: "30px" }}>
				<div className={style.secondaryTitle}>{selectedSecondaryStep.title}</div>
				<div className={style.secondarySubTitle}>{selectedSecondaryStep.subTitle}</div>
				<br />
				{selectedSecondaryStep.type === "Service" ? (
					<div className={cls(style.flexContentColumn, style.wasteSelectionContent)}>
						{exhaustive(product, "quantityType", {
							None: () => {
								return null
							},
							Waste: (product: WasteWasteProductDefinitionWithPrice) => {
								const productSelectReturnId = product.id + "_" + selectedSecondaryStep.serviceId
								return (
									wasteCategoryWasteSelectionElement(
										product,
										selectedSecondaryStep.serviceId,
										productSelectionReturnValues[productSelectReturnId] || null,
										(selectionReturnValue, action, clickedWasteTypeId) => {
											wasteCategoryWasteSelectionSetProductSelectionReturnValue(
												productSelectReturnId,
												selectionReturnValue,
												action,
												clickedWasteTypeId,
											)
										},
										"",
										null,
										consumerCatalog,
									)?.element || null
								)
							},
							Amount: (product: AmountWasteProductDefinitionWithPrice) => {
								const productSelectReturnId = product.id + "_" + selectedSecondaryStep.serviceId
								if (product.service.unit === ProductServiceUnit.Piece) {
									return (
										wasteCategoryAmountSelectionElement(
											product,
											selectedSecondaryStep.serviceId,
											(selectionReturnValue, action) => {
												wasteCategoryAmountSetProductSelectionReturnValue(
													productSelectReturnId,
													selectionReturnValue,
													action,
												)
											},
											consumerCatalog,
										)?.element || null
									)
								} else {
									return (
										wasteCategoryNonDiscreteAmountSelectionElement(
											product,
											selectedSecondaryStep.serviceId,
											(selectionReturnValue, action) => {
												wasteCategoryNonDiscreteAmountSetProductSelectionReturnValue(
													productSelectReturnId,
													selectionReturnValue,
													action,
												)
											},
											consumerCatalog,
										)?.element || null
									)
								}
							},
						})}
					</div>
				) : null}
			</div>
		)
	}

	function wasteCategoryWasteSelectionSetProductSelectionReturnValue(
		productSelectReturnId: string,
		selectionReturnValue: ProductSelectionReturnValue | null,
		action: "text" | "addClick" | "removeClick" | "blur",
		clickedWasteTypeId: string,
	) {
		if (isMobileSize) {
			if (!selectionReturnValue) {
				delete productSelectionReturnValues[productSelectReturnId]
			} else {
				productSelectionReturnValues[productSelectReturnId] = selectionReturnValue
			}
			setProductSelectionReturnValues(Object.assign({}, productSelectionReturnValues))
			return
		}

		const theNew: { [id: string]: ProductSelectionReturnValue } = {}

		if (selectionReturnValue) {
			selectionReturnValue.selectedWasteTypeAmounts = {
				[clickedWasteTypeId]: 1,
			}
			theNew[productSelectReturnId] = selectionReturnValue
		}

		if (selectionReturnValue && action === "addClick") {
			setProductSelectionReturnValues(Object.assign({}, theNew))
			props.onProductSelected(Object.values(theNew))
		} else if (selectionReturnValue && (action === "removeClick" || action === "blur")) {
			setProductSelectionReturnValues(Object.assign({}, theNew))

			const obj = theNew[productSelectReturnId]

			if (obj) {
				props.decrementProduct(obj)
			}
		} else if (selectionReturnValue === null) {
			const obj = productSelectionReturnValues[productSelectReturnId]

			if (obj) {
				// Set the selected waste type with amount 1, to correctly trigger decrement on the waste type
				obj.selectedWasteTypeAmounts = {
					[clickedWasteTypeId]: 1,
				}
				productSelectionReturnValues[productSelectReturnId] = obj
				setProductSelectionReturnValues(Object.assign({}, productSelectionReturnValues))
				props.decrementProduct(obj)
			}
		}
	}

	function wasteCategoryAmountSetProductSelectionReturnValue(
		productSelectReturnId: string,
		selectionReturnValue: ProductSelectionReturnValue | null,
		action: "text" | "addClick" | "removeClick" | "blur",
	) {
		if (isMobileSize) {
			if (!selectionReturnValue) {
				delete productSelectionReturnValues[productSelectReturnId]
			} else {
				productSelectionReturnValues[productSelectReturnId] = selectionReturnValue
			}
			setProductSelectionReturnValues(Object.assign({}, productSelectionReturnValues))
			return
		}

		const theNew: { [id: string]: ProductSelectionReturnValue } = {}
		if (selectionReturnValue && action === "addClick") {
			theNew[productSelectReturnId] = selectionReturnValue
			setProductSelectionReturnValues(Object.assign({}, theNew))
			props.onProductSelected(Object.values(theNew))
		} else if (selectionReturnValue && action === "removeClick") {
			setProductSelectionReturnValues(Object.assign({}, productSelectionReturnValues))

			const obj = productSelectionReturnValues[productSelectReturnId]

			if (obj) {
				props.decrementProduct(obj)
			}
		} else if (selectionReturnValue === null) {
			setProductSelectionReturnValues(Object.assign({}, productSelectionReturnValues))

			const obj = productSelectionReturnValues[productSelectReturnId]

			if (obj) {
				props.decrementProduct(obj)
			}
		}
	}

	function wasteCategoryNonDiscreteAmountSetProductSelectionReturnValue(
		productSelectReturnId: string,
		selectionReturnValue: ProductSelectionReturnValue | null,
		action: "text" | "addClick" | "removeClick" | "blur",
	) {
		if (isMobileSize) {
			if (!selectionReturnValue) {
				delete productSelectionReturnValues[productSelectReturnId]
			} else {
				productSelectionReturnValues[productSelectReturnId] = selectionReturnValue
			}
			setProductSelectionReturnValues(Object.assign({}, productSelectionReturnValues))
			return
		}

		const theNew: { [id: string]: ProductSelectionReturnValue } = {}
		if (selectionReturnValue && action === "addClick") {
			theNew[productSelectReturnId] = selectionReturnValue
			setProductSelectionReturnValues(Object.assign({}, theNew))
			props.onProductSelected(Object.values(theNew))
		} else if (selectionReturnValue && action === "removeClick") {
			const obj = productSelectionReturnValues[productSelectReturnId]

			if (obj) {
				props.decrementProduct(obj)
			}
		} else if (selectionReturnValue === null && action !== "blur") {
			const obj = productSelectionReturnValues[productSelectReturnId]

			if (obj) {
				props.decrementProduct(obj)
			}
		}
	}

	function isOrderPage(): boolean {
		return location.pathname === `/${client.identifier}/order`
	}

	if (!primaryStepProduct) {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	return (
		<div>
			<ArrowLeftIcon
				size={28}
				className={style.backArrow}
				onClick={() => {
					props.onCancel()
				}}
			/>
			<div className={cls(style.wrapper, { [style.regularBasketVisible]: showRegularBasket && isOrderPage() })}>
				<div className={style.productBox}>
					<ProductInformationAndSelectionModule
						nameAndDimensionsClass={style.productNameAndDimensionsClass}
						renderInline={true}
						product={primaryStepProduct}
						descriptionOpen={false}
						category={props.category}
						possibleServices={props.category?.type === "WasteCategory" ? props.category?.services || {} : {}}
						onClose={() => {}}
						onProductSelected={() => {}}
						onSetSelectedServiceId={() => {}}
					/>
				</div>
				<div className={cls(style.flexContentColumn, style.selectionBoxesColumn)}>
					{primaryStepSelectionBoxes()}
					{secondaryStepSelectionBoxes()}
					{isMobileSize ? (
						<FinalizeButton
							style={{ maxWidth: "700px" }}
							disabled={Object.keys(productSelectionReturnValues).length === 0}
							onClick={() => {
								if (Object.keys(productSelectionReturnValues).length === 0) {
									return
								}
								props.onProductSelected(Object.values(productSelectionReturnValues))
								setProductSelectionReturnValues({})
							}}>
							Lägg till i varukorgen
						</FinalizeButton>
					) : null}
				</div>
			</div>
			<div style={{ width: "100%" }}>
				{map(props.category.productSelectionConfig?.widgets, (widget) => {
					if (!widget) {
						return null
					}
					return (
						<div key={widget.title + props.category.id} className={style.widgetSection}>
							<div className={style.widgetTitle}>{widget.title}</div>
							<WidgetRendererV2 apiWidgets={[widget.widget]} scrollToTopOnWidgetChange={false} />
						</div>
					)
				})}
			</div>
		</div>
	)
}
