import { CategorySelectionSection } from "Orders/OrderContainer/SubComponents/CategorySelectionSection"
import { FC } from "react"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { getLogger } from "../../../Logging/getLogger"
import { notNull } from "../../../Shared/notNull"

const logger = getLogger("CategorySelection")

type Props = {
	selectedCategory: string | null
	onSetSelectedCategory: (category: string) => void
}

export const CategorySelection: FC<Props> = ({ selectedCategory, onSetSelectedCategory }) => {
	const client = useClient()

	return (
		<>
			{client.categoryKeys.length > 1 ? (
				<CategorySelectionSection
					categories={client.categoryKeys
						.map((categoryName) => {
							const category = client.categories[categoryName]
							if (category == null) {
								logger.error(`Category missing! name: ${categoryName}`)
								return null
							}
							return {
								title: category.name,
								key: category.name,
								categoryImages: category.categoryImages,
								image: category.image,
								selected: selectedCategory === category.name,
								order: category.order,
							}
						})
						.filter(notNull)}
					onSelected={(category) => {
						if (selectedCategory !== category.title) {
							onSetSelectedCategory(category.title)
						}
					}}
				/>
			) : null}
		</>
	)
}
