import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { v4 } from "uuid"
import { z } from "zod"
import { useClient } from "../../Client/ClientAndUserProvider"
import { TrashIcon } from "../../Icons/Icon"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import { SbtH4 } from "../../Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { invalidPhoneNumberMessage, validatePhoneNumber } from "../../Orders/order-data-model"
import { cls } from "../../Shared/cls"
import { GetContactPerson } from "../ConsumerContactPersons/ConsumerContactPersons"
import { GetProject } from "../CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import style from "./AddEditContactPersonModal.module.css"

type AddEditContactPersonProps = {
	consumerId: string
	contactPerson?: GetContactPerson
	onClose: () => void
	onDone: (newPerson: GetContactPerson | null) => void
}

const ContactPersonSchema = z.object({
	name: z.string().min(1, "Ett namn krävs"),
	phone: z.string().min(3, invalidPhoneNumberMessage).refine(validatePhoneNumber, { message: invalidPhoneNumberMessage }),
})

type ContactPersonType = z.input<typeof ContactPersonSchema>

export type NewContactPerson = {
	name: string
	phone: string
}

export type NewContactPersonRequest = {
	contactPerson: NewContactPerson
}

export const AddEditContactPersonModal: FC<AddEditContactPersonProps> = ({
	consumerId,
	contactPerson,
	onClose,
	onDone,
}) => {
	const client = useClient()

	const [submitting, setSubmitting] = useState<boolean>(false)
	const [disableClose, setDisableClose] = useState(false)

	const [projects, setProjects] = useState<GetProject[] | null>(null)

	useEffect(() => {
		if (contactPerson && consumerId) {
			API.getWithRetries<GetProject[]>(
				`/customer-portal/contact-person-definitions-v1/${client.identifier}/${consumerId}/${contactPerson.id}/get-projects`,
			)
				.then((res) => {
					setProjects(res)
				})
				.catch(() => {
					setProjects(null)
				})
		} else {
			setProjects(null)
		}
	}, [client.identifier, consumerId, contactPerson])

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<ContactPersonType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(ContactPersonSchema)(data, context, options)
		},
		defaultValues: {
			name: contactPerson?.name || "",
			phone: contactPerson?.phone || "",
		},
	})

	const onSubmit: SubmitHandler<ContactPersonType> = async (data: ContactPersonType) => {
		setSubmitting(true)

		const body: NewContactPersonRequest = {
			contactPerson: {
				name: data.name,
				phone: data.phone,
			},
		}

		let promise

		if (contactPerson) {
			promise = API.patchWithRetries<{}>(
				`/customer-portal/contact-person-definitions-v1/${client.identifier}/${consumerId}/${contactPerson.id}`,
				body,
			)
		} else {
			promise = API.postWithRetries<GetContactPerson>(
				`/customer-portal/contact-person-definitions-v1/${client.identifier}/${consumerId}`,
				body,
			)
		}

		promise
			.then((res: {} | GetContactPerson) => {
				setSubmitting(false)
				onClose()
				onDone("name" in res ? res : null)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	function deleteContactPerson() {
		if (!contactPerson) {
			return
		}
		setDisableClose(true)

		setTimeout(() => {
			if (window.confirm("Är du säker att du vill ta bort kontaktpersonen?")) {
				API.deleteWithRetries<void>(
					`/customer-portal/contact-person-definitions-v1/${client.identifier}/${consumerId}/${contactPerson.id}`,
				).then(() => {
					setSubmitting(false)
					setDisableClose(false)
					onClose()
					onDone(null)
				})
			} else {
				setTimeout(() => {
					setDisableClose(false)
				}, 200)
			}
		}, 10)
	}

	return (
		<ModulePopup
			disableClose={submitting || disableClose}
			className={style.modal}
			onClose={() => {
				onClose()
			}}>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<div className={style.header}>
					{contactPerson ? "Redigera kontaktperson" : "Lägg till en ny kontaktperson"}
				</div>
				<form style={{ overflowY: "auto" }} onSubmit={handleSubmit(onSubmit)}>
					<div style={{ overflowY: "auto" }}>
						<label className={style.inputField}>
							<SbtH4>Namn</SbtH4>
							<input {...register("name")} placeholder="Fyll i ett namn" />
							<SbtRHFError error={errors.name} />
						</label>
						<label className={style.inputField}>
							<SbtH4>Telefonnummer</SbtH4>
							<input {...register("phone")} placeholder="Fyll i ett telefonnummer" />
							<SbtRHFError error={errors.phone} />
						</label>
					</div>
					{contactPerson ? (
						<>
							{projects && projects.length > 0 ? (
								<div style={{ marginBottom: "30px" }}>
									<div
										className={style.sbt}
										style={{ marginBottom: "5px", fontSize: "20px", fontWeight: 600 }}>
										Tillagd i följande projekt:
									</div>
									{projects.map((project) => {
										return (
											<div
												key={v4()}
												className={style.sbt}
												style={{ fontSize: "16px", fontWeight: 400 }}>
												{project.marking || project.address.street}
											</div>
										)
									})}
								</div>
							) : null}
							<div
								className={style.deleteContactPerson}
								onClick={() => {
									deleteContactPerson()
								}}>
								<TrashIcon
									size={22}
									iconClassName={cls(style.iconColorStroke, style.iconColorFill, {
										[style.disabled]: disableClose,
									})}
								/>
								<span style={{ lineHeight: "16px" }} className={cls({ [style.disabled]: disableClose })}>
									Ta bort kontaktperson
								</span>
							</div>
						</>
					) : null}
					<FinalizeButton disabled={!isValid || submitting} type="submit">
						{contactPerson ? "Spara ändringar" : "Lägg till"}
						{submitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
				</form>
			</div>
		</ModulePopup>
	)
}
