import { FC, useEffect, useState } from "react"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { API } from "../../network/API"
import { ConsumerContactPersons } from "../ConsumerContactPersons/ConsumerContactPersons"
import {
	CustomerPortalConsumerInformation,
	GetConsumer,
} from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { Users } from "../Users/Users"
import style from "./CustomerPortalConsumerDetails.module.css"

type CustomerPortalConsumerDetailsProps = {
	consumerId: string
}
export const CustomerPortalConsumerDetails: FC<CustomerPortalConsumerDetailsProps> = ({ consumerId }) => {
	const auth = useAuth()
	const client = useClient()
	const [showUsers, setShowUsers] = useState<boolean>(true)
	const [consumer, setConsumer] = useState<GetConsumer | null>(null)

	useEffect(() => {
		API.getWithRetries<GetConsumer>(
			`/customer-portal/consumers-v1/${client.identifier}/details/${consumerId}`,
			true,
			undefined,
			10,
		)
			.then((res) => {
				setConsumer(res)
			})
			.catch(() => {})
	}, [client.identifier, consumerId, auth.Me])

	if (!consumer) {
		return null
	}

	return (
		<div className={style.wrapper}>
			<CustomerPortalConsumerInformation
				consumer={consumer}
				onInactivate={() => {
					setShowUsers(false)
					setTimeout(() => {
						setShowUsers(true)
					}, 15)
				}}
				onConsumerUpdated={() => {
					auth.refreshMeData()
				}}
			/>
			<div className={style.flexColumns}>
				{showUsers ? (
					<Users type={"Consumer"} clientIdentifier={client.identifier} consumerId={consumerId} />
				) : null}
				<ConsumerContactPersons consumerId={consumerId} />
			</div>
		</div>
	)
}
