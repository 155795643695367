import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { filter, groupBy, some, sortBy } from "lodash"
import { FC, useEffect, useState } from "react"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ConsumerCatalogInstance, ConsumerProjectMode, useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { CrossIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { FieldModuleBox } from "../../Orders/Components/FieldModuleBox/FieldModuleBox"
import { FieldModuleBoxWrapper } from "../../Orders/Components/FieldModuleBox/FieldModuleBoxWrapper"
import { AccentButton, Button } from "../../Orders/Components/Form/Buttons/Buttons"
import { HorizontalScrollBox } from "../../Orders/Components/HorizontalScrollBox/HorizontalScrollBox"
import { Mbt } from "../../Orders/Components/Text/Mbt/Mbt"
import { MbtH5 } from "../../Orders/Components/Text/MbtH5/MbtH5"
import { Project } from "../../Orders/order-data-model"
import { ProjectInputModule } from "../../Orders/ProjectInputModule/ProjectInputModule"
import { projectContactInformation } from "../../Orders/ProjectSelectModule/ProjectSelectModule"
import { cls } from "../../Shared/cls"
import {
	GetProject,
	NewProjectRequest,
	ProjectStatus,
} from "../CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import style from "./CustomerPortalProjectsList.module.css"

type CustomerPortalProjectsListProps = {
	consumerId: string
	projects: GetProject[] | null
	selectedProject: GetProject | null
	consumerCatalog: ConsumerCatalogInstance | null
	selectProject: (project: GetProject) => void
	onMouseEnter: (project: GetProject) => void
	onMouseLeave: (project: GetProject) => void
	onNewProject: (project: GetProject) => void
}

export async function addNewProject(
	clientIdentifier: string,
	consumerId: string,
	project: Project | GetProject,
	onDone: (res: GetProject) => void,
) {
	const body: NewProjectRequest = {
		project: {
			street: "street" in project ? project.street : project.address.street,
			city: "city" in project ? project.city : project.address.city,
			zipCode: "street" in project ? project.zipcode : project.address.zipCode,
			coordinates: "street" in project ? project.coordinates : project.address.coordinates,
			marking: project.marking,
			contactPersonIds: project.contactPersons?.map((x) => x.id) ?? [],
			status: ProjectStatus.Active,
		},
	}
	await API.postWithRetries<GetProject, NewProjectRequest>(
		`/customer-portal/project-definitions-v1/${clientIdentifier}/${consumerId}`,
		body,
	)
		.then((res) => {
			onDone(res)
		})
		.catch(() => {})
}

export const CustomerPortalProjectsList: FC<CustomerPortalProjectsListProps> = (props: CustomerPortalProjectsListProps) => {
	const client = useClient()
	const consumerCatalogUse = useConsumerCatalog()

	const [filterString, setFilterString] = useState("")
	const [projects, setProjects] = useState<GetProject[] | null>(props.projects)
	const [selectedStatus, setSelectedStatus] = useState<ProjectStatus | null>(null)
	const [showNewProject, setShowNewProject] = useState<boolean>(false)

	useEffect(() => {
		setProjects(props.projects)
	}, [props.projects])

	function getFilteredProjects(projects: GetProject[], filterString: string): GetProject[] {
		let filteredProjects: GetProject[] = projects
		if (filterString) {
			filteredProjects = filter(filteredProjects, (project) => {
				return (
					project.address.city.normalize("NFC").toLocaleLowerCase().indexOf(filterString) > -1 ||
					project.address.street.normalize("NFC").toLocaleLowerCase().indexOf(filterString) > -1 ||
					(project.address.zipCode ? project.address.zipCode.indexOf(filterString) > -1 : false) ||
					(project.marking
						? project.marking.normalize("NFC").toLocaleLowerCase().indexOf(filterString) > -1
						: false) ||
					some(project.contactPersons, (contactPerson) => {
						return (
							contactPerson.name.normalize("NFC").toLocaleLowerCase().indexOf(filterString) > -1 ||
							contactPerson.phone.indexOf(filterString) > -1
						)
					})
				)
			})
		}

		filteredProjects = sortBy(filteredProjects, (x) => {
			if ((props.consumerCatalog || consumerCatalogUse).features.projectMode === ConsumerProjectMode.SimpleAddress) {
				return x.address.street.toLocaleLowerCase()
			}
			return (x.marking || x.address.street).toLocaleLowerCase()
		})

		if (selectedStatus !== null) {
			filteredProjects = filter(filteredProjects, (x) => {
				return x.status === selectedStatus
			})
		} else {
			const grouped = groupBy(filteredProjects, "status")
			filteredProjects = [ProjectStatus.Active, ProjectStatus.Inactive].flatMap((x) => {
				return grouped[x] || []
			})
		}

		return filteredProjects
	}

	function addProject(project: Project | GetProject) {
		addNewProject(client.identifier, props.consumerId, project, (res) => {
			props.onNewProject(res)
			setProjects((x) => (x ? [...x, res] : [res]))
		})
	}

	if (!projects) {
		return (
			<AbsolutCentered>
				<Loader></Loader>
			</AbsolutCentered>
		)
	}

	return (
		<>
			{showNewProject ? (
				<ProjectInputModule
					consumerCatalog={props.consumerCatalog || undefined}
					consumerId={props.consumerId}
					onClose={() => {
						setShowNewProject(false)
					}}
					inputProject={{ project: {}, isNew: true }}
					onDone={addProject}
				/>
			) : null}
			<div className={style.wrapper}>
				<HorizontalScrollBox
					className={style.statusSelectorWrapper}
					cellClassName={style.horizontalScrollCell}
					key={`consumerManagerMenuRow`}>
					<div
						className={cls(style.statusSelector, { [style.selectedStatus]: selectedStatus === null })}
						onClick={() => {
							setSelectedStatus(null)
						}}>
						Alla
					</div>
					<div
						className={cls(style.statusSelector, {
							[style.selectedStatus]: selectedStatus === ProjectStatus.Active,
						})}
						onClick={() => {
							setSelectedStatus(ProjectStatus.Active)
						}}>
						Pågående
					</div>
					<div
						className={cls(style.statusSelector, {
							[style.selectedStatus]: selectedStatus === ProjectStatus.Inactive,
						})}
						onClick={() => {
							setSelectedStatus(ProjectStatus.Inactive)
						}}>
						Avslutade
					</div>
				</HorizontalScrollBox>
				<div className={style.searchInputWrapper}>
					<span className={style.searchIcon}>
						<FontAwesomeIcon icon={faMagnifyingGlass} />
					</span>
					<input
						className={style.searchInput}
						onChange={(e) => setFilterString(e.target.value.toLocaleLowerCase())}
						placeholder={
							(props.consumerCatalog || consumerCatalogUse).features.projectMode ===
							ConsumerProjectMode.FullProject
								? "Sök i projekt"
								: "Sök i adresser"
						}
						value={filterString}
					/>
					<button onClick={() => setFilterString("")} className={style.clearSearchButton}>
						{filterString !== "" ? (
							<CrossIcon size={22} className={style.closeXIcon} iconClassName={style.closeXIconInside} />
						) : (
							<span style={{ marginLeft: "1rem" }} />
						)}
					</button>
				</div>
				<FieldModuleBoxWrapper className={style.listWrapper}>
					{getFilteredProjects(projects, filterString).map((project) => {
						const selected = props.selectedProject?.id === project.id
						let complementNeeded

						const catalog = props.consumerCatalog || consumerCatalogUse
						if (catalog.features.projectMode === ConsumerProjectMode.SimpleAddress) {
							complementNeeded = !project.address.coordinates
						} else {
							complementNeeded =
								!project.contactPersons ||
								project.contactPersons.length === 0 ||
								!project.address.coordinates ||
								(catalog.markingRequired && !project.marking)
						}
						return (
							<FieldModuleBox
								key={project.id + "_listItem"}
								selected={selected}
								className={style.listItem}
								onMouseEnter={() => {
									props.onMouseEnter(project)
								}}
								onMouseLeave={() => {
									props.onMouseLeave(project)
								}}>
								<div>
									{(props.consumerCatalog || consumerCatalogUse).features.projectMode ===
									ConsumerProjectMode.SimpleAddress ? (
										<>
											<MbtH5>{project.address.street}</MbtH5>
											<Mbt>
												{project.address.zipCode ? project.address.zipCode + " " : ""}
												{project.address.city}
											</Mbt>
										</>
									) : (
										<>
											<MbtH5>{project.marking || project.address.street}</MbtH5>
											<Mbt>
												{project.address.street},{" "}
												{project.address.zipCode ? project.address.zipCode + " " : ""}
												{project.address.city}
											</Mbt>
										</>
									)}

									<Mbt>
										{projectContactInformation(
											project,
											(props.consumerCatalog || consumerCatalogUse).features.projectMode ===
												ConsumerProjectMode.SimpleAddress,
										)}
									</Mbt>
									{complementNeeded ? (
										<div
											style={{
												color: "var(--invalid-color)",
												fontWeight: 400,
												marginTop: "5px",
												fontSize: "14px",
											}}>
											Kompletterande information krävs
										</div>
									) : null}
								</div>
								<Button
									onClick={() => {
										props.selectProject(project)
									}}
									className={style.statusButton}
									btnStyle={project.status === ProjectStatus.Active ? "Finalize" : "Accent"}>
									{project.status === ProjectStatus.Active ? "Pågående" : "Avslutad"}
								</Button>
							</FieldModuleBox>
						)
					})}
				</FieldModuleBoxWrapper>
				<AccentButton
					className={style.newProjectButton}
					onClick={() => {
						setShowNewProject(true)
					}}>
					{(props.consumerCatalog || consumerCatalogUse).features.projectMode ===
					ConsumerProjectMode.FullProject ? (
						<span>Nytt projekt</span>
					) : (
						<span>Ny adress</span>
					)}
					<span style={{ fontSize: "24px" }}>+</span>
				</AccentButton>
			</div>
		</>
	)
}
