import { FC } from "react"
import orderAcceptanceStyle from "../../ClientPortal/OrderAcceptance/OrderAcceptance.module.css"
import { ModulePopup, ModulePopupProps } from "../../Orders/Components/ModulePopup/ModulePopup"
import { cls } from "../../Shared/cls"
import { GetOrder } from "../CustomerPortalOrders/CustomerPortalOrders"
import { OrderDetails } from "../OrderDetails/OrderDetails"
import style from "./OrderInformation.module.css"

type Props = {
	order: GetOrder
	onUpdate: (order: GetOrder) => void
} & ModulePopupProps
export const OrderInformation: FC<Props> = ({ order, onClose, onUpdate, ...props }) => {
	return (
		<ModulePopup {...props} onClose={onClose} className={orderAcceptanceStyle.modal}>
			<div className={cls(orderAcceptanceStyle.contentWrapper, style.contentWrapper)}>
				<OrderDetails onUpdate={onUpdate} order={order} className={style.orderDetails} onTabChange={() => {}} />
			</div>
		</ModulePopup>
	)
}
