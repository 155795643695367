import { PhoneNumberUtil } from "google-libphonenumber"
import { isString } from "lodash"
import { z } from "zod"
import { Articles } from "../Client/articleTrees/Articles"
import { DiscountDescriptionId } from "../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"

export const validatePhoneNumber = (data: string) => {
	// Have to use try catch because the lib throws an exception in some cases when parsing instead of just returning false...
	try {
		if (isString(data)) {
			const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance()
			return phoneUtil.isValidNumberForRegion(phoneUtil.parse(data, "SE"), "SE")
		}
		return false
	} catch (e) {
		return false
	}
}

export const invalidPhoneNumberMessage: string = "Felaktigt format på telefonnummer"

export const ProjectContactPersonSchema = z.object({
	id: z.string().min(1),
	name: z.string().min(1),
	phone: z.string().min(3, invalidPhoneNumberMessage).refine(validatePhoneNumber, { message: invalidPhoneNumberMessage }),
})

export type ProjectContactPerson = z.input<typeof ProjectContactPersonSchema>

export const ProjectSchema = z.object({
	street: z.string().min(1),
	city: z.string().min(1),
	zipcode: z.string().optional(),
	coordinates: z.custom<google.maps.LatLngLiteral>().optional(),
	deliveryCoordinates: z.custom<google.maps.LatLngLiteral>().optional(),
	marking: z.string().optional(),
	/**
	 * Set contactName, contactPhone and contactPersons to optional to
	 * keep backwards compatibility and not remove every project for every person on page load
	 */
	contactName: z.string().optional(),
	contactPhone: z.string().optional(),
	contactPersons: z.array(ProjectContactPersonSchema).optional(),
	migrated: z.boolean().optional(),
})

export type Project = z.input<typeof ProjectSchema>

export const WasteTypeSchema = z.object({
	wasteTypeId: z.string(),
	amount: z.number(),
})

export const SelectedProductPackagingMethodSchema = z.object({
	id: z.string(),
	amount: z.number(),
})

export type SelectedProductPackagingMethod = z.input<typeof SelectedProductPackagingMethodSchema>

export const SelectedProductSchema = z.object({
	productId: z.string(),
	name: z.string(),
	amount: z.number().optional(),
	wasteType: WasteTypeSchema.optional(),
	serviceId: z.string().optional(),
	packagingMethod: SelectedProductPackagingMethodSchema.optional(),
	category: z.string(),
	uniqueId: z.string(), // used to give each object a unique id
})

const OrderItemTimeNamedSlotSchema = z.object({
	timeslotId: z.string(),
	specificTime: z.literal(false),
	timeName: z.string(),
})

const OrderItemSpecificTimeSchema = z.object({
	timeslotId: z.string(),
	specificTime: z.literal(true),
	timeValue: z.string(),
})

export const OrderItemTimeSchema = z.discriminatedUnion("specificTime", [
	OrderItemTimeNamedSlotSchema,
	OrderItemSpecificTimeSchema,
])

export const OrderItemDateSchema = z.object({
	dateSlotId: z.string(),
	specificDate: z.string().optional(),
})

export const OrderItemsProductsSchema = z.array(SelectedProductSchema)
export type OrderItemProductsType = z.input<typeof OrderItemsProductsSchema>

export const OrderItemSchema = z.object({
	id: z.string(),
	project: ProjectSchema,
	date: z.string().optional().or(OrderItemDateSchema.optional()),
	time: OrderItemTimeSchema.optional(),
	products: OrderItemsProductsSchema,
	category: z.string(),
	serviceId: z.string().optional(),
	transportId: z.string(), //Not needed in order request
	zoneId: z.string().optional(),
})

export type OrderItemDateType = OrderItemType["date"]

export const OrderItemsSchema = z.array(OrderItemSchema)
export type OrderItems = z.input<typeof OrderItemsSchema>

export type OrderItemType = z.input<typeof OrderItemSchema>

export type OrderItem = OrderItemType & {
	articles?: Articles
	products: OrderItemsProductsType
}

export type OrderItemProductType = z.input<typeof SelectedProductSchema>
export type OrderItemProduct = OrderItemProductType & {
	articles?: Articles
	discountDescriptionRef?: DiscountDescriptionId
}
export type OrderItemsProductsType = OrderItemProduct[]

export type OrderItemTime = z.input<typeof OrderItemTimeSchema>

export enum LicenseOptions {
	AGREE = "AGREE",
	DECLINED = "DECLINED",
}

export type LicenseAgreement = {
	skrappy: LicenseOptions
	client: LicenseOptions | null
}
