import { FC, useRef, useState } from "react"
import { cls } from "../../../Shared/cls"
import style from "./ExpandableIncrementor.module.css"

type Props = {
	name: string
	defaultValue: () => number
	min: number
	max: number
	validation?: (name: string, value: number) => boolean
	onChange: (name: string, value: number, action: "addClick" | "removeClick" | "text", currentValue: number) => void
	disableIncrement?: boolean
	onBlur?: (value: number) => void
}

export const ExpandableIncrementor: FC<Props> = ({
	max,
	min,
	name,
	defaultValue,
	onChange,
	validation,
	disableIncrement,
	onBlur,
}: Props) => {
	const [currentValue, setCurrentValue] = useState<number>(defaultValue)
	const initiallyShown = useRef(false)
	const validate = (aValue: number) => {
		if (aValue < min) return false

		if (aValue > max) return false

		return !(validation !== undefined && !validation(name, aValue))
	}

	const changeTo = (newValue: number, action: "addClick" | "removeClick" | "text", skipValidate: boolean) => {
		if (skipValidate || validate(newValue)) {
			setCurrentValue(newValue)
			onChange(name, newValue, action, currentValue)
		} else {
			if (newValue === 0) {
				setCurrentValue(1)
				onChange(name, 1, action, currentValue)
			} else if (newValue < min) {
				setCurrentValue(min)
				onChange(name, min, action, currentValue)
			} else {
				setCurrentValue(max)
				onChange(name, max, action, currentValue)
			}
		}
	}

	const increment = () => changeTo(currentValue + 1, "addClick", false)

	const decrement = () => changeTo(currentValue - 1, "removeClick", false)

	return (
		<div
			className={cls(style.wrapper, {
				[style.noWrapper]: currentValue === 0 && !initiallyShown.current,
			})}>
			<button
				disabled={!validate(currentValue - 1)}
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					decrement()
					if (currentValue - 1 === 0 && onBlur) {
						initiallyShown.current = false
						onBlur(0)
					}
				}}
				type="button"
				className={cls(style.buttons, {
					[style.dNone]: currentValue === 0 && !initiallyShown.current,
				})}>
				-
			</button>
			<input
				type="number"
				id={name}
				key={name}
				className={cls(style.value, { [style.dNone]: currentValue === 0 && !initiallyShown.current })}
				value={currentValue === -1 ? "" : currentValue}
				onChange={(event) => {
					if (event.target.value === "") {
						changeTo(0, "text", true)
					} else {
						changeTo(event.target.valueAsNumber, "text", false)
					}
				}}
				onFocus={(e) => {
					e.target.addEventListener(
						"wheel",
						function (e) {
							e.preventDefault()
						},
						{ passive: false },
					)
				}}
				onBlur={() => {
					if (currentValue < min) {
						changeTo(min, "text", true)
					}
					if (currentValue === 0) {
						initiallyShown.current = false
					}
					if (onBlur) {
						onBlur(currentValue < min ? min : currentValue)
					}
				}}
			/>
			<button
				disabled={!validate(currentValue + 1) || disableIncrement === true}
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					initiallyShown.current = true
					increment()
				}}
				type="button"
				className={style.buttons}>
				+
			</button>
		</div>
	)
}
