import { sortBy } from "lodash"
import { FC, useCallback, useEffect, useState } from "react"
import { v4 } from "uuid"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { KontaktIcon, PenIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { AccentButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { convertTextToInitials } from "../../Orders/Helpers"
import { RelativeCentered } from "../../RelativeCentered/RelativeCentered"
import { cls } from "../../Shared/cls"
import { AddEditContactPersonModal } from "../AddEditContactPersonModal/AddEditContactPersonModal"
import style from "./ConsumerContactPersons.module.css"

type ConsumerContactPersonsProps = {
	consumerId: string
}

type GetContactPersonBase = {
	id: string
	name: string
	phone: string
}

export type GetContactPersonReal = GetContactPersonBase & {
	type: "UserAccount"
	email?: string
	profilePictureUrl?: string
}

export type GetContactPersonNormal = GetContactPersonBase & {
	type: "ContactPerson"
}

export type GetContactPerson = GetContactPersonReal | GetContactPersonNormal

export type GetContactPersonDefinitionResponse = {
	contactPersons: GetContactPerson[]
}
export const ConsumerContactPersons: FC<ConsumerContactPersonsProps> = ({ consumerId }) => {
	const auth = useAuth()
	const client = useClient()
	const [contactPersons, setContactPersons] = useState<GetContactPerson[]>([])
	const [dataLoading, setDataLoading] = useState(true)
	const [, setTick] = useState(0)
	const [addEditContactPerson, setAddEditContactPerson] = useState<"new" | GetContactPerson | null>(null)

	const init = useCallback(() => {
		setDataLoading(true)
		if (!consumerId) {
			return
		}
		API.getWithRetries<GetContactPersonDefinitionResponse>(
			`/customer-portal/contact-person-definitions-v1/${client.identifier}/${consumerId}`,
			true,
		)
			.then((res) => {
				setContactPersons(sortBy(res.contactPersons, (x) => x.name.toLocaleLowerCase()))
				setDataLoading(false)
			})
			.catch(() => {
				setContactPersons([])
				setDataLoading(false)
			})
	}, [client.identifier, consumerId])

	useEffect(() => {
		init()
	}, [init, auth])

	if (!consumerId) {
		return null
	}

	if (dataLoading) {
		return (
			<div className={style.wrapper}>
				<RelativeCentered>
					<Loader />
				</RelativeCentered>
			</div>
		)
	}

	return (
		<>
			{addEditContactPerson ? (
				<AddEditContactPersonModal
					consumerId={consumerId}
					contactPerson={addEditContactPerson !== "new" ? addEditContactPerson : undefined}
					onClose={() => {
						setAddEditContactPerson(null)
					}}
					onDone={(newPerson) => {
						if (newPerson) {
							setContactPersons((persons) =>
								sortBy([newPerson, ...persons], (x) => x.name.toLocaleLowerCase()),
							)
						} else {
							init()
						}
					}}
				/>
			) : null}
			<div className={style.wrapper}>
				<div className={style.header}>Kontaktpersoner</div>
				<div className={style.topInfoText}>
					Användare med ett för- eller efternamn och ett telefonnummer läggs automatiskt till som kontaktpersoner
				</div>
				<div>
					{contactPersons
						.filter((x) => x.type === "ContactPerson")
						.map((contactPerson) => {
							return (
								<div
									key={v4()}
									className={cls(style.userRow, {
										[style.noClick]: contactPerson.type === "UserAccount",
									})}>
									<div className={style.profilePic}>
										{contactPerson.type === "UserAccount" ? (
											contactPerson.profilePictureUrl ? (
												<img
													src={contactPerson.profilePictureUrl}
													onError={() => {
														contactPerson.profilePictureUrl = undefined
														setTick((tick) => tick + 1)
													}}
													alt="Profilbild"
												/>
											) : (
												<div className={style.noProfilePic}>
													{convertTextToInitials(contactPerson.name)}
												</div>
											)
										) : (
											<div className={style.noProfilePic}>
												<KontaktIcon size={22} />
											</div>
										)}
									</div>
									<div className={style.userRowName}>
										<div style={{ fontWeight: 600 }}>{contactPerson.name}</div>
										<div style={{ wordBreak: "break-word" }}>
											{contactPerson.type === "UserAccount" && contactPerson.email
												? contactPerson.email + ", "
												: null}
											{contactPerson.phone}
										</div>
									</div>
									{contactPerson.type === "UserAccount" ? (
										<div />
									) : (
										<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
											<PenIcon
												size={22}
												className={style.editIcon}
												onClick={() => {
													setAddEditContactPerson(contactPerson)
												}}
											/>
										</div>
									)}
								</div>
							)
						})}
				</div>
				<AccentButton
					className={style.newContactPersonBtn}
					onClick={() => {
						setAddEditContactPerson("new")
					}}>
					<span>Ny kontaktperson</span>
					<span style={{ fontSize: "24px" }}>+</span>
				</AccentButton>
			</div>
		</>
	)
}
