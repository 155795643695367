import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { AbsolutCentered } from "../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../Auth/AuthContext"
import { Loader } from "../Loader/Loader"
import { getLogger, remoteLogPusher } from "../Logging/getLogger"
import { API, ServerError } from "../network/API"
import { PolygonTransportZone, TransportZoneApiResponse } from "../Orders/ProjectInputModule/ProjectInputModule"
import { EventQueue } from "../Shared/eventQueue"
import { plausible } from "../Shared/plausible"
import { useThrowAsync } from "../Shared/throwAsync"
import { ClientInstance, clientInstanceOf } from "./ClientInstance"
import { ClientNotFound } from "./ClientNotFound/ClientNotFound"
import { ConsumerCatalogContextProvider } from "./ConsumerCatalogContext"
import { ConsumerContextProvider } from "./ConsumerContext"
import { GetClientResponse } from "./GetClientResponse"
import { setBrandingCssVariables } from "./setBrandingCssVariables"

export const ClientContext = React.createContext<ClientInstance | null>(null)

export const useClientRoot = () => useClient().identifier

export const useClientContext = () => useContext(ClientContext)

export const useClient = () => {
	const context = useClientContext()
	if (!context) {
		throw new Error("Client context does not exist")
	}
	return context
}

type Props = {
	element: React.ReactNode
}

type ServiceWorkerRegistrationResult = {
	status: "success" | "already-registered" | "not-available" | "error"
}

type NewPushSubscription = {
	clientIdentifier: string
	loggedInUserId: string
	pushNotificationEndpoint: object
	consumerRef?: string
}

const logger = getLogger("ClientAndUserProvider")
const broadcastChannel = new BroadcastChannel("sw-messages")

export const ClientAndUserProvider = ({ element }: Props) => {
	const auth = useAuth()
	const { clientName } = useParams()

	remoteLogPusher.clientIdentifier = clientName ?? null

	const throwAsync = useThrowAsync()
	const [client, setClient] = useState<ClientInstance | "not-found" | null>(null)

	const publicVapidKey = useRef<string | null>(null)
	const swRegRef = useRef<ServiceWorkerRegistration | null>(null)

	useEffect(() => {
		const id = EventQueue.addEventListener("reload-client-data", (res) => {
			if (clientName && fetchClient) {
				fetchClient(clientName, true)
			}
		})

		return () => {
			EventQueue.removeEventListener(id)
		}
	}, [])

	const urlBase64ToUint8Array = (base64String: string) => {
		const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
		const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

		const rawData = atob(base64)
		const outputArray = new Uint8Array(rawData.length)

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i)
		}

		return outputArray
	}

	const fetchClient = useCallback(
		async (clientName: string, refreshCache: boolean) => {
			let clientInstance: ClientInstance | null = await API.getWithRetries<GetClientResponse>(
				`/order-ui/clients-v1/${clientName}`,
				refreshCache,
			)
				.then((clientResp) => {
					return clientInstanceOf(clientResp)
				})
				.catch((error: ServerError<unknown>) => {
					if (error.status === 404) {
						setClient("not-found")
						plausible("client-not-found", { props: { clientName: clientName } })
					} else {
						throwAsync(new Error(`Unable to fetch client data for: ${clientName}`, { cause: error }))
					}
					return null
				})

			if (!clientInstance) {
				return
			}

			if (clientInstance.features.orderUiRenderTransportZones) {
				let zones: PolygonTransportZone[] = await API.getWithRetries<TransportZoneApiResponse>(
					`/order-ui/transport-zones-v1/${clientInstance.identifier}`,
					refreshCache,
					{},
					10,
				)
					.then((x) => x.zones)
					.catch((err) => {
						logger.error(`Unable to fetch transport zones for client: ${clientName}, this is recoverable`, err)
						return []
					})
				clientInstance.setTransportZones(zones)
			}

			await auth.init(clientInstance.identifier).catch((error) => {
				throwAsync(new Error(`Unable to initialize auth for client: ${clientName}`, { cause: error }))
			})

			setClient(clientInstance)
			setBrandingCssVariables(clientInstance.branding)
		},
		[throwAsync],
	)

	useEffect(() => {
		if (clientName && fetchClient) {
			fetchClient(clientName, false)
		} else {
			setClient("not-found")
		}
	}, [fetchClient, clientName])

	useEffect(() => {
		if (auth.IsLoggedIn && client instanceof ClientInstance && client.features.notificationsEnabled) {
			initServiceWorkerStuff(client)
		}
	}, [client, auth.IsLoggedIn])

	function getSelectedConsumerId(clientIdentifier: string) {
		try {
			const item = localStorage.getItem(`${clientIdentifier}.selected-consumer-id`)
			const parsed = JSON.parse(item || '""')

			if (!parsed) {
				return ""
			}

			return parsed
		} catch (e) {
			return ""
		}
	}

	function initServiceWorkerStuff(client: ClientInstance) {
		const loggedInUserId = auth.Me?.userId
		fetchPublicVapidKey().then((key) => {
			publicVapidKey.current = key

			if (key && loggedInUserId) {
				registerServiceWorker(key, client.identifier, loggedInUserId).then(
					(res) => {
						if (res.status === "success") {
							document.addEventListener(
								"click",
								() => {
									requestNotificationPermission(key, client.identifier, loggedInUserId)
								},
								{ once: true },
							)
						}
					},
					(err) => {
						console.error("service worker register callback error", err)
					},
				)
			}
		})
	}

	async function fetchPublicVapidKey() {
		return API.get<string>("/order-ui/notifications-v1/public-vapid-key")
			.then((resp) => {
				return resp
			})
			.catch(() => {
				return null
			})
	}

	async function saveSubscription(clientIdentifier: string, loggedInUserId: string, subscription: PushSubscription) {
		if (!auth.IsLoggedIn) {
			return Promise.resolve()
		}

		const body: NewPushSubscription = {
			clientIdentifier: clientIdentifier,
			loggedInUserId: loggedInUserId,
			pushNotificationEndpoint: subscription,
		}

		const consumerId = getSelectedConsumerId(clientIdentifier)
		if (consumerId && auth.IsLoggedInConsumer) {
			body.consumerRef = consumerId
		}

		return API.post<void, NewPushSubscription>("/order-ui/notifications-v1/add-push-subscribe", body)
			.then(() => {})
			.catch(() => {
				navigator.serviceWorker.getRegistration(`/service-worker/${clientIdentifier}/`).then(
					(registration) => {
						if (registration) {
							registration.pushManager.getSubscription().then((x) => {
								if (x) {
									x.unsubscribe()
								}
							})
						}
					},
					(err) => {
						console.error("service worker getRegistration on save error", err)
					},
				)
			})
	}

	async function registerServiceWorker(
		publicVapidKey: string,
		clientIdentifier: string,
		loggedInUserId: string,
	): Promise<ServiceWorkerRegistrationResult> {
		if ("serviceWorker" in navigator) {
			return navigator.serviceWorker
				.register(`/service-worker/service-worker.js`, { scope: `/service-worker/${clientIdentifier}/` })
				.then(
					async (swReg) => {
						broadcastChannel.addEventListener(
							"message",
							async (event) => {
								if (event.data?.type === "service-worker-activate") {
									if ("Notification" in window && Notification.permission === "granted") {
										swReg.pushManager
											.subscribe({
												userVisibleOnly: true,
												applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
											})
											.then(
												async (subscription) => {
													await saveSubscription(clientIdentifier, loggedInUserId, subscription)
												},
												(err) => {
													console.error("service worker activate subscribe error", err)
												},
											)
									}
								}
							},
							{ once: true },
						)

						swRegRef.current = swReg
						if ("Notification" in window && Notification.permission === "granted") {
							return swRegRef.current.pushManager.getSubscription().then(
								async (sub) => {
									if (sub) {
										return { status: "already-registered" }
									}
									return swReg.pushManager
										.subscribe({
											userVisibleOnly: true,
											applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
										})
										.then(
											(subscription) => {
												return saveSubscription(
													clientIdentifier,
													loggedInUserId,
													subscription,
												).then(
													() => {
														return { status: "success" }
													},
													() => {
														return { status: "not-available" }
													},
												)
											},
											(err) => {
												console.error("service worker register callback subscribe error", err)
												return { status: "error" }
											},
										)
								},
								() => {
									return { status: "not-available" }
								},
							)
						}
						return { status: "success" }
					},
					(err) => {
						console.error("service worker register error", err)
						return { status: "error" }
					},
				)
		} else {
			return { status: "not-available" }
		}
	}
	function requestNotificationPermission(publicVapidKey: string, clientIdentifier: string, loggedInUserId: string) {
		if (!("serviceWorker" in navigator)) {
			return
		}

		if (!("Notification" in window)) {
			// Check if the browser supports notifications
		} else if (Notification.permission === "granted") {
			// Check whether notification permissions have already been granted;
			// if so, create a notification
			// const notification = new Notification("Hi there 1!")
			// …
		} else if (Notification.permission !== "denied") {
			// We need to ask the user for permission
			Notification.requestPermission().then(async (permission) => {
				// If the user accepts, let's create a notification
				const swReg = swRegRef.current
				if (permission === "granted" && swReg) {
					// const notification = new Notification("Hi there 2!")
					// …
					swReg.pushManager.getSubscription().then(
						async (sub) => {
							if (sub) {
								return
							}
							swReg.pushManager
								.subscribe({
									userVisibleOnly: true,
									applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
								})
								.then(
									async (subscription) => {
										await saveSubscription(clientIdentifier, loggedInUserId, subscription)
									},
									(err) => {
										console.error("service worker request notification permission subscribe error", err)
									},
								)
						},
						() => {},
					)
				}
			})
		}
	}

	if (!client) {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	if (client === "not-found") {
		return <ClientNotFound clientName={clientName} />
	}

	return (
		<ClientContext.Provider value={client}>
			<ConsumerContextProvider>
				<ConsumerCatalogContextProvider>{element}</ConsumerCatalogContextProvider>
			</ConsumerContextProvider>
		</ClientContext.Provider>
	)
}
