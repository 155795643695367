import { FC, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { MeResponseAccountType } from "../../Auth/Auth.types"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ConsumerCatalogInstance, ConsumerProjectMode, useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { ArrowLeftIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { CustomerPortalOrders, GetOrder, GetOrdersResponse } from "../CustomerPortalOrders/CustomerPortalOrders"
import { CustomerPortalOrdersDocuments } from "../CustomerPortalOrdersDocuments/CustomerPortalOrdersDocuments"
import { CustomerPortalProjectDetails } from "../CustomerPortalProjectDetails/CustomerPortalProjectDetails"
import { CustomerPortalProjectsList } from "../CustomerPortalProjectsList/CustomerPortalProjectsList"
import { CustomerPortalProjectsPlottedMap } from "../CustomerPortalProjectsPlottedMap/CustomerPortalProjectsPlottedMap"
import style from "./CustomerPortalProjectsManager.module.css"

type ProjectId = string & { isProjectId: true }

export type GetProjectDefinitionResponse = {
	projects: GetProject[]
}

export type NewProjectRequest = {
	project: NewProject
}

export type NewProject = {
	street: string
	city: string
	zipCode?: string
	coordinates?: Coordinates
	marking?: string
	contactPersonIds: string[]
	status: ProjectStatus
}

export type UpdateProjectRequest = {
	project: UpdateProject
}

export type UpdateProject = Partial<NewProject>

export type GetProject = {
	id: ProjectId
	address: Address
	marking?: string
	contactPersons: GetContactPerson[]
	status: ProjectStatus
}

export type Address = {
	street: string
	city: string
	zipCode?: string
	coordinates?: Coordinates
}

export type GetContactPerson = {
	id: string
	name: string
	phone: string
}

export type Coordinates = {
	lat: number
	lng: number
}

export enum ProjectStatus {
	Active = "Active",
	Inactive = "Inactive",
}

type CustomerPortalProjectsManagerProps = {
	consumerId?: string
	consumerCatalog?: ConsumerCatalogInstance
}
export const CustomerPortalProjectsManager: FC<CustomerPortalProjectsManagerProps> = (
	props: CustomerPortalProjectsManagerProps,
) => {
	const auth = useAuth()
	const client = useClient()
	const consumerCatalogUse = useConsumerCatalog()

	const [projects, setProjects] = useState<GetProject[] | null>(null)
	const [hoveredProject, setHoveredProject] = useState<GetProject | null>(null)
	const [selectedProject, setSelectedProject] = useState<GetProject | null>(null)
	const [consumerId, setConsumerId] = useState<string | null>(null)

	const [allowSelectedProjectEditAndDelete, setAllowSelectedProjectEditAndDelete] = useState(false)

	const [selectedProjectOrders, setSelectedProjectOrders] = useState<GetOrder[] | null>(null)

	const [dataLoading, setDataLoading] = useState(true)

	const [localStorageConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const [queryParams, setQueryParams] = useSearchParams()

	useEffect(() => {
		if (localStorageConsumerId && auth.Me?.type === MeResponseAccountType.Consumer) {
			init(localStorageConsumerId)
		} else if (props.consumerId && auth.Me?.type === MeResponseAccountType.Client) {
			init(props.consumerId)
		}
	}, [localStorageConsumerId, props.consumerId])

	useEffect(() => {
		if (dataLoading) {
			return
		}
		if (queryParams.has("projectId")) {
			const project = projects?.find((x) => x.id === queryParams.get("projectId"))
			if (project) {
				if (project?.id !== selectedProject?.id) {
					selectProject(project)
				}
			} else {
				unSelectProject()
			}
		} else {
			unSelectProject()
		}
	}, [queryParams, dataLoading])

	useEffect(() => {}, [selectedProject])

	useEffect(() => {
		if (selectedProjectOrders) {
			setAllowSelectedProjectEditAndDelete(selectedProjectOrders?.length === 0)
		}
	}, [selectedProjectOrders])

	function selectProject(project: GetProject) {
		if (consumerId) {
			setSelectedProjectOrders(null)
			API.getWithRetries<GetOrdersResponse>(
				`/customer-portal/project-definitions-v1/${client.identifier}/${consumerId}/${project.id}/orders`,
			)
				.then((res) => {
					setSelectedProjectOrders(res.orders)
				})
				.catch(() => {})
		}
		setSelectedProject(project)
		queryParams.set("projectId", project.id)
		setQueryParams(queryParams)
	}

	function unSelectProject() {
		setSelectedProject(null)
		if (queryParams.has("projectId")) {
			queryParams.delete("projectId")
			setQueryParams(queryParams)
		}
	}

	function init(consumerId: string) {
		setConsumerId(consumerId)
		setDataLoading(true)
		API.getWithRetries<GetProjectDefinitionResponse>(
			`/customer-portal/project-definitions-v1/${client.identifier}/${consumerId}`,
			true,
		)
			.then((res) => {
				setProjects(res.projects)
				setDataLoading(false)
			})
			.catch(() => {
				setDataLoading(false)
			})
	}

	function noSelectedProjectView(): JSX.Element | null {
		if (!consumerId) {
			return null
		}

		return (
			<div className={style.noSelectedProjectViewWrapper}>
				<CustomerPortalProjectsList
					consumerCatalog={props.consumerCatalog || null}
					consumerId={consumerId}
					projects={projects}
					selectedProject={selectedProject}
					selectProject={(x) => {
						selectProject(x)
					}}
					onMouseEnter={(e) => {
						setHoveredProject(e)
					}}
					onMouseLeave={() => {
						setHoveredProject(null)
					}}
					onNewProject={(newProject) => {
						setProjects((x) => (x ? [...x, newProject] : [newProject]))
					}}
				/>
				<CustomerPortalProjectsPlottedMap
					consumerCatalog={props.consumerCatalog || consumerCatalogUse}
					projects={projects}
					hoveredProject={hoveredProject}
					onProjectSelect={(x) => {
						selectProject(x)
					}}
					selectedProject={selectedProject}
				/>
			</div>
		)
	}

	function selectedProjectView(): JSX.Element | null {
		if (!selectedProject || !consumerId) {
			return null
		}

		let simpleAddress =
			(props.consumerCatalog || consumerCatalogUse).features.projectMode === ConsumerProjectMode.SimpleAddress

		return (
			<>
				<div
					className={style.backArrowWrapper}
					onClick={() => {
						unSelectProject()
					}}>
					<ArrowLeftIcon size={28} className={style.backArrow} />
					{simpleAddress ? "Se alla adresser" : "Se alla projekt"}
				</div>
				<div className={style.selectedProjectViewWrapper}>
					<CustomerPortalProjectDetails
						consumerCatalog={props.consumerCatalog || null}
						allowEditAndDelete={allowSelectedProjectEditAndDelete}
						consumerId={consumerId}
						project={selectedProject}
						orderAmount={selectedProjectOrders?.length || 0}
						onUpdate={(updatedProject) => {
							setProjects((projects) => {
								if (projects) {
									const index = projects.findIndex((x) => x.id === updatedProject.id)
									if (index > -1) {
										projects[index] = updatedProject
									}
								}
								return projects
							})
						}}
						onDelete={(deletedId) => {
							setProjects((projects) => {
								if (projects) {
									const index = projects.findIndex((x) => x.id === deletedId)
									if (index > -1) {
										projects.splice(index, 1)
									}
								}
								return projects
							})
							unSelectProject()
						}}
						onMarkingUpdate={(updatedProject) => {
							setProjects((projects) => {
								if (projects) {
									const index = projects.findIndex((x) => x.id === updatedProject.id)
									if (index > -1) {
										projects[index] = updatedProject
									}
									return [...projects]
								}
								return null
							})
							const orders = selectedProjectOrders
							if (orders) {
								setSelectedProjectOrders(null)
								setTimeout(() => {
									if (orders) {
										orders.forEach((projectOrder) => {
											projectOrder.marking = updatedProject.marking || ""
										})
									}
									setSelectedProjectOrders([...orders])
								}, 50)
							}
						}}
					/>
					<span></span>
					<CustomerPortalOrdersDocuments
						infoText={
							simpleAddress
								? "Här visas alla dokument för alla bokningar som har samma gata, stad, och postnummer som den aktuella adressen"
								: "Här visas alla dokument för alla bokningar som har samma märkning, gata, stad, och postnummer som  det aktuella projektet"
						}
						orders={selectedProjectOrders}
						consumerId={consumerId}
					/>
				</div>
				{selectedProjectOrders ? (
					<div style={{ marginTop: "40px", paddingBottom: "60px" }}>
						<CustomerPortalOrders consumerId={consumerId} orders={selectedProjectOrders} />
					</div>
				) : null}
			</>
		)
	}

	if (dataLoading) {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	return (
		<div className={style.wrapper}>
			{selectedProject ? null : (
				<div className={style.header}>
					{(props.consumerCatalog || consumerCatalogUse).features.projectMode === ConsumerProjectMode.FullProject
						? "Projekt"
						: "Adresser"}
				</div>
			)}
			{selectedProject ? selectedProjectView() : noSelectedProjectView()}
		</div>
	)
}
