import { useJsApiLoader } from "@react-google-maps/api"
import { useClient } from "Client/ClientAndUserProvider"
import { exhaustive } from "exhaustive"
import { cloneDeep, every, isArray, isEqual, some } from "lodash"
import { GetVerifiedDiscountCode } from "Orders/Components/OrderConfirmCheckout/DiscountCodeVerifier"
import {
	OrderItem,
	OrderItemDateType,
	OrderItemProduct,
	OrderItemProductType,
	OrderItems,
	OrderItemType,
	Project,
} from "Orders/order-data-model"
import { OrderCompleted } from "Orders/OrderCompleted/OrderCompleted"
import { libraries } from "Orders/ProjectInputModule/ProjectInputModule"
import React, { FC, ForwardedRef, useEffect, useMemo, useRef, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { NavigateOptions, Route, Routes, URLSearchParamsInit, useSearchParams } from "react-router-dom"
import { cls } from "Shared/cls"
import { lets } from "Shared/lets"
import { WidgetRendererV1 } from "Shared/Widget/Widgets"
import { v4 } from "uuid"
import { z } from "zod"
import { useAuth } from "../../Auth/AuthContext"
import { ProductCategoryInstance } from "../../Client/ClientInstance"
import { useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { SelectedConsumerUserSchema } from "../../Client/ConsumerContext"
import { ProductSelectionMode } from "../../Client/FeatureTypes"
import { ProductDefinition } from "../../Client/ProductDefinitionsByCategories"
import {
	GetContactPerson,
	GetProject,
} from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { getLogger } from "../../Logging/getLogger"
import { NavigatorNavigate } from "../../Navigator/NavigatorNavigate"
import { CalculationProduct } from "../../QuantityCalculator/QuantityCalculator"
import { EventQueue } from "../../Shared/eventQueue"
import { indexWithinBounds } from "../../Shared/indexWithinBounds"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { isModalOpen, removeModalOpenClass } from "../Components/ModulePopup/ModulePopup"
import { OrderConfirmCheckout } from "../Components/OrderConfirmCheckout/OrderConfirmCheckout"
import { DateSelectModule } from "../DateSelectModule/DateSelectModule"
import {
	ProductInformationAndSelectionModule,
	ProductSelectionReturnValue,
} from "../ProductInformationAndSelectionModule/ProductInformationAndSelectionModule"
import { TimeSelectModule } from "../TimeSelectModule/TimeSelectModule"
import { FullPageProductSelection } from "./FullPageProductSelection"
import {
	addProductToOrderItem,
	calculateTotalPrices,
	getOrderItemPriceArticles,
	getZoneIdFromPoint,
	readOrderItemsFromLocalStorage,
	setArticlesOnOrderItem,
} from "./Logic"
import style from "./OrderContainer.module.css"
import {
	ProductDefinitionWithPrice,
	resolveProductDefinitionsWithPriceData,
} from "./resolveProductDefinitionsWithPriceData"
import { BasketSection } from "./SubComponents/BasketSection"
import { CategorySelection } from "./SubComponents/CategorySelection"
import { ClientConsumerSelection } from "./SubComponents/ClientConsumerSelection"
import { ProductSelection } from "./SubComponents/ProductSelection"
import { ProjectSelection, ProjectSelectionRefProps } from "./SubComponents/ProjectSelection"
import { ServiceSelection } from "./SubComponents/ServiceSelection"

const logger = getLogger("OrderContainer")

type Props = {}

let previousQueryParams: URLSearchParams

/**
 * oi_e_p -> order item edit project
 * oi_e_d -> order item edit date
 * oi_e_t -> order item edit time
 */
type OpenModalTypes = "new_proj" | "old_proj" | "oi_e_p" | "oi_e_d" | "oi_e_t"
export type OrderItemTotalPrices = Record<
	string,
	{ price: number; tax: number; amountOfArticles: number; discount: number; taxDiscount: number }
>

type SetURLSearchParams = (
	nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
	navigateOpts?: NavigateOptions,
) => void
export function removeModalOpen(queryParams: URLSearchParams, setQueryParams: SetURLSearchParams): void {
	let deleted: boolean = false
	if (queryParams.has("m_op")) {
		queryParams.delete("m_op")
		deleted = true
	}

	if (queryParams.has("oi_idx")) {
		queryParams.delete("oi_idx")
		deleted = true
	}

	if (deleted) {
		setQueryParams(queryParams)
	}

	removeModalOpenClass()
}

type ProductInfoAndSelection = {
	productId: string
	descriptionOpen: boolean
}

export const OrderContainer: FC<Props> = () => {
	const auth = useAuth()
	const client = useClient()
	const consumerCatalog = useConsumerCatalog()
	const isMobileSize = useMediaQuery({ query: `(max-width: 1100px)` })

	const [orderItemTotalPrices, setOrderItemTotalPrices] = useState<OrderItemTotalPrices>({})
	const [showEditOrderItemProject, setShowEditOrderItemProject] = useState(false)
	const [showDateSelectModule, setShowDateSelectModule] = useState(false)
	const [showTimeSelectModule, setShowTimeSelectModule] = useState(false)

	const [selectedOrderItem, setSelectedOrderItem] = useState<number | null>(null)
	const [orderItems, _setOrderItems] = useState<OrderItem[]>(() =>
		readOrderItemsFromLocalStorage(client, consumerCatalog, null),
	)
	const [queryParams, setQueryParams] = useSearchParams()
	let queriedService = queryParams.get("service")

	const [selectedCategoryName, setSelectedCategoryName] = useState<string | null>(() => {
		if (client.categoryKeys.length === 0) {
			return null
		} else if (queryParams.get("category") !== null && client.categories[queryParams.get("category")!]) {
			return queryParams.get("category")
		}

		const categoryKey = client.categoryKeys[0]

		if (!categoryKey) {
			return null
		}

		return client.categories[categoryKey]?.name || null
	})
	let selectedCategory = client.findCategoryByName(selectedCategoryName)

	const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null)
	const [productInfoAndSelection, setProductInfoAndSelection] = useState<ProductInfoAndSelection | null>(null)

	const availableProducts: ProductDefinitionWithPrice[] = useMemo(() => {
		if (selectedCategoryName && selectedCategory) {
			const categoryProducts: ProductDefinition[] = Object.values(selectedCategory.products)
			categoryProducts.sort((a, b) => a.order - b.order)
			return (
				resolveProductDefinitionsWithPriceData(
					categoryProducts,
					selectedServiceId,
					consumerCatalog,
					client,
					auth,
				) ?? []
			)
		} else {
			return []
		}
	}, [selectedCategoryName, selectedCategory, selectedServiceId, consumerCatalog, client])

	const [selectedProject, setSelectedProject] = useState<Project | GetProject | null>(null)

	const [currentDiscountCode, setCurrentDiscountCode] = useState<GetVerifiedDiscountCode | null>(null)

	const savedOrderItemsChecked = useRef(false)

	const projectSelectionComponentRef: ForwardedRef<ProjectSelectionRefProps> = useRef(null)

	const [incrementorKeySuffix, setIncrementorKeySuffix] = useState<number>(0)

	const [clientSelectedConsumerId] = useBrandedLocalStorage("client-selected-consumer-id", z.string(), {
		defaultValue: "",
	})
	const [selectedUserLocalstorage] = useBrandedLocalStorage(
		"client-selected-consumer-user",
		z.string().or(SelectedConsumerUserSchema),
		{
			defaultValue: "",
		},
	)

	const [showRegularBasket, setShowRegularBasket] = useBrandedLocalStorage("show-regular-basket", z.boolean(), {
		defaultValue: false,
	})

	const { isLoaded: googleMapsLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		libraries: libraries,
		language: "sv",
	})

	const openProduct =
		productInfoAndSelection && selectedCategory ? selectedCategory.products[productInfoAndSelection.productId] : null
	let showFullPageProductConfig = false

	if (client.features.orderUiProductSelectionMode === ProductSelectionMode.FullPage && selectedCategory) {
		if (
			selectedCategory.type === "GoodsCategory" ||
			(selectedCategory.type === "WasteCategory" &&
				!!selectedCategory.productSelectionConfig &&
				selectedCategory.productSelectionConfig.steps.length > 0)
		) {
			showFullPageProductConfig = true
		}
	}

	const closeAllModals = (closeMobileBasket: boolean = true) => {
		projectSelectionComponentRef.current?.setShowProjectSelectModule(false)

		projectSelectionComponentRef.current?.setEditProject(null)

		// Don't clear this if we are in full page product config view
		// Clearing this closes the view, which we do explicitly in that case, and not implicitly like otherwise
		if (!(openProduct && showFullPageProductConfig)) {
			setProductInfoAndSelection(null)
		}

		setShowDateSelectModule(false)

		setShowTimeSelectModule(false)

		if (closeMobileBasket) {
			EventQueue.dispatchEvent("hideMobileBasket", {})
		}
	}

	/**
	 * A wrapper that will populate Articles before updating orderItems in the state
	 * FIXME: Optimize this usage, this is done a shit load of times now :scream:
	 * @param orderItems
	 */
	const setOrderItems = (orderItems: OrderItem[]) => {
		const newItems = cloneDeep(orderItems).map((item) => {
			return setArticlesOnOrderItem(
				client,
				consumerCatalog,
				item,
				lets(currentDiscountCode, (it) => it.templateId),
			)
		})
		let calculateTotalPrices1 = calculateTotalPrices(newItems, client)
		setOrderItemTotalPrices(calculateTotalPrices1)
		_setOrderItems(newItems)
	}

	/**
	 * Re-set articles on order items if the consumerCatalog or any other relevant parameter is updated.
	 */
	useEffect(() => {
		setOrderItems(orderItems)
	}, [client, consumerCatalog, currentDiscountCode])

	useEffect(() => {
		if (orderItems.length === 0) {
			setShowRegularBasket(false)
		}

		const eventListenerIds: string[] = []
		eventListenerIds.push(
			EventQueue.addEventListener("closeAllModals", () => {
				closeAllModals()
			}),
			EventQueue.addEventListener("quantityCalculatorBasket", (calcProducts: CalculationProduct[]) => {
				const vals: ProductSelectionReturnValue[] = []
				calcProducts.forEach((calcProduct) => {
					if (calcProduct.type === "Goods" && calcProduct.selectedPackagingMethod) {
						vals.push({
							productId: calcProduct.productId,
							name: calcProduct.productName,
							category: calcProduct.productCategoryName,
							packagingMethods: {
								[calcProduct.selectedPackagingMethod.packagingMethodId]: calcProduct.selectedAmount,
							},
						})
					}
				})

				addProduct(vals)
			}),
		)

		if (client.categoryKeys.length === 0) {
			return
		}

		let category: string
		let service: string | undefined
		if (!queryParams.get("category") && !queryParams.get("service") && selectedCategoryName && selectedServiceId) {
			category = selectedCategoryName
			service = selectedServiceId
		} else {
			if (queryParams.get("category") && client.categories[queryParams.get("category")!]) {
				category = queryParams.get("category")!
			} else {
				category = client.categoryKeys[0] || ""
			}

			if (category) {
				let queriedCategory = client.categories[category]
				if (queriedCategory?.type === "WasteCategory") {
					if (queryParams.get("service") && queriedCategory.services[queryParams.get("service")!]) {
						service = queryParams.get("service")!
					} else if (queriedCategory.serviceIds.length === 1) {
						service = queriedCategory.serviceIds[0]
					}
				}
			}
		}

		if (category) {
			setQueryParamsUrl(category, service)
		}

		return () => {
			eventListenerIds.forEach((id) => EventQueue.removeEventListener(id))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // Set [] as deps to not call on rerender

	useEffect(() => {
		if (googleMapsLoaded && isArray(orderItems) && !savedOrderItemsChecked.current) {
			savedOrderItemsChecked.current = true

			const items = cloneDeep(orderItems)

			items.forEach((item) => {
				if (item.project.coordinates) {
					item.zoneId = getZoneIdFromPoint(client, item.project.coordinates)
				}
			})

			setOrderItems(items)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderItems, googleMapsLoaded])

	useEffect(() => {
		if (queryParams.get("category")) {
			setSelectedCategoryName(queryParams.get("category")!)
		}

		if (queriedService) {
			setSelectedServiceId(queriedService)
		} else {
			setSelectedServiceId(null)
		}

		let productQueryId = queryParams.get("product")
		if (!productQueryId && productInfoAndSelection) {
			setProductInfoAndSelection(null)
			removeModalOpen(queryParams, setQueryParams)
		} else if (selectedCategoryName && productQueryId && !queryParams.has("m_op") && !productInfoAndSelection) {
			const product = availableProducts.find((it) => it.id === productQueryId)
			if (product != null) {
				setProductInfoAndSelection({
					productId: product.id,
					descriptionOpen: false,
				})
			}
		}

		if (previousQueryParams && previousQueryParams.has("m_op") && queryParams && !queryParams.has("m_op")) {
			const typeOfClosedModal = previousQueryParams.get("m_op") as OpenModalTypes

			// Don't close mobile basket if the modal being closed is a modal that was opened in context of said basket
			closeAllModals(
				!(typeOfClosedModal === "oi_e_d" || typeOfClosedModal === "oi_e_t" || typeOfClosedModal === "oi_e_p"),
			)
			removeModalOpen(queryParams, setQueryParams)
		}

		if (queryParams && queryParams.has("m_op") && !productQueryId) {
			const orderItemIndexRaw = queryParams.has("oi_idx") ? parseInt(queryParams.get("oi_idx")!, 10) : undefined
			const orderItemIndex = indexWithinBounds(orderItemIndexRaw, orderItems)

			switch (queryParams.get("m_op") as OpenModalTypes) {
				case "new_proj":
					projectSelectionComponentRef.current?.setEditProject({ isNew: true, project: {} })
					break
				case "old_proj":
					projectSelectionComponentRef.current?.setShowProjectSelectModule(true)
					break
				case "oi_e_p":
					if (orderItemIndex != null) {
						setSelectedOrderItem(orderItemIndex)
						setSelectedProject(orderItems[orderItemIndex]?.project || null)
						projectSelectionComponentRef.current?.setShowProjectSelectModule(true)
						setShowEditOrderItemProject(true)
					}
					break
				case "oi_e_d":
					if (orderItemIndex != null) {
						setSelectedOrderItem(orderItemIndex)
						setSelectedProject(orderItems[orderItemIndex]?.project || null)
						setShowDateSelectModule(true)
					}
					break
				case "oi_e_t":
					if (orderItemIndex != null) {
						const orderItem = orderItems[orderItemIndex]
						const categoryName = orderItem?.category

						if (orderItem && categoryName) {
							let categoryInstance = client.categories[categoryName]

							if (categoryInstance) {
								let timeslots: string[] = []

								if (categoryInstance.type === "WasteCategory" && orderItem.serviceId) {
									timeslots = categoryInstance.services[orderItem.serviceId]?.timeSlots || []
								} else if (categoryInstance.type === "GoodsCategory") {
									const packagingMethodId = orderItem.products.find((x) => x.packagingMethod)
										?.packagingMethod?.id

									if (packagingMethodId) {
										timeslots = client.possiblePackagingMethods[packagingMethodId]?.timeSlotIds || []
									}
								}

								if (timeslots.length > 1) {
									setSelectedOrderItem(orderItemIndex)
									setSelectedProject(orderItem.project)
									setShowTimeSelectModule(true)
								}
							}
						}
					}
					break
			}
		}

		previousQueryParams = new URLSearchParams(queryParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams])

	useEffect(() => {
		if (selectedOrderItem !== null) {
			if (orderItems.length === 0) {
				setSelectedOrderItem(null)
				if (
					selectedCategory?.type === "WasteCategory" &&
					selectedCategoryName &&
					selectedCategory?.serviceIds.length > 0
				) {
					setSelectedServiceId(selectedCategory?.serviceIds[0] || null)
					setQueryParamsUrl(selectedCategoryName, selectedCategory.serviceIds[0])
				}
			} else {
				let index = selectedOrderItem >= orderItems.length ? orderItems.length - 1 : selectedOrderItem
				lets(orderItems[index], (it) => {
					setSelectedCategoryName(it.category === "" ? null : it.category)
					setSelectedServiceId(it.serviceId || null)
					setSelectedProject(it.project)
					setQueryParamsUrl(it.category, it.serviceId)
				})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOrderItem])

	useEffect(() => {
		if (orderItems.length === 0) {
			setSelectedOrderItem(null)
			if (queryParams.get("category") && client.categories[queryParams.get("category")!]) {
				setSelectedCategoryName(queryParams.get("category")!)
			}
			if (
				selectedCategory?.type === "WasteCategory" &&
				selectedCategoryName &&
				selectedCategory?.serviceIds.length === 1
			) {
				setSelectedServiceId(selectedCategory?.serviceIds[0] || null)
				setQueryParamsUrl(selectedCategoryName, selectedCategory.serviceIds[0])
			}
		} else if (selectedOrderItem !== null) {
			if (selectedOrderItem >= orderItems.length) {
				const newSelected = selectedOrderItem - 1
				setSelectedOrderItem(newSelected < 0 ? null : newSelected)
			}
		}

		const orderItemsWithoutArticles: OrderItems = orderItems.map((x): OrderItemType => {
			const { articles, products, ...rest } = x
			const orderItemProducts = products as OrderItemProduct[]

			const orderItemProductsWithoutArticles: OrderItemProductType[] = orderItemProducts.map((x) => {
				const { articles, ...rest } = x
				return { ...rest }
			})

			return {
				products: orderItemProductsWithoutArticles,
				...rest,
			}
		})

		localStorage.setItem(`${client.identifier}.order-items`, JSON.stringify(orderItemsWithoutArticles))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderItems])

	if (client.categoryKeys.length <= 0) {
		return (
			<div className={style.noProductsWrapper}>
				<div className={style.noProductsMessage}>
					{client.clientInfo.clientName} har tyvärr inga produkter upplagda än, men det kommer snart!
				</div>
			</div>
		)
	}

	function setQueryParamsUrl(
		category: string,
		service?: string,
		product?: string,
		openModal?: OpenModalTypes,
		removeModal?: boolean,
		orderItemIndex?: number,
	) {
		queryParams.set("category", category)

		if (service != null) {
			queryParams.set("service", service)
		} else {
			queryParams.delete("service")
		}

		if (product !== undefined) {
			queryParams.set("product", product)
		}

		if (openModal) {
			queryParams.set("m_op", openModal)
		}

		if (removeModal && queryParams.has("m_op")) {
			queryParams.delete("m_op")
		}

		if (orderItemIndex !== undefined) {
			queryParams.set("oi_idx", orderItemIndex.toString())
		}
		setQueryParams(queryParams)
	}

	const addProduct = (
		productSelectionReturnValues: ProductSelectionReturnValue[],
		repeat: number = 1,
		project?: Project | GetProject,
	) => {
		let projectToUse: Project | GetProject | null = project || selectedProject
		if (projectToUse === null && selectedOrderItem === null) {
			const productId = !!openProduct && showFullPageProductConfig ? openProduct.id : ""
			setQueryParamsUrl(selectedCategoryName || "", selectedServiceId || "", productId, "old_proj")
			projectSelectionComponentRef.current?.showProjectSelectionForAddingProduct(productSelectionReturnValues)
			return
		}

		if (!projectToUse) {
			throw new Error("Tried to add product but no selected project or order item was set!")
		}
		const newOrderItems: OrderItem[] = []
		productSelectionReturnValues.forEach((productSelectionReturnValue) => {
			newOrderItems.push(
				...addProductToOrderItem(
					client,
					consumerCatalog,
					cloneDeep(orderItems),
					productSelectionReturnValue,
					productSelectionReturnValue.amount !== undefined ? productSelectionReturnValue.amount : repeat,
					convertGetProjectToInternalProject(projectToUse!),
					selectedOrderItem,
					selectedServiceId,
					setSelectedOrderItem,
					lets(currentDiscountCode, (it) => it.templateId),
				),
			)
		})

		setOrderItems(newOrderItems)
		setShowRegularBasket(true)
		if (orderItems.length !== newOrderItems.length) {
			setSelectedOrderItem(newOrderItems.length - 1)
		}
	}

	function convertGetProjectToInternalProject(project: GetProject | Project): Project {
		if ("id" in project) {
			return {
				street: project.address.street,
				city: project.address.city,
				zipcode: project.address.zipCode,
				coordinates: project.address.coordinates,
				marking: project.marking,
				contactPersons: project.contactPersons.map((x: GetContactPerson) => ({
					id: x.id,
					name: x.name,
					phone: x.phone,
				})),
			}
		}
		return project
	}

	const removeOrderItem = (index: number) => {
		const newOrderItems = orderItems.filter((value, i) => i !== index)
		setOrderItems(newOrderItems)
		setIncrementorKeySuffix(incrementorKeySuffix + 1)
	}

	const setDate = (date: OrderItemDateType) => {
		if (selectedOrderItem === null) {
			return
		}

		const orderItem = orderItems[selectedOrderItem]

		if (orderItem) {
			orderItem.date = date
			orderItem.id = v4()

			// re-calculate order item articles when setting date, since date slots now have prices
			orderItem.articles = getOrderItemPriceArticles(
				client,
				consumerCatalog,
				orderItem,
				lets(currentDiscountCode, (it) => it.templateId),
			)
			orderItems[selectedOrderItem] = orderItem
			setOrderItems([...orderItems])
		}
	}

	const setTime = (timeslotId: string, timeName: string, specificTime: boolean) => {
		if (selectedOrderItem === null) {
			return
		}

		const orderItem = orderItems[selectedOrderItem]

		if (orderItem) {
			if (specificTime) {
				orderItem.time = { timeslotId, timeValue: timeName, specificTime }
			} else {
				orderItem.time = { timeslotId, timeName, specificTime }
			}
			orderItem.id = v4()
			orderItems[selectedOrderItem] = orderItem
			setOrderItems([...orderItems])
		}
	}

	const updateOrderItemAmount = (orderItemId: string, uniqueId: string, amount: number, updateId: boolean) => {
		let newOrderItems = cloneDeep(orderItems)
		const orderItemIndex: number = newOrderItems.findIndex((x) => x.id === orderItemId)
		const productIndex: number = newOrderItems[orderItemIndex]!.products.findIndex((x) => x.uniqueId === uniqueId)

		if (amount === 0) {
			newOrderItems[orderItemIndex]!.products.splice(productIndex, 1)
			if (newOrderItems[orderItemIndex]!.products.length === 0) {
				// remove the order item since it no longer has any products
				newOrderItems = newOrderItems.filter((value, i) => i !== orderItemIndex)
			}
		} else {
			newOrderItems[orderItemIndex]!.products[productIndex]!.amount = amount
		}

		if (updateId && newOrderItems[orderItemIndex]) {
			newOrderItems[orderItemIndex]!.id = v4()
		}
		setOrderItems([...newOrderItems])
	}

	const updateOrderItemWasteTypeAmount = (orderItemId: string, uniqueId: string, amount: number) => {
		let newOrderItems = cloneDeep(orderItems)
		if (amount === 0) {
			const idx = newOrderItems.find((x) => x.id === orderItemId)?.products.findIndex((x) => x.uniqueId === uniqueId)

			if (idx !== undefined && idx > -1) {
				newOrderItems.find((x) => x.id === orderItemId)?.products.splice(idx, 1)

				if (newOrderItems.find((x) => x.id === orderItemId)!.products.length === 0) {
					// remove the order item since it no longer has any products
					newOrderItems = newOrderItems.filter((value, i) => i !== idx)
				}
			}
		} else {
			newOrderItems
				.find((x) => x.id === orderItemId)!
				.products.find((x) => x.uniqueId === uniqueId)!.wasteType!.amount = amount
		}
		setOrderItems([...newOrderItems])
	}

	const updateOrderItemPackagingAmount = (orderItemId: string, uniqueId: string, amount: number) => {
		let newOrderItems = cloneDeep(orderItems)
		if (amount === 0) {
			const idx = newOrderItems.find((x) => x.id === orderItemId)?.products.findIndex((x) => x.uniqueId === uniqueId)

			if (idx !== undefined && idx > -1) {
				newOrderItems.find((x) => x.id === orderItemId)?.products.splice(idx, 1)

				if (newOrderItems.find((x) => x.id === orderItemId)!.products.length === 0) {
					// remove the order item since it no longer has any products
					newOrderItems = newOrderItems.filter((value, i) => i !== idx)
				}
			}
		} else {
			newOrderItems
				.find((x) => x.id === orderItemId)!
				.products.find((x) => x.uniqueId === uniqueId)!.packagingMethod!.amount = amount
		}

		setOrderItems([...newOrderItems])
	}

	const removeProductFromOrderItem = (orderItemId: string, uniqueId: string) => {
		let newOrderItems = cloneDeep(orderItems)
		const orderItemIndex = newOrderItems.findIndex((x) => x.id === orderItemId)

		if (orderItemIndex > -1) {
			const newOrderItem = newOrderItems[orderItemIndex]

			if (newOrderItem) {
				const productIndex = newOrderItem.products.findIndex((x) => x.uniqueId === uniqueId)

				if (productIndex > -1) {
					newOrderItem.products.splice(productIndex, 1)
				}

				if (newOrderItem.products.length === 0) {
					// remove the order item since it no longer has any products
					newOrderItems = newOrderItems.filter((value, i) => i !== orderItemIndex)
				}

				if (newOrderItems[orderItemIndex]) {
					newOrderItem.id = v4()
					newOrderItems[orderItemIndex] = newOrderItem
				}
				setOrderItems([...newOrderItems])
				setIncrementorKeySuffix(incrementorKeySuffix + 1)
			}
		}
	}

	function submitDisabled() {
		let hosNoOrderItems = orderItems.length < 1
		let someThingSomeThingConsumerSelected =
			auth.IsLoggedIn && clientSelectedConsumerId ? !selectedUserLocalstorage : false
		let everyOrderHasProductsAndDateAndTime = every(
			orderItems,
			(item) => item.products.length > 0 && item.date && item.time,
		)
		let anOrderIsMissingProductOrDateOrTime = !everyOrderHasProductsAndDateAndTime
		let anyOrderItemHasProductWithMissingArticles = some(orderItems, (orderItem) => {
			let anyProductIsMissingArticles = some(orderItem.products, (product: OrderItemProduct) => {
				if (product.articles) {
					return product.articles.isEmpty()
				}
				return true
			})
			return anyProductIsMissingArticles
		})
		let userIsClient = auth.IsLoggedInClient

		let requiredPricesAreMissing =
			!userIsClient && consumerCatalog.pricesEnabled && anyOrderItemHasProductWithMissingArticles

		return (
			someThingSomeThingConsumerSelected ||
			hosNoOrderItems ||
			anOrderIsMissingProductOrDateOrTime ||
			requiredPricesAreMissing
		)
	}

	function onProductIncrementorChange(
		buttonClicked: "addClick" | "removeClick" | "text",
		product: ProductDefinition,
		value: number,
		currentValue: number,
		category: string,
		service: string,
	) {
		if (buttonClicked === "addClick") {
			addProduct([
				{
					productId: product.id,
					name: product.name,
					selectedWasteTypeAmounts: undefined,
					category: category,
					serviceId: service,
				},
			])
		} else if (selectedOrderItem !== null && buttonClicked === "removeClick") {
			lets(orderItems[selectedOrderItem], (it) => {
				lets(
					it.products.find((x) => x.productId === product.id && x.serviceId === service)?.uniqueId,
					(productUniqueId) => {
						updateOrderItemAmount(it.id, productUniqueId, value, true)
					},
				)
			})
		} else if (selectedOrderItem !== null && buttonClicked === "text") {
			if (value === 0 || value - currentValue < 0) {
				lets(orderItems[selectedOrderItem], (it) => {
					lets(
						it.products.find((x) => x.productId === product.id && x.serviceId === service)?.uniqueId,
						(productUniqueId) => {
							updateOrderItemAmount(
								it.id,
								productUniqueId,
								value === 0 ? 0 : currentValue + (value - currentValue),
								true,
							)
						},
					)
				})
			} else {
				addProduct(
					[
						{
							productId: product.id,
							name: product.name,
							selectedWasteTypeAmounts: undefined,
							category: category,
							serviceId: service,
						},
					],
					value - currentValue,
				)
			}
		}
	}

	function allowedDateOrTimeSelectValues(category: ProductCategoryInstance | null, type: "date" | "time"): string[] {
		if (!category || selectedOrderItem == null || !orderItems[selectedOrderItem]) {
			return []
		}

		const orderItem = orderItems[selectedOrderItem]

		if (!orderItem) {
			return []
		}

		return exhaustive(category, "type", {
			WasteCategory: (wasteCategory) => {
				if (!orderItem.serviceId) {
					return []
				}
				if (type === "date") {
					return wasteCategory.services[orderItem.serviceId]?.dateSlots ?? []
				} else {
					return wasteCategory.services[orderItem.serviceId]?.timeSlots ?? []
				}
			},
			GoodsCategory: () => {
				const product = orderItem.products.find((x) => x.packagingMethod)
				if (!product?.packagingMethod?.id) {
					return []
				}

				if (type === "date") {
					return client.possiblePackagingMethods[product.packagingMethod.id]?.dateSlotIds ?? []
				} else {
					return client.possiblePackagingMethods[product.packagingMethod.id]?.timeSlotIds ?? []
				}
			},
		})
	}

	function modalElements(): JSX.Element | null {
		const orderItem = selectedOrderItem !== null ? orderItems[selectedOrderItem] : null
		const categoryOfSelectedOrderItem = orderItem ? client.categories[orderItem.category] : null

		const openProduct = availableProducts.find((it) => it.id === productInfoAndSelection?.productId)
		const descriptionOpen = productInfoAndSelection?.descriptionOpen ?? false

		let showFullPageProductConfig = false

		if (client.features.orderUiProductSelectionMode === ProductSelectionMode.FullPage && selectedCategory) {
			if (
				selectedCategory.type === "GoodsCategory" ||
				(selectedCategory.type === "WasteCategory" &&
					!!selectedCategory.productSelectionConfig &&
					selectedCategory.productSelectionConfig.steps.length > 0)
			) {
				showFullPageProductConfig = true
			}
		}

		return (
			<>
				{openProduct != null && selectedCategory && !showFullPageProductConfig ? (
					<ProductInformationAndSelectionModule
						product={openProduct}
						descriptionOpen={descriptionOpen}
						category={selectedCategory}
						service={
							selectedServiceId !== null && selectedCategory?.type === "WasteCategory"
								? selectedCategory?.services[selectedServiceId]
								: undefined
						}
						possibleServices={
							selectedCategory?.type === "WasteCategory" ? selectedCategory?.services || {} : {}
						}
						onClose={() => {
							setProductInfoAndSelection(null)
							setQueryParamsUrl(selectedCategory!.name, selectedServiceId || "", "", undefined, true)
							removeModalOpen(queryParams, setQueryParams)
						}}
						onProductSelected={(data) => {
							addProduct([data])
						}}
						onSetSelectedServiceId={onSetSelectedServiceId}
					/>
				) : null}
				{showDateSelectModule && orderItem ? (
					<DateSelectModule
						onDateSelected={setDate}
						allowedValues={allowedDateOrTimeSelectValues(categoryOfSelectedOrderItem || null, "date")}
						defaultValue={orderItem.date}
						onClose={() => {
							setShowDateSelectModule(false)
							removeModalOpen(queryParams, setQueryParams)
						}}
					/>
				) : null}
				{showTimeSelectModule && orderItem ? (
					<TimeSelectModule
						onTimeSelected={setTime}
						allTimeSlots={client.possibleTimeSlots}
						allowedValues={allowedDateOrTimeSelectValues(categoryOfSelectedOrderItem || null, "time")}
						defaultValue={orderItem.time}
						onClose={() => {
							setShowTimeSelectModule(false)
							removeModalOpen(queryParams, setQueryParams)
						}}
					/>
				) : null}
			</>
		)
	}

	function onProjectSelected(
		project: Project | GetProject,
		isNew: boolean,
		productSelectionReturnValue: ProductSelectionReturnValue[] | null,
	) {
		if (selectedOrderItem !== null && !isEqual(project, orderItems[selectedOrderItem]?.project)) {
			setSelectedOrderItem(null)
		}

		if ((isNew || selectedOrderItem === null) && !showEditOrderItemProject) {
			setSelectedProject(project)
		} else {
			if (showEditOrderItemProject) {
				setShowEditOrderItemProject(false)
			}

			if (selectedOrderItem !== null && orderItems[selectedOrderItem]) {
				const newItems = cloneDeep(orderItems)
				let orderItemProject: Project

				if ("id" in project) {
					orderItemProject = {
						street: project.address.street,
						city: project.address.city,
						zipcode: project.address.zipCode,
						coordinates: project.address.coordinates,
						...project,
					}
				} else {
					orderItemProject = project
				}
				let newItem = newItems[selectedOrderItem]

				if (newItem) {
					newItem = { ...newItem, project: orderItemProject }

					if (newItem.project.coordinates) {
						newItem.zoneId = getZoneIdFromPoint(client, newItem.project.coordinates!)
					}

					if (newItem.zoneId) {
						newItem.articles = getOrderItemPriceArticles(
							client,
							consumerCatalog,
							newItem,
							lets(currentDiscountCode, (it) => it.templateId),
						)
					} else {
						newItem.articles?.removeTransportationArticle()
					}

					newItems[selectedOrderItem] = newItem
				}

				setOrderItems([...newItems])
			}

			setSelectedProject(project)
		}

		if (productSelectionReturnValue) {
			addProduct(productSelectionReturnValue, 1, project)
		}
	}

	function onSetShowProjectSelectModule() {
		setQueryParamsUrl(selectedCategoryName || "", selectedServiceId || "", "", "old_proj")
	}

	function onSetShowProjectInputModule() {
		setQueryParamsUrl(selectedCategoryName || "", selectedServiceId || "", "", "new_proj")
	}

	function onSetSelectedCategory(categoryName: string) {
		setSelectedCategoryName(categoryName)
		let category = client.findCategoryByName(categoryName)
		if (category == null) {
			logger.error("Selected a non-existing category:", categoryName)
			return
		}
		exhaustive.tag(category, "type", {
			WasteCategory: (category) => {
				const serviceKey = Object.keys(category.services || {})[0]
				if (serviceKey) {
					setSelectedServiceId(category.services[serviceKey]?.id || null)
					setQueryParamsUrl(categoryName, category.services[serviceKey]?.id)
				} else {
					setSelectedServiceId(null)
					setQueryParamsUrl(categoryName, undefined)
				}
			},
			GoodsCategory: () => {
				setSelectedServiceId(null)
				setQueryParamsUrl(categoryName, undefined)
			},
			_: () => {
				logger.error("Undefined category selected, trying to continue: ", category)
			},
		})

		if (selectedOrderItem !== null && orderItems[selectedOrderItem]?.category !== "") {
			setSelectedOrderItem(null)
		}
	}

	function onSetSelectedServiceId(selectedCategory: string, serviceId: string | null) {
		setQueryParamsUrl(selectedCategory, serviceId || undefined)
	}

	function onProductClick(selectedCategory: string, product: ProductDefinitionWithPrice) {
		setProductInfoAndSelection({ productId: product.id, descriptionOpen: false })
		setQueryParamsUrl(selectedCategory, selectedServiceId || "", product.id)
	}

	function onProductCardClick(selectedCategory: string, product: ProductDefinitionWithPrice) {
		setProductInfoAndSelection({ productId: product.id, descriptionOpen: true })
		setQueryParamsUrl(selectedCategory, selectedServiceId || "", product.id)
	}

	const onOrderItemEditProject = (index: number) => {
		projectSelectionComponentRef.current?.setShowProjectSelectModule(true)
		setShowEditOrderItemProject(true)
		setQueryParamsUrl(selectedCategoryName || "", selectedServiceId || "", "", "oi_e_p", undefined, index)
	}

	const onOrderItemEditDate = (index: number) => {
		setShowDateSelectModule(true)
		setQueryParamsUrl(selectedCategoryName || "", selectedServiceId || "", "", "oi_e_d", undefined, index)
	}

	const onOrderItemEditTime = (index: number) => {
		setShowTimeSelectModule(true)
		setQueryParamsUrl(selectedCategoryName || "", selectedServiceId || "", "", "oi_e_t", undefined, index)
	}

	function onOrderItemProductIncrementorChange(
		orderItemId: string,
		uniqueId: string,
		value: number,
		type: "amount" | "waste" | "goods",
	) {
		setIncrementorKeySuffix(incrementorKeySuffix + 1)

		if (type === "amount") {
			updateOrderItemAmount(orderItemId, uniqueId, value, false)
		} else if (type === "waste") {
			updateOrderItemWasteTypeAmount(orderItemId, uniqueId, value)
		} else if (type === "goods") {
			updateOrderItemPackagingAmount(orderItemId, uniqueId, value)
		}
	}

	function canRenderOrderCheckout() {
		let somePricesAreCalculated = consumerCatalog.pricesEnabled ? Object.keys(orderItemTotalPrices).length > 0 : true
		let clientUserOrSomePricesAreCalculated = auth.IsLoggedInClient || somePricesAreCalculated
		let submitEnabled = !submitDisabled()

		return orderItems && clientUserOrSomePricesAreCalculated && submitEnabled
	}

	return (
		<Routes key={"orderContainer_route1"}>
			<Route
				key={"orderContainer_route2"}
				path=""
				element={
					<React.Fragment key={"orderContainer_route3"}>
						{modalElements()}
						<div className={cls(style.wrapper, { [style.regularBasketHidden]: !showRegularBasket })}>
							{openProduct != null && selectedCategory && showFullPageProductConfig ? (
								<FullPageProductSelection
									product={openProduct}
									category={selectedCategory}
									onProductSelected={(data) => {
										if (isMobileSize) {
											// When we're in mobile size, the data returned is correct in terms of sizes
											// As such we just send the data as is
											addProduct(data)
											return
										}

										// When we're not in mobile size, we add the data one at a time, since the items
										// Are added to the basket when pressing buttons directly
										data.forEach((x) => {
											if (x.amount) {
												x.amount = 1
											}

											Object.keys(x.selectedWasteTypeAmounts || {}).forEach((key) => {
												if (x.selectedWasteTypeAmounts && x.selectedWasteTypeAmounts[key]) {
													x.selectedWasteTypeAmounts[key] = 1
												}
											})
											Object.keys(x.packagingMethods || {}).forEach((key) => {
												if (x.packagingMethods && x.packagingMethods[key]) {
													x.packagingMethods[key] = 1
												}
											})
											addProduct([x])
										})
									}}
									decrementProduct={(data) => {
										let orderItemIndex = null
										let orderItemProductIndex = null
										let newValue = null

										for (let i = orderItems.length - 1; i > -1; i--) {
											let shouldBreak = false

											const orderItem = orderItems[i]

											if (orderItem) {
												for (let j = 0; j < orderItem.products.length; j++) {
													const packagingMethodKeys = data.packagingMethods
														? Object.keys(data.packagingMethods)
														: []
													if (packagingMethodKeys.length > 0) {
														const packagingMethod = orderItem.products[j]?.packagingMethod
														if (
															packagingMethod &&
															packagingMethod?.id === packagingMethodKeys[0]
														) {
															orderItemIndex = i
															orderItemProductIndex = j
															newValue = packagingMethod.amount - 1
															shouldBreak = true
															break
														}
													}

													const orderItemProduct = orderItem.products[j]

													if (orderItemProduct) {
														if (
															data.productId &&
															data.serviceId &&
															!data.selectedWasteTypeAmounts
														) {
															orderItemIndex = i
															orderItemProductIndex = j
															// if no amount is defined, which it should never be...
															// but if it is, default to 1, so that, it becomes 1-1 = 0
															newValue = (orderItemProduct.amount || 1) - 1
															shouldBreak = true
															break
														}

														if (
															data.productId &&
															data.serviceId &&
															data.selectedWasteTypeAmounts &&
															Object.keys(data.selectedWasteTypeAmounts).length > 0 &&
															orderItemProduct.wasteType &&
															orderItemProduct.wasteType?.wasteTypeId ===
																Object.keys(data.selectedWasteTypeAmounts)[0]
														) {
															orderItemIndex = i
															orderItemProductIndex = j
															newValue = orderItemProduct.wasteType.amount - 1
															shouldBreak = true
															break
														}
													}
												}
											}

											if (shouldBreak) {
												break
											}
										}

										if (
											orderItemIndex !== null &&
											orderItemProductIndex !== null &&
											newValue !== null
										) {
											const finalOrderItem = orderItems[orderItemIndex]
											const finalProduct = finalOrderItem?.products[orderItemProductIndex]

											if (finalOrderItem && finalProduct) {
												if (finalProduct.packagingMethod) {
													updateOrderItemPackagingAmount(
														finalOrderItem.id,
														finalProduct.uniqueId,
														newValue,
													)
												} else if (finalProduct.wasteType) {
													updateOrderItemWasteTypeAmount(
														finalOrderItem.id,
														finalProduct.uniqueId,
														newValue,
													)
												} else {
													updateOrderItemAmount(
														finalOrderItem.id,
														finalProduct.uniqueId,
														newValue,
														true,
													)
												}
											}
										}
									}}
									onCancel={() => {
										setQueryParamsUrl(selectedCategoryName || "", "", "")
									}}
								/>
							) : null}

							<div
								className={style.content}
								style={{
									display:
										openProduct != null &&
										selectedCategory &&
										showFullPageProductConfig &&
										!isModalOpen()
											? "none"
											: "block",
								}}>
								{client.welcomeWidget != null ? (
									<WidgetRendererV1 widgets={[client.welcomeWidget]} />
								) : null}
								<ul
									className={cls(style.timeline, style.mobileOnly)}
									style={{ marginBlockStart: 0, marginBlockEnd: 0, marginBottom: "20px" }}>
									<li className={style.current}>
										<div />
									</li>
									<div className={style.timelineLine} />
									<li>
										<div />
									</li>
									<div className={style.timelineLine} />
									<li>
										<div />
									</li>
								</ul>
								<ClientConsumerSelection />
								<ProjectSelection
									project={selectedProject}
									onSetShowProjectSelectModule={onSetShowProjectSelectModule}
									onSetShowProjectInputModule={onSetShowProjectInputModule}
									ref={projectSelectionComponentRef}
									currentOrderItemProject={
										selectedOrderItem != null && orderItems[selectedOrderItem]
											? orderItems[selectedOrderItem]?.project || null
											: selectedProject || null
									}
									onProjectSelected={onProjectSelected}
									onProjectInputClose={() => {
										setIncrementorKeySuffix(incrementorKeySuffix + 1)
									}}
									onProjectSelectClose={() => {
										setIncrementorKeySuffix(incrementorKeySuffix + 1)
									}}
								/>
								<CategorySelection
									selectedCategory={selectedCategoryName}
									onSetSelectedCategory={onSetSelectedCategory}
								/>
								<ServiceSelection
									selectedCategory={selectedCategoryName}
									selectedServiceId={selectedServiceId}
									onSetSelectedServiceId={onSetSelectedServiceId}
								/>
								{selectedCategoryName !== null ? (
									<ProductSelection
										selectedCategoryName={selectedCategoryName}
										selectedServiceId={selectedServiceId}
										selectedOrderItem={selectedOrderItem}
										availableProducts={availableProducts}
										incrementorKeySuffix={incrementorKeySuffix}
										orderItems={orderItems}
										onProductClick={onProductClick}
										onProductCardClick={onProductCardClick}
										onProductIncrementorChange={onProductIncrementorChange}
									/>
								) : null}
							</div>
						</div>

						<BasketSection
							orderItems={orderItems}
							submitDisabled={submitDisabled()}
							selectedOrderItem={selectedOrderItem}
							onOrderItemSelected={(val) => setSelectedOrderItem(val)}
							onOrderItemEditProject={onOrderItemEditProject}
							onRemoveOrderItem={removeOrderItem}
							onOrderItemEditDate={onOrderItemEditDate}
							onOrderItemEditTime={onOrderItemEditTime}
							onRemoveProductFromOrderItem={removeProductFromOrderItem}
							onOrderItemProductIncrementorChange={onOrderItemProductIncrementorChange}
							orderItemTotalPrices={orderItemTotalPrices}
						/>
					</React.Fragment>
				}
			/>
			{canRenderOrderCheckout() ? (
				<Route
					key={"orderContainer_route4"}
					path="checkout/*"
					element={
						<OrderConfirmCheckout
							orderItems={orderItems}
							totalPrices={orderItemTotalPrices}
							currentDiscountCode={currentDiscountCode}
							setCurrentDiscountCode={(newDiscountTemplateId) => {
								setCurrentDiscountCode(newDiscountTemplateId)
							}}
						/>
					}
				/>
			) : (
				<Route key={"orderContainer_route5"} path="checkout/*" element={<NavigatorNavigate to={"order"} />} />
			)}
			<Route key={"orderContainer_route6"} path="completed/:collectionNumber" element={<OrderCompleted />} />
		</Routes>
	)
}
