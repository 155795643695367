import { zodResolver } from "@hookform/resolvers/zod"
import { isString } from "lodash"
import { FC, useEffect, useRef, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { v4 } from "uuid"
import { z } from "zod"
import { AbsolutCentered } from "../../../AbsolutCentered/AbsolutCentered"
import { MeResponseAccountType } from "../../../Auth/Auth.types"
import { useAuth } from "../../../Auth/AuthContext"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { SelectedConsumerUserSchema } from "../../../Client/ConsumerContext"
import { GetConsumer } from "../../../CustomerPortal/CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { GetUser } from "../../../CustomerPortal/Users/Users"
import { PenIcon } from "../../../Icons/Icon"
import { Loader } from "../../../Loader/Loader"
import { API } from "../../../network/API"
import { EventQueue } from "../../../Shared/eventQueue"
import { useBrandedLocalStorage } from "../../../Shared/useBrandedLocalStorage"
import { SelectedConsumerUser } from "../../ClientSelectActiveConsumerUserModal/ClientSelectActiveConsumerUserModal"
import { invalidPhoneNumberMessage, validatePhoneNumber } from "../../order-data-model"
import { FinalizeButton } from "../Form/Buttons/Buttons"
import { ModulePopup, removeModalOpenClass } from "../ModulePopup/ModulePopup"
import { MbtH5 } from "../Text/MbtH5/MbtH5"
import { SbtH1 } from "../Text/SbtH1/SbtH1"
import { SbtH4 } from "../Text/SbtH4/SbtH4"
import { SbtRHFError } from "../Text/SbtInvalid/SbtRHFError"
import style from "./OrderConfirmCustomerInformation.module.css"

const personNumberRegex = new RegExp("^(19|20)?[0-9]{6}[- ]?[0-9]{4}$")

export const IndividualInfo = z.object({
	ordererName: z.string().optional(),
	name: z.string().min(1),
	personNumber: z.string().regex(personNumberRegex).min(10),
	email: z.string().min(3).email(),
	phone: z.string().min(3, invalidPhoneNumberMessage).refine(validatePhoneNumber, { message: invalidPhoneNumberMessage }),
})

export const OrganizationInfo = z.object({
	ordererName: z.string().optional(),
	companyName: z.string().min(1),
	organizationNumber: z.string().optional(),
	email: z.string().min(3).email(),
	phone: z.string().min(3, invalidPhoneNumberMessage).refine(validatePhoneNumber, { message: invalidPhoneNumberMessage }),
})

// duplicated but slightly altered types to allow reading empty values from local storage
export const IndividualInfoRead = z.object({
	ordererName: z.string().optional(),
	name: z.string(),
	personNumber: z.string().optional(),
	email: z.string().optional(),
	phone: z.string(),
})

export const OrganizationInfoRead = z.object({
	ordererName: z.string().optional(),
	companyName: z.string(),
	organizationNumber: z.string().optional(),
	email: z.string().optional(),
	phone: z.string(),
})

export type IndividualInfoType = z.input<typeof IndividualInfo>
export type IndividualInfoReadType = z.input<typeof IndividualInfoRead>
export type OrganizationInfoType = z.input<typeof OrganizationInfo>
export type OrganizationInfoReadType = z.input<typeof OrganizationInfoRead>

type CustomerInformationModal = {
	defaultIndividualValues: IndividualInfoReadType
	defaultOrganizationValues: OrganizationInfoReadType
	onClose: (data?: IndividualInfoType | OrganizationInfoType) => void
	lastSelectedType: "individual" | "organization"
}
export const OrderConfirmCustomerInformationModal: FC<CustomerInformationModal> = ({
	defaultIndividualValues,
	defaultOrganizationValues,
	onClose,
	lastSelectedType,
}) => {
	const client = useClient()

	const individualOrgToggleInputId = useRef(v4())
	const [isIndividual, setIsIndividual] = useState<boolean>(lastSelectedType === "individual")

	const {
		register: registerIndividual,
		handleSubmit: handleSubmitIndividual,
		formState: { errors: errorsIndividual, isValid: isValidIndividual },
	} = useForm<IndividualInfoType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(IndividualInfo)(data, context, options)
		},
		defaultValues: defaultIndividualValues,
	})

	const {
		register: registerOrg,
		handleSubmit: handleSubmitOrg,
		formState: { errors: errorsOrg, isValid: isValidOrganization },
	} = useForm<OrganizationInfoType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(OrganizationInfo)(data, context, options)
		},
		defaultValues: defaultOrganizationValues,
	})

	const onSubmit: SubmitHandler<IndividualInfoType | OrganizationInfoType> = async (data) => {
		if (isIndividual && IndividualInfo.safeParse(data).success) {
			localStorage.setItem(`${client.identifier}.consumer-individual-info`, JSON.stringify(data))
		} else if (!isIndividual && OrganizationInfo.safeParse(data).success) {
			localStorage.setItem(`${client.identifier}.consumer-organization-info`, JSON.stringify(data))
		}

		localStorage.setItem(
			`${client.identifier}.consumer-individual-info.last-selected-type`,
			isIndividual ? "individual" : "organization",
		)

		onClose(data)
	}

	return (
		<ModulePopup
			disableClose={
				Object.values(defaultIndividualValues || {}).filter((x) => x).length === 0 &&
				Object.values(defaultOrganizationValues || {}).filter((x) => x).length === 0
			}
			onClose={() => {
				onClose()
			}}
			className={style.modal}>
			<div className={style.wrapper}>
				<SbtH1>Kunduppgifter</SbtH1>
				<div style={{ marginTop: "20px", marginBottom: "20px" }}>
					<div className={style.btnContainer}>
						<label className={style.btnIndividualOrCompanySwitch}>
							<input
								type="checkbox"
								name="individualOrCompany"
								id={individualOrgToggleInputId.current}
								value="1"
								defaultChecked={lastSelectedType === "individual"}
								onChange={(x) => {
									setIsIndividual(x.target.checked)
								}}
							/>
							<label
								htmlFor={individualOrgToggleInputId.current}
								data-on="Privatperson"
								data-off="Företag"
								className={style.btnIndividualOrCompanySwitchInner}
							/>
						</label>
					</div>
				</div>
				{isIndividual ? (
					<>
						<form key="form_individual" onSubmit={handleSubmitIndividual(onSubmit)} id="form_individual">
							<div className={style.fields}>
								<label>
									<SbtH4>Fullständigt namn*</SbtH4>
									<input
										className={style.input}
										{...registerIndividual("name")}
										placeholder="Ditt Namn"
									/>
									<SbtRHFError error={errorsIndividual.name} />
								</label>
								<label>
									<SbtH4>Personnummer*</SbtH4>
									<input
										className={style.input}
										{...registerIndividual("personNumber")}
										placeholder="19800808-8080"
									/>
									<SbtRHFError error={errorsIndividual.personNumber} />
								</label>
								<label>
									<SbtH4>E-post*</SbtH4>
									<input
										className={style.input}
										{...registerIndividual("email")}
										placeholder="exempel@exempel.se"
									/>
									<SbtRHFError error={errorsIndividual.email} />
								</label>
								<label>
									<SbtH4>Telefonnummer*</SbtH4>
									<input
										className={style.input}
										{...registerIndividual("phone")}
										placeholder="070-0000000"
									/>
									<SbtRHFError error={errorsIndividual.phone} />
								</label>
							</div>
							<p className={style.mandatoryFieldInformation}>*Obligatoriskt fält</p>
						</form>
						<FinalizeButton
							onClick={() => {
								;(document.getElementById("form_individual") as HTMLFormElement).requestSubmit()
							}}
							disabled={!isValidIndividual}
							type="submit">
							Spara
						</FinalizeButton>
					</>
				) : (
					<>
						<form key="form_org" onSubmit={handleSubmitOrg(onSubmit)} id="form_org">
							<div className={style.fields}>
								<label>
									<SbtH4>Företagsnamn*</SbtH4>
									<input
										className={style.input}
										{...registerOrg("companyName")}
										placeholder="Ditt Företag"
									/>
									<SbtRHFError error={errorsOrg.companyName} />
								</label>
								{client.features.orderUiShowOrgNumber ? (
									<label>
										<SbtH4>Organisations&#8203;nummer</SbtH4>
										<input
											className={style.input}
											{...registerOrg("organizationNumber")}
											placeholder="555555-5555"
										/>
										<SbtRHFError error={errorsOrg.organizationNumber} />
									</label>
								) : null}

								<label>
									<SbtH4>E-post*</SbtH4>
									<input
										className={style.input}
										{...registerOrg("email")}
										placeholder="exempel@exempel.se"
									/>
									<SbtRHFError error={errorsOrg.email} />
								</label>
								<label>
									<SbtH4>Telefonnummer*</SbtH4>
									<input className={style.input} {...registerOrg("phone")} placeholder="070-0000000" />
									<SbtRHFError error={errorsOrg.phone} />
								</label>
							</div>
							<p className={style.mandatoryFieldInformation}>*Obligatoriskt fält</p>
						</form>
						<FinalizeButton
							onClick={() => {
								;(document.getElementById("form_org") as HTMLFormElement).requestSubmit()
							}}
							disabled={!isValidOrganization}
							type="submit">
							Spara
						</FinalizeButton>
					</>
				)}
			</div>
		</ModulePopup>
	)
}

type Props = {
	onSelectedBefore: () => void
	onSelected: (data?: IndividualInfoType | OrganizationInfoType) => void
}
export const OrderConfirmCustomerInformation: FC<Props> = ({ onSelectedBefore, onSelected }) => {
	const auth = useAuth()
	const client = useClient()

	const [selectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})
	const [clientSelectedConsumerId] = useBrandedLocalStorage("client-selected-consumer-id", z.string(), {
		defaultValue: "",
	})
	const [selectedUserLocalstorage] = useBrandedLocalStorage(
		"client-selected-consumer-user",
		z.string().or(SelectedConsumerUserSchema),
		{
			defaultValue: "",
		},
	)

	const [showModal, setShowModal] = useState<boolean>(false)
	const [dataToShow, setDataToShow] = useState<IndividualInfoType | OrganizationInfoType | null>(null)

	const savedIndividualDetails = IndividualInfoRead.safeParse(
		JSON.parse(localStorage.getItem(`${client.identifier}.consumer-individual-info`) || "[]"),
	)
	let defaultIndividualValues: IndividualInfoReadType

	if (savedIndividualDetails.success) {
		defaultIndividualValues = savedIndividualDetails.data
	} else {
		defaultIndividualValues = { name: "", personNumber: "", email: "", phone: "" }
	}

	const savedOrganizationDetails = OrganizationInfoRead.safeParse(
		JSON.parse(localStorage.getItem(`${client.identifier}.consumer-organization-info`) || "[]"),
	)
	let defaultOrganizationValues: OrganizationInfoReadType

	if (savedOrganizationDetails.success) {
		defaultOrganizationValues = savedOrganizationDetails.data
	} else {
		defaultOrganizationValues = { organizationNumber: "", companyName: "", email: "", phone: "" }
	}

	const lastSelectedType =
		localStorage.getItem(`${client.identifier}.consumer-individual-info.last-selected-type`) === "individual"
			? "individual"
			: "organization"

	useEffect(() => {
		setDataToShow(null)
		onSelectedBefore()
		if (
			auth.IsLoggedIn &&
			auth.Me &&
			((auth.Me.type === MeResponseAccountType.Consumer && !!selectedConsumerId) ||
				(auth.Me.type === MeResponseAccountType.Client && !!clientSelectedConsumerId))
		) {
			const consumerIdToUse =
				auth.Me.type === MeResponseAccountType.Consumer ? selectedConsumerId : clientSelectedConsumerId
			API.getWithRetries<GetConsumer>(
				`/customer-portal/consumers-v1/${client.identifier}/details/${consumerIdToUse}`,
				true,
			)
				.then(async (res) => {
					if (!auth.Me) {
						return
					}
					let consumerUser: SelectedConsumerUser | null = null
					if (auth.Me?.type === MeResponseAccountType.Client) {
						if (isString(selectedUserLocalstorage)) {
							await API.getWithRetries<GetUser>(
								`/customer-portal/users-v1/consumer/${client.identifier}/${consumerIdToUse}/${selectedUserLocalstorage}`,
								true,
							)
								.then((user) => {
									consumerUser = {
										id: user.id,
										name:
											user.firstName || user.lastName
												? `${user.firstName} ${user.lastName}`
												: user.username,
										email: user.email,
										phone: user.phoneNumber,
									}
								})
								.catch(() => {})
						} else {
							consumerUser = selectedUserLocalstorage
						}
					} else {
						consumerUser = {
							id: auth.Me.userId,
							name:
								auth.Me.firstName || auth.Me.lastName
									? `${auth.Me.firstName} ${auth.Me.lastName}`
									: auth.Me.username,
							email: auth.Me.email,
							phone: auth.Me.phoneNumber,
						}
					}

					if (!consumerUser) {
						return
					}

					let data: IndividualInfoType | OrganizationInfoType
					if (res.consumerDetails.type === "CompanyConsumerDetails") {
						data = {
							ordererName: consumerUser.name,
							companyName: res.consumerDetails.name,
							email: consumerUser.email || "",
							phone: consumerUser.phone || "",
							organizationNumber: res.consumerDetails.orgNumber,
						}
					} else {
						data = {
							ordererName: consumerUser.name,
							name: res.consumerDetails.name,
							email: consumerUser.email || "",
							phone: consumerUser.phone || "",
							personNumber: res.consumerDetails.personNumber || "",
						}
					}
					onSelected(data)
					setDataToShow(data)
				})
				.catch(() => {})
		} else {
			if (lastSelectedType === "individual" && IndividualInfo.safeParse(defaultIndividualValues).success) {
				onSelected(defaultIndividualValues as IndividualInfoType)
				setDataToShow(defaultIndividualValues as IndividualInfoType)
			} else if (
				lastSelectedType === "organization" &&
				OrganizationInfo.safeParse(defaultOrganizationValues).success
			) {
				onSelected(defaultOrganizationValues as OrganizationInfoType)
				setDataToShow(defaultOrganizationValues as OrganizationInfoType)
			} else {
				setShowModal(true)
			}
		}
	}, [selectedConsumerId, clientSelectedConsumerId])

	function onClose(data?: IndividualInfoType | OrganizationInfoType) {
		removeModalOpenClass()
		setShowModal(false)
		if (data) {
			onSelectedBefore()
			onSelected(data)
			setDataToShow(data)
		}
	}
	return (
		<>
			{showModal ? (
				<OrderConfirmCustomerInformationModal
					defaultIndividualValues={defaultIndividualValues}
					defaultOrganizationValues={defaultOrganizationValues}
					onClose={onClose}
					lastSelectedType={lastSelectedType}
				/>
			) : null}
			<div className={style.customerInfoWrapper}>
				{!dataToShow ? (
					<AbsolutCentered>
						<Loader />
					</AbsolutCentered>
				) : (
					<>
						<div className={style.customerInfoHeader}>
							<MbtH5>{"companyName" in dataToShow ? dataToShow.companyName : dataToShow.name}</MbtH5>{" "}
							{auth.Me &&
							((auth.IsLoggedInClient && clientSelectedConsumerId) ||
								(auth.IsLoggedInConsumer && auth.Me.consumers.length <= 1)) ? null : (
								<PenIcon
									size={22}
									className={style.cursorPointer}
									iconClassName={style.moduleBoxIconColorStroke}
									onClick={() => {
										if (
											!!(
												auth.IsLoggedIn &&
												auth.Me &&
												auth.Me.type === "Consumer" &&
												auth.Me.consumers.length > 1 &&
												selectedConsumerId
											)
										) {
											EventQueue.dispatchEvent("show-select-consumer", {})
										} else {
											setShowModal(true)
										}
									}}
								/>
							)}
						</div>
						<div>
							{"companyName" in dataToShow ? (
								<span className={style.customerInfo}>
									<div>{dataToShow.organizationNumber}</div>
									<div>{dataToShow.ordererName}</div>
									<div style={{ wordBreak: "break-all" }}>{dataToShow.email}</div>
									<div>{dataToShow.phone}</div>
								</span>
							) : (
								<span className={style.customerInfo}>
									<div>{dataToShow.personNumber}</div>
									<div>{dataToShow.ordererName}</div>
									<div style={{ wordBreak: "break-all" }}>{dataToShow.email}</div>
									<div>{dataToShow.phone}</div>
								</span>
							)}
						</div>
					</>
				)}
			</div>
		</>
	)
}
