import { exhaustive } from "exhaustive"
import { isEmpty } from "lodash"
import { FC } from "react"
import { v4 } from "uuid"
import { PreferredVATRenderPolicy } from "../../../Client/consumerCatalogConfigurationTypes"
import orderConfirmPriceSummaryStyle from "../../../Orders/Components/OrderConfirmPriceSummarySection/OrderConfirmPriceSummarySection.module.css"
import { AnonymousOrder } from "../../../Orders/OrderCompleted/AnonymousOrderResponse"
import { productServiceUnitToHumanText } from "../../../Orders/OrderContainer/Logic"
import { Annotation } from "../../../Shared/Annotation/Annotation"
import { getKey } from "../../../Shared/getKey"
import { lets } from "../../../Shared/lets"
import { currencyFormatter } from "../../../Shared/numberFormatter"
import { DiscountEntry } from "../../../Shared/PriceDisplay/DiscountEntry"
import { when } from "../../../Shared/when"
import { ArticleType, GetOrder } from "../../CustomerPortalOrders/CustomerPortalOrders"
import { DiscountRefExplanationTooltip } from "../ExplainDiscount/ExplainDiscount"
import style from "./OrderPriceDetails.module.css"

type Props = {
	order: GetOrder | AnonymousOrder
	preferredVATRenderPolicy?: PreferredVATRenderPolicy
}

export const OrderPriceDetails: FC<Props> = ({ order, preferredVATRenderPolicy: extPreferredVATRenderPolicy }) => {
	let preferredVATRenderPolicy: PreferredVATRenderPolicy =
		extPreferredVATRenderPolicy || PreferredVATRenderPolicy.IncludeVAT

	if (order.consumerGroupSettings && !preferredVATRenderPolicy) {
		preferredVATRenderPolicy = exhaustive(order.consumerGroupSettings, "type", {
			Contract: () => {
				return PreferredVATRenderPolicy.IncludeVAT
			},
			Prepay: (it) => {
				return it.preferredVATRenderPolicy
			},
		})
	}

	let totalPriceWithoutTax: number = 0
	let totalTaxAmount: number = 0
	function getOrderPriceSummaryTable(): JSX.Element | null {
		const productPriceElements: (JSX.Element | null)[] = order.products.map((product) => {
			const article = product.productArticles?.find((x) => x.type === ArticleType.Product)

			if (!article) {
				return null
			}

			totalPriceWithoutTax += article.price * product.amount
			totalTaxAmount += article.price * article.taxPercentage * product.amount

			let theNumber: number =
				preferredVATRenderPolicy === PreferredVATRenderPolicy.IncludeVAT
					? article.price * (1 + article.taxPercentage) * product.amount
					: article.price * product.amount
			const price = currencyFormatter(theNumber)

			const key = getKey(product)
			const priceId = "productPrice-" + key

			return (
				<div key={key} className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryRow}>
					<span>
						{product.amount} {productServiceUnitToHumanText(product.unit)}{" "}
						{"productName" in product ? product.productName : product.name}
						{"productName" in product
							? product?.wasteName
								? " - " + product?.wasteName
								: null
							: product?.wasteType?.name
							? " - " + product?.wasteType?.name
							: null}
					</span>
					<span id={priceId}>
						{price}
						{lets(product.discountDescriptionRef, (id) => (
							<>
								<DiscountRefExplanationTooltip
									discountDescriptionId={id}
									taxPercentage={
										preferredVATRenderPolicy === PreferredVATRenderPolicy.IncludeVAT
											? article.taxPercentage
											: null
									}
									anchorSelect={`#${priceId}`}
									order={order}
								/>
								<Annotation annotationClassName={style.annotation}>R</Annotation>
							</>
						))}
					</span>
				</div>
			)
		})

		const discounts = discountElements()

		return (
			<div key={v4()} className={style.orderItemPriceSummaryWrapper}>
				{productPriceElements}
				{transportPriceElement()}
				{dateSlotPriceElement()}
				{discounts != null ? (
					<>
						<hr />
						{discounts}
					</>
				) : null}
				<hr />
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryTotal}>
					<span>Totalt</span>
					<span>
						{when(preferredVATRenderPolicy, {
							[PreferredVATRenderPolicy.ExcludeVAT]: () => {
								return currencyFormatter(totalPriceWithoutTax)
							},
							[PreferredVATRenderPolicy.IncludeVAT]: () => {
								return currencyFormatter(totalPriceWithoutTax + totalTaxAmount)
							},
						})}
					</span>
				</div>
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryTotalSubRow}>
					{when(preferredVATRenderPolicy, {
						[PreferredVATRenderPolicy.ExcludeVAT]: () => {
							return (
								<>
									<span>Priser är exklusive moms</span>
									<span></span>
								</>
							)
						},
						[PreferredVATRenderPolicy.IncludeVAT]: () => {
							return (
								<>
									<span>Varav moms</span>
									<span>{currencyFormatter(totalTaxAmount)}</span>
								</>
							)
						},
					})}
				</div>
			</div>
		)
	}

	function transportPriceElement(): JSX.Element {
		let transportPriceElement: JSX.Element

		const zoneName = order.transportZoneName ? (
			<>
				- <strong>{order.transportZoneName}</strong>
			</>
		) : null

		const transportPriceArticle = order.orderArticles?.find((x) => x.type === ArticleType.Transport)

		if (transportPriceArticle) {
			totalPriceWithoutTax += transportPriceArticle.price
			totalTaxAmount += transportPriceArticle.price * transportPriceArticle.taxPercentage
			transportPriceElement = (
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryZoneRow}>
					<div>Transportavgift {zoneName}</div>
					<div>
						{preferredVATRenderPolicy === PreferredVATRenderPolicy.IncludeVAT
							? currencyFormatter(transportPriceArticle.price * (1 + transportPriceArticle.taxPercentage))
							: currencyFormatter(transportPriceArticle.price)}
					</div>
				</div>
			)
		} else {
			transportPriceElement = (
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryZoneRow}>
					<div>
						Transportavgift <strong>{zoneName} - Pris ej identifierat</strong>
					</div>
					<div></div>
				</div>
			)
		}

		return transportPriceElement
	}

	function dateSlotPriceElement(): JSX.Element | null {
		if (!order.datetime.dateSlot) {
			return null
		}

		let dateSlotPriceElement: JSX.Element

		const dateSlotPriceArticle = order.orderArticles?.find((x) => x.type === ArticleType.DateSlot)

		if (dateSlotPriceArticle) {
			totalPriceWithoutTax += dateSlotPriceArticle.price
			totalTaxAmount += dateSlotPriceArticle.price * dateSlotPriceArticle.taxPercentage
			dateSlotPriceElement = (
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryZoneRow}>
					<div>
						Datumavgift - <strong>{order.datetime.dateSlot.name}</strong>
					</div>
					<div>
						{preferredVATRenderPolicy === PreferredVATRenderPolicy.IncludeVAT
							? currencyFormatter(dateSlotPriceArticle.price * (1 + dateSlotPriceArticle.taxPercentage))
							: currencyFormatter(dateSlotPriceArticle.price)}
					</div>
				</div>
			)
		} else {
			dateSlotPriceElement = (
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryZoneRow}>
					<div>
						Datumavgift - <strong>{order.datetime.dateSlot.name} - Pris ej identifierat</strong>
					</div>
					<div></div>
				</div>
			)
		}

		return dateSlotPriceElement
	}

	function discountElements() {
		const discountArticles = order.orderArticles?.filter((x) => x.type === ArticleType.Discount)

		if (isEmpty(discountArticles)) {
			return null
		}

		return discountArticles?.map((discountArticle) => {
			totalPriceWithoutTax += discountArticle.price
			totalTaxAmount += discountArticle.price * discountArticle.taxPercentage
			return (
				<DiscountEntry
					renderVAT={preferredVATRenderPolicy === PreferredVATRenderPolicy.IncludeVAT}
					discountArticle={discountArticle}
					className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryZoneRow}
					key={getKey(discountArticle)}
				/>
			)
		})
	}

	return (
		<>
			{lets(order.discountCode, (it) => {
				return (
					<div key={v4()} className={style.orderItemPriceSummaryWrapper}>
						<strong>Använd rabattkod: {it.identifier}</strong>
						<div>{it.label}</div>
					</div>
				)
			})}

			<div>{getOrderPriceSummaryTable()}</div>
		</>
	)
}
