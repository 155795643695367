import { faFileLines, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { exhaustive } from "exhaustive"
import { isArray } from "lodash"
import { FC, useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { v4 } from "uuid"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { Loader } from "../../Loader/Loader"
import { FieldModuleBox } from "../../Orders/Components/FieldModuleBox/FieldModuleBox"
import { FieldModuleBoxWrapper } from "../../Orders/Components/FieldModuleBox/FieldModuleBoxWrapper"
import { AccentButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { removeModalOpenClass } from "../../Orders/Components/ModulePopup/ModulePopup"
import { cls } from "../../Shared/cls"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import {
	GetOrder,
	getSpecificOrderForClient,
	getSpecificOrderForConsumer,
} from "../CustomerPortalOrders/CustomerPortalOrders"
import { OrderInformationModalHandler } from "../OrderInformationModalHandler/OrderInformationModalHandler"
import style from "./CustomerPortalOrdersDocuments.module.css"

type GetOrderProjectFileBase = {
	orderId: string
	orderNumber: number
	originalFileName?: string
}

type GetOrderProjectFileImage = GetOrderProjectFileBase & {
	type: "Image"
	url: string
}

type GetOrderProjectFileGeneric = GetOrderProjectFileBase & {
	type: "Generic"
	url: string
}

type GetOrderProjectFile = GetOrderProjectFileImage | GetOrderProjectFileGeneric

type CustomerPortalProjectDocumentsProps = {
	infoText: string
	orders: GetOrder[] | null
	consumerId: string
}
export const CustomerPortalOrdersDocuments: FC<CustomerPortalProjectDocumentsProps> = (
	props: CustomerPortalProjectDocumentsProps,
) => {
	const auth = useAuth()
	const client = useClient()

	const [selectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const [queryParams, setQueryParams] = useSearchParams()

	const [files, setFiles] = useState<GetOrderProjectFile[] | null>(null)
	const [informationOrder, setInformationOrder] = useState<GetOrder | null>(null)
	const [retrievingOrder, setRetrievingOrder] = useState<boolean>(false)

	const retrievedOrders = useRef<{ [orderId: string]: GetOrder }>({})

	useEffect(() => {
		if (props.orders) {
			setFilesFromOrders(props.orders)
		}
	}, [props.orders])

	function setFilesFromOrders(orders: GetOrder[]) {
		const ret: GetOrderProjectFile[] = orders.flatMap((order) => {
			if (isArray(order.files) && order.files.length > 0) {
				return order.files
					.map<GetOrderProjectFile>((file) => {
						return exhaustive(file, "type", {
							Generic: (it) => {
								return {
									orderId: order.id,
									orderNumber: order.orderNumber,
									type: "Generic",
									url: it.url,
									originalFileName: it.originalFileName,
								}
							},
							Image: (it) => {
								return {
									orderId: order.id,
									orderNumber: order.orderNumber,
									type: "Image",
									url: it.url,
									originalFileName: it.originalFileName,
								}
							},
						})
					})
					.filter((x) => !!x) as GetOrderProjectFile[]
			}

			return []
		})

		setFiles(ret)
	}

	function openOrder(orderId: string) {
		const currentRetrievedOrders = retrievedOrders.current[orderId]
		if (currentRetrievedOrders) {
			setInformationOrder(currentRetrievedOrders)
			return
		}

		if (!auth.Me) {
			return
		}
		setRetrievingOrder(true)
		const func =
			auth.Me.type === "Consumer" && selectedConsumerId
				? getSpecificOrderForConsumer(client.identifier, selectedConsumerId, orderId)
				: getSpecificOrderForClient(client.identifier, orderId)

		func.then((order) => {
			retrievedOrders.current[orderId] = order
			setInformationOrder(order)
			setRetrievingOrder(false)
		}).catch(() => {
			setRetrievingOrder(false)
		})
	}

	function renderedFiles(): JSX.Element | null {
		if (!files) {
			return null
		}

		if (files.length === 0) {
			return <div className={style.noDocsText}>Inga dokument tillgängliga</div>
		}

		return (
			<FieldModuleBoxWrapper className={style.listWrapper}>
				{files.map((file) => {
					return (
						<FieldModuleBox key={v4()} className={style.listItem} hoverAndClick="no-propagate">
							{file.type === "Image" ? (
								<img
									className={style.image}
									src={file.url}
									alt=""
									onClick={() => {
										window.open(file.url, "_blank")
									}}
								/>
							) : null}
							{file.type === "Generic" ? (
								<div
									className={style.image}
									key={file.url}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										marginBottom: "5px",
									}}
									onClick={() => {
										window.open(file.url, "_blank")
									}}>
									<FontAwesomeIcon
										style={{
											color: "var(--module-box-icon-color)",
											width: "70%",
											height: "70%",
										}}
										icon={faFileLines}
									/>
								</div>
							) : null}
							{file.originalFileName ? (
								<div className={cls(style.fileName, "oneLineClamp")}>{file.originalFileName}</div>
							) : null}
							<div className={style.orderNumber}>Order #{file.orderNumber}</div>
							<div>
								<AccentButton
									className={style.showOrderButton}
									disabled={retrievingOrder}
									onClick={() => {
										openOrder(file.orderId)
									}}>
									Visa order{" "}
									{retrievingOrder ? (
										<FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} />
									) : null}
								</AccentButton>
							</div>
						</FieldModuleBox>
					)
				})}
			</FieldModuleBoxWrapper>
		)
	}

	return (
		<>
			<OrderInformationModalHandler
				acceptanceOrder={null}
				completionOrder={null}
				informationOrder={informationOrder}
				setAcceptanceOrder={() => {}}
				setCompletionOrder={() => {}}
				setInformationOrder={setInformationOrder}
				onClose={() => {
					removeModalOpenClass()
					queryParams.delete("tabId")
					setQueryParams(queryParams)
				}}
				onDone={() => {}}
				onUpdate={() => {}}
			/>
			<div className={style.wrapper}>
				<div className={style.title}>Dokument</div>
				<div className={style.box}>
					<div className={style.infoText} style={{ marginBottom: "20px" }}>
						{props.infoText}
					</div>
					{files ? (
						renderedFiles()
					) : (
						<AbsolutCentered>
							<Loader></Loader>
						</AbsolutCentered>
					)}
				</div>
			</div>
		</>
	)
}
