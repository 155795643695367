import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isArray, isPlainObject, isString } from "lodash"
import { FC, useEffect, useState } from "react"
import { z } from "zod"
import { MeResponseConsumerData } from "../../../Auth/Auth.types"
import { useAuth } from "../../../Auth/AuthContext"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { SelectedConsumerUserSchema } from "../../../Client/ConsumerContext"
import { GetConsumerUsersResponse, GetUser } from "../../../CustomerPortal/Users/Users"
import { CrossIcon, KontaktIcon, OrganisationsnummerIcon } from "../../../Icons/Icon"
import { API } from "../../../network/API"
import { cls } from "../../../Shared/cls"
import { EventQueue } from "../../../Shared/eventQueue"
import { useBrandedLocalStorage } from "../../../Shared/useBrandedLocalStorage"
import { ClientSelectActiveConsumerModal } from "../../ClientSelectActiveConsumerModal/ClientSelectActiveConsumerModal"
import {
	ClientSelectActiveConsumerUserModal,
	SelectedConsumerUser,
} from "../../ClientSelectActiveConsumerUserModal/ClientSelectActiveConsumerUserModal"
import { MbactH1 } from "../../Components/Text/MbactH1/MbactH1"
import orderContainerStyle from "../OrderContainer.module.css"
import style from "./ClientConsumerSelection.module.css"

export type ClientConsumerSelectionProps = {}
export const ClientConsumerSelection: FC<ClientConsumerSelectionProps> = () => {
	const auth = useAuth()
	const client = useClient()

	const [clientSelectedConsumerId, setClientSelectedConsumerId] = useBrandedLocalStorage(
		"client-selected-consumer-id",
		z.string(),
		{
			defaultValue: "",
		},
	)

	const [selectedUserLocalstorage, setSelectedUserLocalstorage] = useBrandedLocalStorage(
		"client-selected-consumer-user",
		z.string().or(SelectedConsumerUserSchema),
		{
			defaultValue: "",
		},
	)

	const [consumerDetails, setConsumerDetails] = useState<MeResponseConsumerData | null>(() => {
		if (clientSelectedConsumerId) {
			const res = auth.Me?.consumers?.find((x) => x.id === clientSelectedConsumerId) || null
			if (!res) {
				setClientSelectedConsumerId("")
				setSelectedUserLocalstorage("")
			}
			return res
		}
		return null
	})

	const [consumerUserDetails, setConsumerUserDetails] = useState<SelectedConsumerUser | null>(null)

	const [showConsumerSelectionModal, setShowConsumerSelectionModal] = useState(false)
	const [showConsumerUserSelectionModal, setShowConsumerUserSelectionModal] = useState(false)
	const [consumerUsers, setConsumerUsers] = useState<GetUser[] | "loading" | null>(null)

	useEffect(() => {
		const id = EventQueue.addEventListener("show-client-consumer-user-selection", () => {
			setShowConsumerUserSelectionModal(true)
		})

		return () => {
			EventQueue.removeEventListener(id)
		}
	}, [])

	useEffect(() => {
		if (!auth.IsLoggedIn) {
			setConsumerDetails(null)
		}
	}, [auth])

	useEffect(() => {
		if (consumerDetails) {
			loadConsumerUsers(consumerDetails.id)
		}
	}, [consumerDetails])

	if (!auth.IsLoggedInClient || !auth.IsLoggedInAndHasConsumers) {
		return null
	}

	function loadConsumerUsers(consumerId: string) {
		setConsumerUsers("loading")
		API.getWithRetries<GetConsumerUsersResponse>(
			`/customer-portal/users-v1/consumer/${client.identifier}/${consumerId}`,
			true,
		)
			.then((res) => {
				setConsumerUsers(res.users)
				if (isPlainObject(selectedUserLocalstorage)) {
					setConsumerUserDetails(selectedUserLocalstorage as SelectedConsumerUser)
				} else {
					const user = res.users.find((x) => x.id === (selectedUserLocalstorage as string))
					if (user) {
						setConsumerUserDetails({
							id: user.id,
							name: user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.username,
							email: user.email,
							phone: user.phoneNumber,
						})
					}
				}
			})
			.catch(() => {
				setConsumerUsers([])
			})
	}

	return (
		<>
			{showConsumerSelectionModal ? (
				<ClientSelectActiveConsumerModal
					selectedConsumerId={clientSelectedConsumerId}
					onClose={() => {
						setShowConsumerSelectionModal(false)
					}}
					onDone={(consumerId) => {
						if (!auth.Me?.consumers) {
							return
						}
						setShowConsumerSelectionModal(false)
						const foundConsumer = auth.Me?.consumers.find((x) => x.id === consumerId)
						if (foundConsumer) {
							setClientSelectedConsumerId(consumerId)
							setConsumerDetails(foundConsumer)
							setSelectedUserLocalstorage("")
							setConsumerUserDetails(null)
						}
					}}
				/>
			) : null}
			{showConsumerUserSelectionModal && isArray(consumerUsers) && consumerDetails ? (
				<ClientSelectActiveConsumerUserModal
					consumer={consumerDetails}
					selectedUser={selectedUserLocalstorage || null}
					users={consumerUsers}
					onClose={() => {
						setShowConsumerUserSelectionModal(false)
					}}
					onDone={(userDetails) => {
						setSelectedUserLocalstorage(userDetails)
						if (isString(userDetails)) {
							const user = consumerUsers.find((x) => x.id === userDetails)
							if (user) {
								setConsumerUserDetails({
									id: user.id,
									name:
										user.firstName || user.lastName
											? `${user.firstName} ${user.lastName}`
											: user.username,
									email: user.email,
									phone: user.phoneNumber,
								})
							}
						} else {
							setConsumerUserDetails(userDetails)
						}
					}}
				/>
			) : null}
			<div className={orderContainerStyle.section} style={{ maxWidth: "780px" }}>
				<div className={orderContainerStyle.sectionH1}>
					<MbactH1>Kunduppgifter</MbactH1>
				</div>
				<div className={cls(orderContainerStyle.projectCell, style.buttonsWrapper)}>
					<div
						className={cls(orderContainerStyle.selectedProjectSection, {
							[style.noSelectedConsumer]: !consumerDetails,
						})}
						onClick={() => {
							setShowConsumerSelectionModal(true)
						}}>
						<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
							{consumerDetails ? (
								<>
									<div className={orderContainerStyle.projectMainText}>{consumerDetails.name} </div>
									<div className={orderContainerStyle.projectSubText}>{consumerDetails.identifier}</div>
								</>
							) : (
								<div className={orderContainerStyle.projectMainText}>Välj kund</div>
							)}
						</div>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							{consumerDetails ? (
								<CrossIcon
									size={24}
									className={style.icon}
									iconClassName={style.iconInside}
									onClick={(e) => {
										e?.stopPropagation()
										setConsumerDetails(null)
										setClientSelectedConsumerId("")
										setSelectedUserLocalstorage("")
										setConsumerUserDetails(null)
									}}
								/>
							) : (
								<OrganisationsnummerIcon
									size={24}
									className={style.icon}
									iconClassName={style.iconInside}
								/>
							)}
						</div>
					</div>
					<div
						className={cls(
							orderContainerStyle.selectedProjectSection,
							{ [style.noSelectedUserButton]: !consumerUserDetails },
							{
								[style.selectUserDisabled]: !consumerDetails || !isArray(consumerUsers),
							},
						)}
						onClick={() => {
							if (consumerDetails && isArray(consumerUsers)) {
								setShowConsumerUserSelectionModal(true)
							}
						}}>
						<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
							{!consumerUserDetails || consumerUsers === "loading" ? (
								<div className={orderContainerStyle.projectMainText}>Välj beställare</div>
							) : (
								<>
									<div className={orderContainerStyle.projectMainText}>{consumerUserDetails.name} </div>
									<div className={orderContainerStyle.projectSubText}>
										{consumerUserDetails.email || ""}
									</div>
									<div className={orderContainerStyle.projectSubText}>
										{consumerUserDetails.phone || ""}
									</div>
								</>
							)}
						</div>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							{consumerUsers === "loading" ? (
								<FontAwesomeIcon spin={true} icon={faSpinner} />
							) : (
								<KontaktIcon size={22} className={style.icon} iconClassName={style.iconInside} />
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
