import { map, toNumber } from "lodash"
import { FC, useState } from "react"
import { v4 } from "uuid"
import expandableIncrementorStyle from "../ExpandableIncrementor/ExpandableIncrementor.module.css"
import style from "./ExpandableDropdown.module.css"

type DropdownProps = {
	getItems: (indexes: number[], max?: number) => DropdownItem[]
	unSelectionItemText?: string
	onChange: (item: DropdownItem | null) => void
	defaultValue: number | string | null
	max?: number
	disabled?: boolean
}

export type DropdownItem = {
	key: string
	value: number | string
	disabled?: boolean
}

export const Dropdown: FC<DropdownProps> = ({ getItems, onChange, unSelectionItemText, defaultValue, max, disabled }) => {
	const items = getItems([...Array(50).keys()], max)
	const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(
		items.find((x) => x.value === defaultValue) || null,
	)

	return (
		<select
			disabled={disabled}
			value={selectedItem?.value}
			className={style.dropdown}
			onChange={(event) => {
				const value = !isNaN(toNumber(event.target.value)) ? toNumber(event.target.value) : event.target.value
				const item = items.find((x) => x.value === value) || null
				setSelectedItem(item)
				onChange(item)
			}}>
			{unSelectionItemText ? (
				<option key={v4()} value={0}>
					{unSelectionItemText}
				</option>
			) : null}
			{map(items, (item) => {
				return (
					<option disabled={item.disabled} key={v4()} value={item.value}>
						{item.key}
					</option>
				)
			})}
		</select>
	)
}

export const ExpandableDropdown: FC<DropdownProps> = ({
	getItems,
	onChange,
	unSelectionItemText,
	defaultValue,
	max,
	disabled,
}) => {
	const items = getItems([...Array(50).keys()], max)
	const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(
		items.find((x) => x.value === defaultValue) || null,
	)

	return selectedItem ? (
		<select
			disabled={disabled}
			value={selectedItem.value}
			className={style.dropdown}
			onChange={(event) => {
				const value = !isNaN(toNumber(event.target.value)) ? toNumber(event.target.value) : event.target.value
				const item = items.find((x) => x.value === value) || null
				setSelectedItem(item)
				onChange(item)
			}}>
			{unSelectionItemText ? (
				<option key={v4()} value={0}>
					{unSelectionItemText}
				</option>
			) : null}
			{map(items, (item) => {
				return (
					<option disabled={item.disabled} key={v4()} value={item.value}>
						{item.key}
					</option>
				)
			})}
		</select>
	) : (
		<button
			onClick={(event) => {
				event.preventDefault()
				event.stopPropagation()
				setSelectedItem(items[0] || null)
				onChange(items[0] || null)
			}}
			type="button"
			className={expandableIncrementorStyle.buttons}>
			+
		</button>
	)
}
