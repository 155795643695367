import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useState } from "react"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { GetOrder } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { OrderDetails, SelectedTab } from "../../CustomerPortal/OrderDetails/OrderDetails"
import { API } from "../../network/API"
import { OrderState } from "../../OrderAcceptance/OrderCollection"
import { DoneButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup, ModulePopupProps } from "../../Orders/Components/ModulePopup/ModulePopup"
import { cls } from "../../Shared/cls"
import style from "../OrderAcceptance/OrderAcceptance.module.css"

type Props = {
	order: GetOrder
	onUpdate: (order: GetOrder) => void
	onDone: (newOrder: GetOrder) => void
} & ModulePopupProps

export type UpdateOrderStateAndComment = {
	state: OrderState
	comment?: string
}
export const OrderCompletion: FC<Props> = ({ order, onClose, onDone, onUpdate, ...props }) => {
	const auth = useAuth()
	const client = useClient()

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [showFinalizeButtons, setShowFinalizeButtons] = useState(true)

	function onSubmit() {
		setIsSubmitting(true)

		const updateOrder: UpdateOrderStateAndComment = {
			state: OrderState.Done,
		}
		API.postWithRetries<GetOrder>(
			`/customer-portal/orders/client-v1/${client.identifier}/set-state-and-comment/${order.id}`,
			updateOrder,
		).then(
			(updated) => {
				onDone(updated)
				onClose()
			},
			() => {
				setIsSubmitting(false)
			},
		)
	}

	return (
		<ModulePopup {...props} onClose={onClose} className={style.modal}>
			<div className={style.contentWrapper}>
				<OrderDetails
					onUpdate={onUpdate}
					order={order}
					className={cls(style.orderDetails, { [style.fullHeight]: !auth.Me || auth.Me.type === "Consumer" })}
					onTabChange={(tab) => {
						setShowFinalizeButtons(tab === SelectedTab.Booking)
					}}
				/>

				{auth.Me && auth.Me.type === "Client" && showFinalizeButtons ? (
					<div className={cls(style.confirmWrapper, "google-maps-custom-hidden-fullscreen")}>
						<DoneButton disabled={isSubmitting} onClick={onSubmit}>
							Markera som avslutad&nbsp;
							{isSubmitting ? (
								<FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} />
							) : null}
						</DoneButton>
					</div>
				) : null}
			</div>
		</ModulePopup>
	)
}
