import {
	faAngleDown,
	faAngleUp,
	faArrowRightToBracket,
	faDownLeftAndUpRightToCenter,
	faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { exhaustive } from "exhaustive"
import { chain, every, isEqual, isUndefined, map, some } from "lodash"
import { DateTime } from "luxon"
import { Footer } from "Orders/Components/Footer/Footer"
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ReactElement } from "react-markdown/lib/react-markdown"
import { useLocation, useSearchParams } from "react-router-dom"
import { z } from "zod"
import { MeResponseAccountType } from "../Auth/Auth.types"
import { useAuth } from "../Auth/AuthContext"
import { BrandBuilder } from "../Branding/BrandBuilder/BrandBuilder"
import { useClient } from "../Client/ClientAndUserProvider"
import { Demo } from "../Client/Demo/Demo"
import { OrderUILoginPlacement } from "../Client/FeatureTypes"
import { ClientModes } from "../Client/GetClientResponse"
import {
	GetNotification,
	GetNotificationsLimited,
	GetOrderChatMessageCreatedByType,
	NotificationReadStatus,
} from "../CustomerPortal/NotificationsList/NotificationsList"
import { BurgerIcon, KontaktIcon, NotifikationIcon, VarukorgIcon } from "../Icons/Icon"
import { Loader } from "../Loader/Loader"
import { LoglevelSettings } from "../Logging/LoglevelSettings"
import { NavigatorLink } from "../Navigator/NavigatorLink"
import { useNavigator } from "../Navigator/useNavigator"
import { API } from "../network/API"
import { AccentButton } from "../Orders/Components/Form/Buttons/Buttons"
import { HorizontalScrollBox } from "../Orders/Components/HorizontalScrollBox/HorizontalScrollBox"
import { convertTextToInitials } from "../Orders/Helpers"
import { removeModalOpen } from "../Orders/OrderContainer/OrderContainer"
import { PwaInstallPrompt } from "../PwaInstallPrompt/PwaInstallPrompt"
import { cls } from "../Shared/cls"
import { EventQueue } from "../Shared/eventQueue"
import { useBrandedLocalStorage } from "../Shared/useBrandedLocalStorage"
import { Sidebar } from "../Sidebar/Sidebar"
import style from "./MainLayout.module.css"

export type GetNavbarsResponse = {
	navbars: Navbar[]
}

export type Navbar = {
	items: GetNavbarItem[]
	constraints: NavbarConstraints
}

export type NavbarConstraints = {
	allowedLocations: NavbarAllowedLocation[]
	mode: NavbarMode
}

export enum NavbarMode {
	Active = "Active",
	Draft = "Draft",
}

export enum NavbarAllowedLocation {
	OrderStart = "OrderStart",
	OrderCheckout = "OrderCheckout",
	OrderConfirmed = "OrderConfirmed",
	CustomerPortal = "CustomerPortal",
	Pages = "Pages",
	QuantityCalculators = "QuantityCalculators",
}

type GetNavbarItemBase = {
	id: string
	name: string
	imageUrl?: string
}

export type GetParentNavbarItem = GetNavbarItemBase & {
	type: "ParentNavbarItem"
	subItems: GetNavbarItem[]
}

export type GetNavbarLinkWidgetsPage = GetNavbarItemBase & {
	type: "WidgetsPage"
	path: string
}

export type GetNavbarLinkExternal = GetNavbarItemBase & {
	type: "External"
	url: string
	hrefTarget: string
}

export type GetNavbarItem = GetNavbarLinkWidgetsPage | GetNavbarLinkExternal | GetParentNavbarItem

type Props = { children: ReactNode | ReactElement; style?: string }
export const MainLayout: FC<Props> = (props) => {
	const auth = useAuth()
	const client = useClient()
	const navigator = useNavigator()
	const location = useLocation()

	const [, setTick] = useState(0)
	const [showSidebar, setShowSidebar] = useState(false)

	const [queryParams, setQueryParams] = useSearchParams()

	const [navbars, setNavbars] = useState<Navbar[] | null>(null)
	const [openedNavbarDropdown, setOpenedNavbarDropdown] = useState<string | null>(null)
	const navbarDropdown = useRef<HTMLDivElement | null>(null)
	const navbarOpenCallerElement = useRef<HTMLElement | null>(null)
	// for showing/hiding current navbar when scrolling up/down
	const [navbarVisible, setNavbarVisible] = useState(true)

	const [notifications, setNotifications] = useState<GetNotification[] | null>(null)
	const [showNotificationDropdown, setShowNotificationDropdown] = useState<boolean>(false)
	const [showOnlyUnreadNotifications, setShowOnlyUnreadNotifications] = useState<boolean>(true)
	const [unreadAmount, setUnreadAmount] = useState<number>(0)
	const notificationDropdownButton = useRef<HTMLDivElement | null>(null)
	const notificationDropdown = useRef<HTMLDivElement | null>(null)

	const [showUserDropdown, setShowUserDropdown] = useState(false)
	const userDropdown = useRef<HTMLDivElement | null>(null)

	const [showRegularBasket, setShowRegularBasket] = useBrandedLocalStorage("show-regular-basket", z.boolean(), {
		defaultValue: false,
	})

	const [selectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const mainLayoutScrollTopPrevious = useRef(0)
	const scrollDownAmount = useRef(0)
	const scrollUpAmount = useRef(0)
	const scrollAmountThreshold = 100

	const firstLoad = useRef(true)

	const fetchNavbars = useCallback(() => {
		API.getWithRetries<GetNavbarsResponse>(`/pages/navbars-v1/${client.identifier}`, true).then(
			(res) => {
				setNavbars(res.navbars)
			},
			() => {},
		)
	}, [client.identifier])

	const fetchNotifications = useCallback(() => {
		if (!auth.IsLoggedIn || !client.features.notificationsEnabled) {
			return
		}

		const setData = (responseData: GetNotification[]) => {
			// If the data diffs in any way, we update it
			if (!isEqual(responseData, notifications)) {
				setNotifications(responseData)

				// Only notify of new notifications on not-first load (notifications are null on intial load)
				// and only if the id's don't match since the data may have changed in terms of statuses,
				// but it's still the same set of objects
				if (
					notifications !== null &&
					!isEqual(
						map(responseData, (x) => x.id),
						map(notifications, (x) => x.id),
					)
				) {
					document.dispatchEvent(new Event("new-notification-available"))
				}
			}
		}

		if (auth.IsLoggedInClient) {
			API.getWithRetries<GetNotificationsLimited>(
				`/order-ui/notifications-v1/get-notifications-limited-client`,
				true,
			).then(
				(res) => {
					setData(res.notifications)
					setUnreadAmount(res.totalUnread || 0)
				},
				() => {},
			)
		}

		if (auth.IsLoggedInConsumer && selectedConsumerId) {
			API.getWithRetries<GetNotificationsLimited>(
				`/order-ui/notifications-v1/get-notifications-limited-consumer/${selectedConsumerId}`,
				true,
			).then(
				(res) => {
					setData(res.notifications)
					setUnreadAmount(res.totalUnread || 0)
				},
				() => {},
			)
		}
	}, [auth.IsLoggedIn, auth.IsLoggedInClient, auth.IsLoggedInConsumer, notifications, selectedConsumerId])

	useEffect(() => {
		fetchNavbars()
	}, [])

	useEffect(() => {
		if (firstLoad.current) {
			firstLoad.current = false
			return
		}
		if (auth.IsLoggedInClient || !auth.IsLoggedIn) {
			// Fetch specifically when client because when it's a consumer the surrounding consumer context reloads this component
			fetchNavbars()
		}
	}, [auth])

	useEffect(() => {
		document.addEventListener("click", handleClickOutsideDropdown, true)
		return () => {
			document.removeEventListener("click", handleClickOutsideDropdown, true)
		}
	}, [])

	useEffect(() => {
		setNavbarVisible(true)
		setOpenedNavbarDropdown(null)
	}, [location.pathname])

	useEffect(() => {
		const dropdown = navbarDropdown.current
		const callerElement = navbarOpenCallerElement.current
		if (openedNavbarDropdown && dropdown && callerElement) {
			const bodyWidth = document.body.getBoundingClientRect().width
			const callerLeft = callerElement.getBoundingClientRect().left
			const dropdownWidth = dropdown.getBoundingClientRect().width

			// This checks if the dropdown would've been shown outside the right or left side of the screen, and adjusts the position accordingly
			if (bodyWidth - callerLeft < dropdownWidth) {
				dropdown.style.right = "5px"
			} else if (callerElement.getBoundingClientRect().left < 0) {
				dropdown.style.left = "5px"
			} else {
				// removes navbar width to avoid setting the position too far to the right
				const navbarWidth =
					client.features.orderUIShowSidebar && document.body.getBoundingClientRect().width >= 1100 ? 105 : 0
				dropdown.style.left = (callerElement.getBoundingClientRect().left - navbarWidth).toString() + "px"
			}
		}
	}, [openedNavbarDropdown])

	useEffect(() => {
		if (!client.features.notificationsEnabled) {
			return
		}

		fetchNotifications()

		const id = setInterval(() => {
			fetchNotifications()
		}, 5000)

		return () => {
			clearInterval(id)
		}
	}, [fetchNotifications])

	function getCurrentLocation(): NavbarAllowedLocation | null {
		let currentLocation: NavbarAllowedLocation | null = null

		if (location.pathname === `/${client.identifier}/order`) {
			currentLocation = NavbarAllowedLocation.OrderStart
		} else if (location.pathname === `/${client.identifier}/order/checkout`) {
			currentLocation = NavbarAllowedLocation.OrderCheckout
		} else if (location.pathname.startsWith(`/${client.identifier}/order/checkout/completed/`)) {
			currentLocation = NavbarAllowedLocation.OrderConfirmed
		} else if (location.pathname.startsWith(`/${client.identifier}/my-pages/`)) {
			currentLocation = NavbarAllowedLocation.CustomerPortal
		} else if (location.pathname.startsWith(`/${client.identifier}/quantity-calculator`)) {
			currentLocation = NavbarAllowedLocation.QuantityCalculators
		} else if (
			location.pathname.startsWith(`/${client.identifier}/pages/`) ||
			location.pathname.startsWith(`/${client.identifier}/preview-page/`)
		) {
			currentLocation = NavbarAllowedLocation.Pages
		}
		return currentLocation
	}

	const underHeaderDynamicNavbarObject: Navbar | null = useMemo(() => {
		if (!navbars || navbars.length === 0) {
			return null
		}

		const currentLocation = getCurrentLocation()
		let finalNavbar: Navbar | null = null

		if (currentLocation) {
			const matchedNavbars = chain(navbars)
				.filter((x) => x.constraints.allowedLocations.includes(currentLocation))
				.groupBy((x) => x.constraints.mode)
				.value()

			const activeNavbars = matchedNavbars[NavbarMode.Active] || []
			if (auth.Me && auth.Me.type === MeResponseAccountType.Client) {
				const draftNavbars = matchedNavbars[NavbarMode.Draft] || []
				if (draftNavbars.length === 1) {
					finalNavbar = draftNavbars[0] || null
				} else if (draftNavbars.length > 1) {
					if (activeNavbars.length === 1) {
						finalNavbar = activeNavbars[0] || null
					}
				} else {
					if (activeNavbars.length === 1) {
						finalNavbar = activeNavbars[0] || null
					}
				}
			} else {
				if (activeNavbars.length === 1) {
					finalNavbar = activeNavbars[0] || null
				}
			}
		}

		return finalNavbar
	}, [navbars, location.pathname, client.identifier, auth])

	function isOrderPage(): boolean {
		return location.pathname === `/${client.identifier}/order`
	}

	function handleClickOutsideDropdown(event: Event) {
		const headerUserSection = document.getElementById("headerUserSection")
		if (
			userDropdown.current &&
			!userDropdown.current.contains(event.target as Node) &&
			headerUserSection &&
			!headerUserSection.contains(event.target as Node)
		) {
			setShowUserDropdown(false)
		}

		const dropdownWrappers: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
			"dynamicNavbarDropdownButton",
		) as HTMLCollectionOf<HTMLElement>
		if (
			navbarDropdown.current &&
			!navbarDropdown.current.contains(event.target as Node) &&
			every(dropdownWrappers, (x) => !x.contains(event.target as Node))
		) {
			setOpenedNavbarDropdown(null)
		}

		if (
			notificationDropdown.current &&
			!notificationDropdown.current.contains(event.target as Node) &&
			notificationDropdownButton.current &&
			!notificationDropdownButton.current.contains(event.target as Node)
		) {
			setShowNotificationDropdown(false)
		}
	}

	const closeAllModals = () => {
		if (showSidebar) {
			setShowSidebar(false)
		}
		removeModalOpen(queryParams, setQueryParams)
		EventQueue.dispatchEvent("closeAllModals", {})
	}

	function headerRowElement(): JSX.Element {
		return (
			<>
				<div className={style.headerRow}>
					<div className={style.headerRowInnerWrapper}>
						{client.features.orderUIShowSidebar ? (
							<div className={cls(style.headerCell, style.headerSidebarCell)}>
								<div
									id={"mainLayoutHeaderSidebarIcon"}
									onClick={() => {
										setShowSidebar(!showSidebar)
									}}>
									<BurgerIcon className={cls(style.headerSidebarIcon, style.mobileOnly)} size={null} />
									{showSidebar ? (
										<FontAwesomeIcon
											className={cls(style.headerSidebarIcon, style.desktopOnly)}
											icon={faDownLeftAndUpRightToCenter}
										/>
									) : (
										<FontAwesomeIcon
											className={cls(style.headerSidebarIcon, style.desktopOnly)}
											icon={faUpRightAndDownLeftFromCenter}
										/>
									)}
								</div>
							</div>
						) : (
							<div className={style.headerCell} />
						)}
						<div className={cls(style.headerCell, style.headerLogoCell)}>
							<img
								alt={"Kundlogga"}
								src={client.getLogoUrl()}
								height={client.branding.logoHeight}
								onClick={() => {
									closeAllModals()
									navigator.open(`order`)
								}}
							/>
						</div>
						{loginButton()}
						{userDetails()}
						{basketHeaderButton()}
					</div>
				</div>
				{showUserDropdown ? (
					<div className={style.userDropdown} ref={userDropdown}>
						<NavigatorLink
							to={`my-pages/my-account`}
							className={({ isActive }) => {
								return cls(style.userDropdownItem, {
									[style.userDropdownItemActive]: isActive,
								})
							}}
							onClick={() => {
								setShowUserDropdown(false)
							}}>
							<KontaktIcon size={22} />
							<span>Mitt konto</span>
						</NavigatorLink>
						<hr />
						<div
							className={style.userDropdownItem}
							onClick={() => {
								auth.logout(client.identifier)
								navigator.open("order")
								setShowUserDropdown(false)
							}}>
							<FontAwesomeIcon style={{ width: "22px" }} icon={faArrowRightToBracket} />
							<span>Logga ut</span>
						</div>
					</div>
				) : null}
				{underHeaderDynamicNavbar()}
			</>
		)
	}

	function openedDropdownElement(navbar: Navbar) {
		const button = navbar.items.find((x) => x.id === openedNavbarDropdown)

		if (!button || button.type !== "ParentNavbarItem") {
			return null
		}

		return openedNavbarDropdown ? (
			<div className={style.dynamicNavbarDropdown} ref={navbarDropdown}>
				<div className={style.dynamicNavbarDropdownHeader}>
					{button.imageUrl ? (
						<img className={style.dynamicNavbarButtonImage} src={button.imageUrl} alt="" />
					) : null}
					<span className={"oneLineClamp"}>{button.name}</span>
				</div>
				{button.subItems.map((subItem) => {
					return navbarItemToHtml(subItem)
				})}
			</div>
		) : null
	}

	function underHeaderDynamicNavbar(): JSX.Element | null {
		if (!underHeaderDynamicNavbarObject || !navbarVisible) {
			return null
		}

		return (
			<div
				className={cls(
					style.dynamicNavbar,
					{ [style.regularBasketVisible]: showRegularBasket && isOrderPage() },
					{ [style.noSidebar]: !client.features.orderUIShowSidebar },
				)}>
				<span className={style.dynamicNavbarWrapper}>
					<span></span>
					<HorizontalScrollBox
						className={style.dynamicNavbarHorizontalScroll}
						cellClassName={style.dynamicNavbarHorizontalScrollCell}
						key={`dynamicNavbar`}>
						{underHeaderDynamicNavbarObject.items.map((button) => {
							return navbarItemToHtml(button)
						})}
					</HorizontalScrollBox>
				</span>
				{openedDropdownElement(underHeaderDynamicNavbarObject)}
			</div>
		)
	}

	function navbarItemToHtml(button: GetNavbarItem) {
		return exhaustive(button, "type", {
			WidgetsPage: (it) => {
				return (
					<NavigatorLink
						key={button.id}
						to={`pages/${it.path}`}
						end={true}
						className={({ isActive }) => {
							return cls(style.dynamicNavbarButton, {
								[style.dynamicNavbarButtonActive]: isActive,
							})
						}}>
						{button.imageUrl ? (
							<img className={style.dynamicNavbarButtonImage} src={button.imageUrl} alt="" />
						) : null}
						{button.name}
					</NavigatorLink>
				)
			},
			External: (it) => {
				let urlToUse = it.url

				if (!urlToUse.startsWith("http://") && !urlToUse.startsWith("https://")) {
					urlToUse = "https://" + urlToUse
				}

				return (
					<a key={button.id} className={style.dynamicNavbarButton} href={urlToUse} target={it.hrefTarget}>
						{button.imageUrl ? (
							<img className={style.dynamicNavbarButtonImage} src={button.imageUrl} alt="" />
						) : null}
						{it.name}
					</a>
				)
			},
			ParentNavbarItem: (it) => {
				const selectedItem = it.subItems.find((subItem) => {
					return subItem.type === "WidgetsPage" && isCurrentTab(`pages/${subItem.path}`)
				})

				return (
					<div key={it.id} className={style.dynamicNavbarDropdownButtonWrapper}>
						<div
							className={cls("dynamicNavbarDropdownButton", style.dynamicNavbarButton, {
								[style.dynamicNavbarButtonActive]: some(it.subItems, (subItem) => {
									if (subItem.type === "WidgetsPage") {
										return isCurrentTab(`pages/${subItem.path}`)
									}

									return false
								}),
							})}
							onClick={(e) => {
								navbarOpenCallerElement.current = e.currentTarget as HTMLElement
								setOpenedNavbarDropdown((x) => {
									if (x && it.id === x) {
										return null
									}
									return it.id
								})
							}}>
							{(selectedItem ? selectedItem.imageUrl : button.imageUrl) ? (
								<img
									className={style.dynamicNavbarButtonImage}
									src={selectedItem ? selectedItem.imageUrl : button.imageUrl}
									alt=""
								/>
							) : null}
							{selectedItem?.name || it.name}
							{openedNavbarDropdown === it.id ? (
								<FontAwesomeIcon style={{ marginLeft: "auto" }} icon={faAngleUp} />
							) : (
								<FontAwesomeIcon style={{ marginLeft: "auto" }} icon={faAngleDown} />
							)}
						</div>
					</div>
				)
			},
		})
	}

	function loginButton() {
		if (auth.IsLoggedIn) {
			return null
		}

		if (
			!client.features.orderUiAllowLoggingIn ||
			client.features.orderUiLoginPlacement !== OrderUILoginPlacement.HeaderRight
		) {
			return <div className={cls(style.headerCell, style.headerLoginButtonCell)}></div>
		}

		return (
			<div className={cls(style.headerCell, style.headerLoginButtonCell)}>
				<div
					className={cls(style.loginButton)}
					onClick={() => {
						EventQueue.dispatchEvent("openLoginModal", { redirectTo: "order" })
					}}>
					<span className={style.desktopOnly} style={{ fontWeight: 600 }}>
						Logga in
					</span>
					<FontAwesomeIcon icon={faArrowRightToBracket} />
				</div>
			</div>
		)
	}

	function userDetails(): JSX.Element | null {
		if (!auth.IsLoggedIn || !auth.Me) {
			return null
		}

		return (
			<div className={cls(style.headerCell, style.headerUserDetailsCell)}>
				{userNotificationElement()}
				<div
					id="headerUserSection"
					className={style.userSection}
					onClick={() => setShowUserDropdown(!showUserDropdown)}>
					<div className={style.profilePic} style={{ alignSelf: "center" }}>
						{auth.Me?.profilePicture ? (
							<img
								src={auth.Me.profilePicture}
								onError={() => {
									if (auth.Me) {
										auth.Me.profilePicture = undefined
									}
									setTick((tick) => tick + 1)
								}}
								alt="Profilbild"
							/>
						) : (
							<div className={style.noProfilePic}>
								{isUndefined(auth.Me.firstName) && isUndefined(auth.Me.lastName) ? null : (
									<span style={{ color: "#fff" }}>
										{convertTextToInitials(auth.Me.firstName + " " + auth.Me.lastName)}
									</span>
								)}
							</div>
						)}
					</div>
					<div className={style.userDetails}>
						<div className={"twoLineClamp"} style={{ wordBreak: "break-word" }}>
							{auth.Me.type === MeResponseAccountType.Client
								? client.clientInfo.clientName
								: auth.Me.consumers.find((x) => x.id === selectedConsumerId)?.name}
						</div>

						<div style={{ fontWeight: 600 }} className={"oneLineClamp"}>
							{auth.Me.firstName || auth.Me.lastName
								? `${auth.Me.firstName} ${auth.Me.lastName}`
								: auth.Me.username}
						</div>
					</div>
					{showUserDropdown ? (
						<FontAwesomeIcon className={style.userDropdownToggle} icon={faAngleUp} />
					) : (
						<FontAwesomeIcon className={style.userDropdownToggle} icon={faAngleDown} />
					)}
				</div>
			</div>
		)
	}

	async function markNotificationAsRead(notificationId: string) {
		return API.post<void, {}>(`/order-ui/notifications-v1/mark-as-read/${notificationId}`, {})
	}

	function userNotificationElement() {
		if (!auth.IsLoggedIn || !auth.Me || !client.features.notificationsEnabled) {
			return null
		}

		return (
			<>
				<div
					ref={notificationDropdownButton}
					className={style.notificationWrapper}
					onClick={() => {
						setShowNotificationDropdown((x) => !x)
					}}>
					<NotifikationIcon className={style.notificationIcon} size={45}></NotifikationIcon>
					<span
						className={style.notificationCounter}
						style={{ fontSize: unreadAmount > 99 ? "12px" : undefined }}>
						{unreadAmount > 99 ? "99+" : unreadAmount}
					</span>
				</div>
				{userNotificationDropdown()}
			</>
		)
	}

	function onNotificationClick(notification: GetNotification) {
		let urlParams

		// If we're on the orders page, keep whatever url params are set, to not navigate user
		// away from whatever page or filters etc., that they've set
		if (location.pathname.startsWith(`/${client.identifier}/my-pages/orders`)) {
			urlParams = new URLSearchParams(window.location.search)
		} else {
			urlParams = new URLSearchParams()
		}

		urlParams.set("order", notification.orderId)
		urlParams.set("tabId", "Activity")
		const newParams = Array.from(urlParams.entries()).reduce((previousValue, currentValue) => {
			if (previousValue) {
				return `${previousValue}&${currentValue[0]}=${currentValue[1]}`
			}
			return `${currentValue[0]}=${currentValue[1]}`
		}, "")
		markNotificationAsRead(notification.id).then(
			() => {
				setShowNotificationDropdown(false)
				navigator.open(`/${notification.clientIdentifier}/my-pages/orders?${newParams}`)
			},
			() => {},
		)
	}

	function userNotificationDropdown() {
		if (!showNotificationDropdown) {
			return null
		}

		if (!notifications) {
			return (
				<div className={style.notificationDropdown} ref={notificationDropdown}>
					<div style={{ margin: "0 auto" }}>
						<Loader />
					</div>
				</div>
			)
		}

		return (
			<div className={style.notificationDropdown} ref={notificationDropdown}>
				<div className={style.notificationDropdownHeader}>Notifikationer</div>
				<div className={style.filterButtons}>
					<AccentButton
						className={cls({ [style.selectedFilterButton]: !showOnlyUnreadNotifications })}
						onClick={() => {
							setShowOnlyUnreadNotifications(false)
						}}>
						Alla
					</AccentButton>
					<AccentButton
						className={cls({ [style.selectedFilterButton]: showOnlyUnreadNotifications })}
						onClick={() => {
							setShowOnlyUnreadNotifications(true)
						}}>
						Olästa
					</AccentButton>
				</div>
				<div
					style={{
						display: "flex",
						marginBottom: "5px",
						justifyContent: "space-between",
						paddingLeft: "10px",
						fontWeight: "600",
					}}>
					<div>Nya</div>
					<div
						className={style.showAllNotificationsLink}
						onClick={() => {
							setShowNotificationDropdown(false)
							navigator.open("my-pages/notifications")
						}}>
						Visa alla
					</div>
				</div>
				<div className={style.notificationsList}>
					{notifications.length === 0 ? (
						<div style={{ margin: "10px auto 30px auto" }}>
							<strong>Det finns inga notiser att se för tillfället</strong>
						</div>
					) : null}
					{notifications
						.filter((x) => {
							if (showOnlyUnreadNotifications) {
								return x.readStatus === NotificationReadStatus.Unread
							}
							return true
						})
						.map((notification) => {
							return (
								<div
									id={`notification_${notification.id}`}
									className={style.notification}
									key={notification.id}
									onClick={() => {
										const element = document.getElementById(`notification_${notification.id}`)
										if (element) {
											element.classList.add("notification-clicked")

											setTimeout(() => {
												element?.classList?.remove("notification-clicked")
											}, 250)
										}

										onNotificationClick(notification)
									}}>
									{notificationContent(notification)}
								</div>
							)
						})}
				</div>
			</div>
		)
	}

	function notificationContent(notification: GetNotification): JSX.Element {
		return exhaustive(notification, "type", {
			OrderChatMessage: (it) => {
				return (
					<>
						<div className={style.notificationHeader}>
							<span className="oneLineClamp">
								{it.createdBy.type === GetOrderChatMessageCreatedByType.Client ? (
									<>
										{client.clientInfo.clientName}, {it.createdBy.name}
									</>
								) : (
									<>
										{it.consumerName}, {it.createdBy.name}
									</>
								)}
							</span>
						</div>
						<div className={style.notificationMessageContent}>
							<div className={style.notificationMessageContentText}>
								<span className="twoLineClamp">har lämnat ett meddelande: "{it.truncatedMessage}"</span>
							</div>
							{it.readStatus === NotificationReadStatus.Unread ? (
								<div className={style.notificationMessageContentUnreadMarker}></div>
							) : null}
						</div>
						<div className={style.notificationMarkingAndDate}>
							<div className={style.marking}>{it.orderMarking}</div>
							<div className={style.createdAt}>
								{DateTime.fromSeconds(it.createdAt)
									.setLocale("sv-SE")
									.toLocaleString(DateTime.DATETIME_MED)}
							</div>
						</div>
					</>
				)
			},
		})
	}

	function basketHeaderButton(): JSX.Element | null {
		if (!isCurrentTab("order") || Object.keys(client.categories).length === 0 || showRegularBasket) {
			return null
		}

		return (
			<div className={cls(style.headerCell, style.headerBasketButtonCell)}>
				<div
					className={cls(style.headerBasketButton, style.desktopOnly)}
					onClick={() => {
						setShowRegularBasket(true)
					}}>
					<span>Varukorg</span>
					<VarukorgIcon size={25} iconClassName={style.basketIcon} />
				</div>
			</div>
		)
	}

	function isCurrentTab(path: string): boolean {
		const _path = "/" + client.identifier + "/" + path
		return location && location.pathname === _path
	}

	return (
		<main className={style.main}>
			{client.features.orderUIShowSidebar ? (
				<Sidebar
					display={showSidebar}
					onHide={() => {
						setShowSidebar(false)
					}}
					onShow={() => {
						setShowSidebar(true)
					}}
				/>
			) : null}
			{headerRowElement()}
			<div
				id="mainLayoutPageContent"
				className={cls(
					props.style,
					{
						[style.dynamicNavbarOffset]: underHeaderDynamicNavbarObject !== null && navbarVisible,
					},
					{ [style.regularBasketVisible]: showRegularBasket && isOrderPage() },
				)}
				onScroll={(e) => {
					const element = e.target as HTMLDivElement
					if (document.body.getBoundingClientRect().width < 1100) {
						if (element.scrollTop > mainLayoutScrollTopPrevious.current) {
							// scrolling down
							scrollUpAmount.current = 0
							scrollDownAmount.current += element.scrollTop - mainLayoutScrollTopPrevious.current
							if (scrollDownAmount.current > scrollAmountThreshold) {
								setNavbarVisible(false)
							}
						} else if (element.scrollTop < mainLayoutScrollTopPrevious.current) {
							// scrolling up
							scrollDownAmount.current = 0
							scrollUpAmount.current += mainLayoutScrollTopPrevious.current - element.scrollTop
							if (scrollUpAmount.current > scrollAmountThreshold) {
								setNavbarVisible(true)
							}
						}
					}
					mainLayoutScrollTopPrevious.current = element.scrollTop
				}}>
				<span>
					<span className={style.minWidthWrapper}>{props.children}</span>
				</span>
				<Footer
					className={cls(style.footer, style.footerDesktopHiddenLogo, {
						[style.mobileShowBasketButtonFooterMargin]: isCurrentTab("order"),
					})}
				/>
			</div>
			{client.hasMode(ClientModes.Demo) ? <Demo /> : null}
			{window.localStorage.getItem("iam") === "niclas" ? <BrandBuilder /> : null}
			{window.localStorage.getItem("loginator") === "3000" ? <LoglevelSettings /> : null}
			<PwaInstallPrompt />
		</main>
	)
}
