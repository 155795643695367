import { UniqueArticleTreeId } from "./articleTrees/ArticleTreeDataModel"

export type ConsumerCatalogConfiguration = PrepayConfiguration | ContractConfiguration // | InvoiceConfiguration

/**
 * Payment is required before an order is placed, or before it is executed, using one of the available providers.
 */
export type PrepayConfiguration = {
	type: "Prepay"
	inherit: InheritSettings
	articleTreeIds: UniqueArticleTreeId[]
	prepayProviders: PrepayProviders[]
	missingPricePolicy: MissingPricePolicy
	preferredVATRenderPolicy: PreferredVATRenderPolicy
	//PrepayRequirement: PrepayRequirement //This is somewhat dependent on the provider, too early to add it now
	//catalogRef: string //Too early to add
}

/**
 * Payment will be done via invoicing.
 */
// export type InvoiceConfiguration = {
// 	type: "Invoice"
// 	inherit: InheritSettings
// 	articleTreeIds: UniqueArticleTreeId[]
// 	showPrices: ShowPrices
// 	invoiceMarking: InvoiceMarking
// 	catalogRef: string
// }

/**
 * The legacy option, no price settings, the clients are to use the "good ol' what-ever-they-used-before".
 */
export type ContractConfiguration = {
	type: "Contract"
}

export enum InheritSettings {
	/**
	 * Merge with settings from previous levels.
	 */
	Merge = "Merge",

	/**
	 * Overwrite settings frm previous levels.
	 */
	Overwrite = "Overwrite",
}

export enum PrepayProviders {
	Klarna = "Klarna",
}

//This is commented out because it's a future idea temporary added to the code base to give the typing some context.
// enum PrepayRequirement {
// 	/**
// 	 * Payment is required before the order is sent from Order-UI to Order-Service.
// 	 */
// 	BeforePlacement = "BeforePlacement",
//
// 	/**
// 	 * Payment is required before the order is accepted.
// 	 */
// 	BeforeAcceptance = "BeforeAcceptance",
// }
//
// type InvoiceMarking = UseProjectMarking | StringInvoiceMarking
//
// type UseProjectMarking = {
// 	type: "UseProject"
// }
//
// export type StringInvoiceMarking = {
// 	type: "StringInvoice"
// 	marking: string
// }
//
// enum ShowPrices {
// 	/**
// 	 * Show prices for all users.
// 	 */
// 	All = "All",
//
// 	/**
// 	 * Hide the prices for anyone but the responsible users for the current entity.
// 	 */
// 	OnlyResponsible = "OnlyResponsible",
// }

export enum MissingPricePolicy {
	/**
	 * Hide if price is missing.
	 */
	Hide = "Hide",

	/**
	 * Show a "call us indicator".
	 */
	ShowCallUs = "ShowCallUs",
}

export enum PreferredVATRenderPolicy {
	IncludeVAT = "IncludeVAT",
	ExcludeVAT = "ExcludeVAT",
}
