import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { isPlainObject, isString } from "lodash"
import { FC, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { MeResponseConsumerData } from "../../Auth/Auth.types"
import { SelectedConsumerUserSchema } from "../../Client/ConsumerContext"
import { optionalTextInput } from "../../ClientPortal/ClientPortalNewConsumerModal/ClientPortalNewConsumerModal"
import consumerInformationStyle from "../../CustomerPortal/CustomerPortalConsumerInformation/CustomerPortalConsumerInformation.module.css"
import { GetUser } from "../../CustomerPortal/Users/Users"
import { cls } from "../../Shared/cls"
import { FieldModuleBox } from "../Components/FieldModuleBox/FieldModuleBox"
import { FieldModuleBoxWrapper } from "../Components/FieldModuleBox/FieldModuleBoxWrapper"
import { FinalizeButton } from "../Components/Form/Buttons/Buttons"
import { ModulChooserButton } from "../Components/ModulChooserButton/ModulChooserButton"
import { ModulePopup, removeModalOpenClass } from "../Components/ModulePopup/ModulePopup"
import orderConfirmStyle from "../Components/OrderConfirmCustomerInformation/OrderConfirmCustomerInformation.module.css"
import { MbtH5 } from "../Components/Text/MbtH5/MbtH5"
import { SbtH4 } from "../Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../Components/Text/SbtInvalid/SbtRHFError"
import { convertTextToInitials } from "../Helpers"
import { invalidPhoneNumberMessage, validatePhoneNumber } from "../order-data-model"
import style from "./ClientSelectActiveConsumerUserModal.module.css"

export const SelectedConsumerUserFormSchema = z.object({
	id: z.string().optional(),
	name: z.string().min(1),
	email: optionalTextInput(z.string().email(), "Felaktigt format på e-posten"),
	phone: z
		.string()
		.optional()
		.transform((x) => (!x ? null : x))
		.refine(
			(data) => {
				if (data === null) {
					return true
				}
				return validatePhoneNumber(data)
			},
			{ message: invalidPhoneNumberMessage },
		),
})

export type SelectedConsumerUser = z.input<typeof SelectedConsumerUserSchema>
export type SelectedConsumerUserForm = z.input<typeof SelectedConsumerUserFormSchema>

export type ClientSelectActiveConsumerUserModalProps = {
	consumer: MeResponseConsumerData
	selectedUser: string | SelectedConsumerUser | null
	users: GetUser[]
	onClose: () => void
	onDone: (user: string | SelectedConsumerUser) => void
}

export const ClientSelectActiveConsumerUserModal: FC<ClientSelectActiveConsumerUserModalProps> = ({
	consumer,
	selectedUser: defaultSelectedUser,
	users,
	onClose: extOnClose,
	onDone: extOnDone,
}) => {
	const [selectedUser, setSelectedUser] = useState<string | SelectedConsumerUser | null>(defaultSelectedUser)
	const [isTempUser, setIsTempUser] = useState(isPlainObject(selectedUser))
	const [, setTick] = useState(0)

	const {
		register,
		formState: { errors, isValid },
		reset,
		getValues,
	} = useForm<SelectedConsumerUserForm>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(SelectedConsumerUserFormSchema)(data, context, options)
		},
		defaultValues: isPlainObject(defaultSelectedUser)
			? {
					name: (defaultSelectedUser as SelectedConsumerUser).name,
					email: (defaultSelectedUser as SelectedConsumerUser).email,
					phone: (defaultSelectedUser as SelectedConsumerUser).phone,
			  }
			: undefined,
	})

	function onClose() {
		removeModalOpenClass()
		extOnClose()
	}

	function onDone() {
		if (!selectedUser) {
			return
		}

		onClose()
		extOnDone(selectedUser)
	}

	function tempUserFields(): JSX.Element {
		return (
			<>
				<form
					className={style.tempUserForm}
					onChange={() => {
						setSelectedUser({
							name: getValues("name"),
							phone: getValues("phone"),
							email: getValues("email"),
						})
					}}>
					<label>
						<SbtH4>Namn*</SbtH4>
						<input className={style.input} {...register("name")} placeholder="Anders Andersson" />
						<SbtRHFError error={errors.name} />
					</label>
					<label>
						<SbtH4>Telefonnummer</SbtH4>
						<input className={style.input} {...register("phone")} placeholder="070-0000000" />
						<SbtRHFError error={errors.phone} />
					</label>
					<label>
						<SbtH4>E-post</SbtH4>
						<input className={style.input} {...register("email")} placeholder="exempel@exempel.se" />
						<SbtRHFError error={errors.email} />
					</label>
					<span style={{ fontSize: "16px", color: "var(--section-background-text-color)" }}>
						För att en bekräftelse ska skickas ut behövs en e-post
					</span>
				</form>
			</>
		)
	}

	function regularUsersFields(): JSX.Element {
		if (users.length === 0) {
			return (
				<div style={{ marginTop: "20px", color: "var(--section-background-text-color)" }}>
					Här finns det inga användare att se än
				</div>
			)
		}

		return (
			<>
				<FieldModuleBoxWrapper className={style.fields}>
					{users.map((consumerUser) => {
						const selected = isString(selectedUser) && selectedUser === consumerUser.id
						const name =
							consumerUser.firstName || consumerUser.lastName
								? `${consumerUser.firstName} ${consumerUser.lastName}`
								: consumerUser.username
						return (
							<FieldModuleBox className={style.item} key={consumerUser.id} selected={selected}>
								<div className={style.itemContent}>
									<span className={style.profilePicture}>
										{consumerUser.profilePictureUrl ? (
											<img
												src={consumerUser.profilePictureUrl}
												onError={() => {
													consumerUser.profilePictureUrl = undefined
													setTick((tick) => tick + 1)
												}}
												alt=""
											/>
										) : (
											<AbsolutCentered>
												<span style={{ color: "white" }}>{convertTextToInitials(name)}</span>
											</AbsolutCentered>
										)}
									</span>
									<div style={{ wordBreak: "break-all" }}>
										<MbtH5>{name}</MbtH5>
										<MbtH5 styles={{ fontWeight: 300, fontSize: "16px" }}>{consumerUser.email}</MbtH5>
										<MbtH5 styles={{ fontWeight: 300, fontSize: "16px" }}>
											{consumerUser.phoneNumber}
										</MbtH5>
									</div>
								</div>
								<ModulChooserButton
									selected={selected}
									onClick={() => {
										reset()
										setSelectedUser(consumerUser.id)
									}}>
									{selected ? "Vald" : "Välj"}
								</ModulChooserButton>
							</FieldModuleBox>
						)
					})}
				</FieldModuleBoxWrapper>
				<span style={{ fontSize: "16px", color: "var(--section-background-text-color)" }}>
					Användare hanteras under kundens konto
				</span>
			</>
		)
	}

	function disableSubmit(): boolean {
		if (isTempUser) {
			return !isValid
		} else {
			return !selectedUser
		}
	}

	return (
		<>
			<ModulePopup className={style.modal} onClose={onClose}>
				<div className={style.wrapper}>
					<div className={style.header}>Välj beställare</div>
					<div style={{ display: "flex", gap: "25px", marginTop: "30px" }}>
						<div className={cls(consumerInformationStyle.profilePic, style.consumerProfilePicture)}>
							{consumer.profilePicture ? (
								<img
									src={consumer.profilePicture}
									onError={() => {
										consumer.profilePicture = undefined
										setTick((tick) => tick + 1)
									}}
									alt="Profilbild"
								/>
							) : (
								<div className={consumerInformationStyle.noProfilePic}>
									<span>{convertTextToInitials(consumer.name)}</span>
								</div>
							)}
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								color: "var(--section-background-text-color)",
							}}>
							<div style={{ fontSize: "22px" }}>{consumer.name}</div>
							<div>{consumer.identifier}</div>
						</div>
					</div>
					<div style={{ display: "flex", marginTop: "20px" }}>
						<div className={orderConfirmStyle.btnContainer} style={{ width: "auto" }}>
							<label className={style.btnTempUserSwitch}>
								<input
									type="checkbox"
									name="individualOrCompanyFilter"
									id="individualOrCompanyFilter"
									value="1"
									defaultChecked={isTempUser}
									onChange={(x) => {
										setIsTempUser(x.target.checked)
										setSelectedUser(null)
									}}
								/>
								<label
									style={{ cursor: "pointer" }}
									htmlFor="individualOrCompanyFilter"
									data-on="Temporär beställare"
									data-off="Användare"
									className={style.btnTempUserSwitchInner}
								/>
							</label>
						</div>
					</div>
					{isTempUser ? tempUserFields() : regularUsersFields()}
					<FinalizeButton disabled={disableSubmit()} className={style.finalize} onClick={() => onDone()}>
						Klar
					</FinalizeButton>
				</div>
			</ModulePopup>
		</>
	)
}
