import { ProductDimensions, ProductImageType, ProductType, WasteId, WasteType } from "ProductDefinitions"
import { Widget } from "../Shared/Widget/Widgets"
import { GoodsProductCategoryInstance, WasteProductCategoryInstance } from "./ClientInstance"
import { TransportationDetails } from "./TransportationDetails"

export type ProductDefinitionsByCategories = {
	categories: ProductCategory[]
	possibleWasteTypes: { [key: WasteId]: WasteType }
	possibleTimeSlots: { [key: TimeSlotId]: ProductTimeSlot }
	possibleDateSlots?: { [key: DateSlotId]: ProductDateSlot }
	possibleTransportations: { [key: TransportationId]: ProductTransportation }
	possiblePackagingMethods: { [key: PackagingMethodId]: PackagingMethod }
}
export type PackagingMethodId = string

export type TimeSlotId = string
export type DateSlotId = string

export type ProductTimeSlot = {
	id: string
	name: string
	specificTime: boolean
	description?: string
	order: number
}

export type ProductDateSlot = {
	id: DateSlotId
	name: string
	fromDays: number
	toDays: number
	description?: string
	settings: ProductDateSlotSettings
	order: number
}

export type ProductDateSlotSettings = {
	skipSaturdayAndSunday: boolean
	exactDate: boolean
}

export type ProductCategoryId = string & { isProductCategoryId: true }

export type ProductCategory = WasteProductCategory | GoodsProductCategory

export type WasteProductCategory = {
	type: "WasteCategory"
	id: ProductCategoryId
	name: string
	image: CategoryImageDeprecated | undefined
	categoryImages: CategoryImages | undefined
	subCategories: SubCategory[] | undefined
	services: ProductService[]
	products: WasteProductDefinition[]
	brandColorOverrides: BrandOverrides
	logoOverride?: string
	productSelectionConfig?: GetWasteProductSelectionConfig
	order: number
}

export type GoodsProductCategory = {
	type: "GoodsCategory"
	id: ProductCategoryId
	name: string
	image: CategoryImageDeprecated | undefined
	categoryImages: CategoryImages | undefined
	subCategories: SubCategory[] | undefined
	products: GoodsProductDefinition[]
	productSelectionConfig?: GetGoodsProductSelectionConfig
	order: number
}

export type ProductDefinition = WasteProductDefinition | GoodsProductDefinition

export type GoodsProductDefinition = {
	type: "GoodsProductDefinition"
	id: string
	name: string
	images: ProductImages
	dimensions: ProductDimensions
	order: number
	description: string
	secondDescription: string
	packagings: ProductPackagingWithMethod[]
	tags: string[] | undefined
	subCategories: SubCategoryId[] | undefined
	productCustomizations?: ProductDefinitionCustomizations
} & {
	categoryName: string // used internally, not part of BE data
	category: GoodsProductCategoryInstance
}

export type WasteProductDefinition = {
	type: "WasteProductDefinition"
	id: string
	name: string
	images: ProductImages
	dimensions: ProductDimensions
	allowedWaste: WasteId[]
	transportation: { [key: ProductServiceId]: TransportationId }
	order: number
	description?: string
	secondDescription?: string
	tags: string[] | undefined
	subCategories: SubCategoryId[] | undefined
	productCustomizations?: ProductDefinitionCustomizations
} & {
	categoryName: string // used internally, not part of BE data
	category: WasteProductCategoryInstance
}

export type ProductDefinitionCustomizations = {
	hideTransportationTerms: boolean
}

export type BrandOverrides = {
	productBaseColor?: string
	productSecondaryColor?: string
	productDetailsColor?: string
}

export type CategoryImageDeprecated = {
	productType: ProductType
}

export type ProductService = {
	id: ProductServiceId
	name: string
	timeSlots: TimeSlotId[]
	dateSlots: DateSlotId[]
	quantityType: ProductServiceQuantityType
	unit: ProductServiceUnit
}

export type ProductServiceId = string

export enum ProductServiceQuantityType {
	Waste = "Waste",
	Amount = "Amount",
}

export type TransportationId = string

export type ProductTransportation = {
	id: TransportationId
	name: string
	transportationDetails: TransportationDetails
	constraints: ProductTransportationConstraint
}

export type ProductTransportationConstraint = {
	max: number
	maxDiscreteProducts?: number
}

export type ProductImages = {
	main: ProductImage
	details: ProductImage[]
}

export type ProductImage = ProductImageTyped | ProductImageUrl

export enum ImageType {
	Typed = "Typed",
	Url = "Url",
}

export type ProductImageTyped = {
	type: ImageType.Typed
	typeImage: ProductImageType
}

export type ProductImageUrl = {
	type: ImageType.Url
	url: string
	renderMode: ImageRenderMode
}

export enum ImageRenderMode {
	Fill = "Fill",
	Fit = "Fit",
}

export enum ProductServiceUnit {
	Piece = "Piece",
	MetricTon = "MetricTon",
	Litre = "Litre",
	CubicMeter = "CubicMeter",
}

export type PackagingMethodVisualization =
	| PackagingMethodVisualizationNumeric
	| PackagingMethodVisualizationIncrementor
	| PackagingMethodVisualizationDropdown

export type PackagingMethodVisualizationNumeric = {
	type: "Numeric"
}

export type PackagingMethodVisualizationIncrementor = {
	type: "Incrementor"
}

export type PackagingMethodVisualizationDropdown = {
	type: "Dropdown"
}

export type ProductPackagingWithMethod = {
	type: "ProductPackagingWithMethod"
	packagingMethodId: PackagingMethodId
}

export type PackagingMethod = {
	id: PackagingMethodId
	name: string
	description: string
	multiplier: number
	productUnit: ProductServiceUnit
	visualization: PackagingMethodVisualization
	timeSlotIds: PackagingMethodId[]
	dateSlotIds?: string[]
	transportationId: TransportationId
	measurement?: QuantityValue
}

export type ValueTolerance = {
	type: "Value"
	plus: number
	minus: number
}

export type PercentTolerance = {
	type: "Percent"
	plus: number
	minus: number
}

export type Tolerance = ValueTolerance | PercentTolerance

export enum LengthUnits {
	Meter = "Meter",
}

export enum VolumeUnits {
	Liter = "Liter",
	CubicMeter = "CubicMeter",
}

export enum MassUnits {
	Kilogram = "Kilogram",
}

export enum DensityUnits {
	KilogramPerCubicMeter = "KilogramPerCubicMeter",
}

export type LengthValue = {
	type: "Length"
	length: number
	tolerance?: Tolerance
	displayUnit?: LengthUnits
}

export type VolumeValue = {
	type: "Volume"
	volume: number
	tolerance?: Tolerance
	displayUnit?: VolumeUnits
}

export type MassValue = {
	type: "Mass"
	mass: number
	tolerance?: Tolerance
	displayUnit?: MassUnits
}

export type DensityValue = {
	type: "Density"
	density: number
	tolerance?: Tolerance
	displayUnit?: DensityUnits
}

export type QuantityValue = LengthValue | VolumeValue | MassValue | DensityValue

export type SubCategoryId = number & { isSubCategoryId: true }

export type SubCategory = GenericSubCategory

export type GenericSubCategory = {
	type: "Generic"
	id: SubCategoryId
	title: string
	categoryImages: CategoryImages | undefined
}

export type CategoryImages = {
	main: CategoryImage
}

export type CategoryImage = CategoryImageUrl | CategoryImageTyped

export type CategoryImageUrl = {
	type: "Url"
	url: string
	renderMode: ImageRenderMode
}

export type CategoryImageTyped = {
	type: "Typed"
	typeImage: ProductType
}

export type GetWasteProductSelectionConfig = {
	type: "Waste"
	stepsTitle: string
	steps: ProductSelectionConfigStep[]
	widgets: GetProductSelectionConfigWidget[]
}

export type GetGoodsProductSelectionConfig = {
	type: "Goods"
	widgets: GetProductSelectionConfigWidget[]
	title?: string
	subTitle?: string
}

export type ProductSelectionConfigStep = {
	id: string
	name: string
	services?: string[]
	title: string
	secondaryStepsTitle?: string
	secondarySteps?: ProductSelectionConfigSecondaryStep[]
}

export type ProductSelectionConfigSecondaryStepBase = {
	id: string
	name: string
	title: string
	subTitle: string
}

export type ProductSelectionConfigSecondaryStep =
	| ProductSelectionConfigSecondaryStepService
	| ProductSelectionConfigSecondaryStepNoAction

export type ProductSelectionConfigSecondaryStepService = ProductSelectionConfigSecondaryStepBase & {
	type: "Service"
	serviceId: string
}

export type ProductSelectionConfigSecondaryStepNoAction = ProductSelectionConfigSecondaryStepBase & {
	type: "NoAction"
}

export type GetProductSelectionConfigWidget = {
	title: string
	widget: Widget
}
