import {
	ArticleTree,
	ArticleTreeType,
	Branch,
	BranchCondition,
	BranchTargetKeyEnum,
	Leaf,
	TreeSearchObject,
	TypedLeaf,
} from "Client/articleTrees/ArticleTreeDataModel"
import { exhaustive } from "exhaustive"
import { cloneDeep, isEmpty } from "lodash"
import { notNull } from "Shared/notNull"
import { when } from "Shared/when"
import { ArticlePath, ArticlePathStep } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { getLogger } from "../../Logging/getLogger"
import { lets } from "../../Shared/lets"

const logger = getLogger("ArticleTreeResolver")

export enum LeafOrigin {
	DiscountCode = "DiscountCode",
	Regular = "Regular",
}
/**
 * Helper/Service used to resolve Articles from an ArticleTree.
 * This is a direct port of the corresponding BE classes.
 */
export class ArticleResolverService {
	static getAllArticlesFromTree(articleTree: ArticleTree): ResolvedLeaf[] {
		return this.traversAllBranches(articleTree.branches, ResolvedArticlePath.ofTree(articleTree), articleTree.treeType)
	}

	private static traversAllBranches(
		branches: Branch[] | null,
		path: ResolvedArticlePath,
		treeType: ArticleTreeType,
	): ResolvedLeaf[] {
		return (
			branches?.flatMap((branch) => {
				const currentPath = path.plus(ResolvedArticlePathStep.ofBranch(branch))
				if (isEmpty(branch.branches)) {
					if (branch.typedLeaf != null) {
						logger.debug(`No more branches at $currentPath, getting TypedLeaf: ${branch.leaf}`)
						return [new ResolvedTypedLeaf(currentPath, branch.typedLeaf)]
					} else if (branch.leaf) {
						logger.debug(
							`No more branches at ${JSON.stringify(currentPath)}, getting leaf: ${JSON.stringify(
								branch.leaf,
							)}`,
						)
						return [new ResolvedArticle(currentPath, branch.leaf)]
					} else {
						logger.warn(`No leaf found att end of branch with path: ${JSON.stringify(currentPath)}`)
						return []
					}
				} else {
					return this.traversAllBranches(branch?.branches, currentPath, treeType)
				}
			}) ?? []
		)
	}

	static resolveAllLeafs(
		searchObject: TreeSearchObject,
		articleTrees: ArticleTree[],
		discountTemplateId: string | null,
	): ResolvedLeafWithTree[] {
		return articleTrees
			.map((tree) => {
				let resolvedLeaf = this.resolveArticleFromTree(searchObject, tree)
				if (resolvedLeaf == null) return null
				const leafOrigin = lets(discountTemplateId, (it) => {
					if (resolvedLeaf instanceof ResolvedTypedLeaf) {
						if (
							resolvedLeaf.articlePath.findStep(BranchTargetKeyEnum.DiscountTemplate)?.condition?.value === it
						) {
							return LeafOrigin.DiscountCode
						}
					}
					if (resolvedLeaf instanceof ResolvedArticle) {
						if (
							resolvedLeaf.articlePath.findStep(BranchTargetKeyEnum.DiscountTemplate)?.condition?.value === it
						) {
							return LeafOrigin.DiscountCode
						}
					}
					return LeafOrigin.Regular
				})

				return new ResolvedLeafWithTree(resolvedLeaf, tree, leafOrigin ?? LeafOrigin.Regular)
			})
			.filter(notNull)
	}

	static resolveArticleFromTree(searchObject: TreeSearchObject, articleTree: ArticleTree): ResolvedLeaf | null {
		logger.debug(`Searching in '${articleTree.name}', after:`, cloneDeep(searchObject))
		return this.searchInBranches(
			searchObject,
			articleTree.branches,
			ResolvedArticlePath.ofTree(articleTree),
			articleTree.treeType,
		)
	}

	private static searchInBranches(
		searchObject: TreeSearchObject,
		branches: Branch[] | null,
		path: ResolvedArticlePath,
		treeType: ArticleTreeType,
	): ResolvedLeaf | null {
		const foundBranch = this.findValidBranch(searchObject, branches)

		if (!foundBranch) {
			logger.debug(
				`No more branches found current path: '${JSON.stringify(path)}', Search: ${JSON.stringify(searchObject)}`,
			)
			return null
		} else {
			const currentPath = path.plus(ResolvedArticlePathStep.ofBranch(foundBranch))
			if (isEmpty(foundBranch.branches)) {
				if (foundBranch.typedLeaf != null) {
					if (foundBranch.leaf != null) {
						logger.warn(
							`Typed Leaf! Contains legacy leaf as well! Legacy leaf is ignored. Path: '$currentPath', Typed Leaf: ${foundBranch.typedLeaf}, Leaf: ${foundBranch.leaf}`,
						)
					}

					logger.debug(`Found Typed Leaf! Path: '$currentPath', TypedLeaf: ${foundBranch.typedLeaf}`)
					return new ResolvedTypedLeaf(currentPath, foundBranch.typedLeaf)
				}

				if (foundBranch.leaf) {
					logger.debug(
						`Found Leaf! Path: '${JSON.stringify(currentPath)}', Leaf: ${JSON.stringify(foundBranch.leaf)}`,
					)
					return new ResolvedArticle(currentPath, foundBranch.leaf)
				}

				logger.info(`No Leaf Found on Path: '${currentPath.asShortPath()}'`)
				return null
			} else {
				return this.searchInBranches(searchObject, foundBranch.branches, currentPath, treeType)
			}
		}
	}

	private static findValidBranch(searchObject: TreeSearchObject, branches: Branch[] | null): Branch | null {
		return (
			branches?.find((branch) => {
				return when(branch.condition.type, {
					Equals: () => {
						const key = branch.condition.key
						const conditionValue = branch.condition.value
						const searchValue = searchObject.get(key)
						if (conditionValue === searchValue) {
							logger.debug(
								`Equals Condition matched on key '${key}': '${conditionValue}' === '${searchValue}'`,
							)
							return true
						} else {
							logger.debug(
								`Equals Condition not-matched on key '${key}': '${conditionValue}' !== '${searchValue}'`,
							)
							return false
						}
					},
				})
			}) ?? null
		)
	}
}

export class ResolvedLeafWithTree<L extends ResolvedLeaf = ResolvedLeaf> {
	constructor(readonly resolvedLeaf: L, readonly tree: ArticleTree, readonly originReason: LeafOrigin) {}
}

export interface ResolvedLeaf {
	articlePath: ResolvedArticlePath
}

export class ResolvedTypedLeaf implements ResolvedLeaf {
	constructor(readonly articlePath: ResolvedArticlePath, readonly leaf: TypedLeaf) {}
}

export class ResolvedArticle implements ResolvedLeaf {
	constructor(readonly articlePath: ResolvedArticlePath, readonly leaf: Leaf) {}

	get price(): number {
		return this.leaf.price
	}
	get taxPercentage(): number {
		return this.leaf.taxPercentage
	}
}

export class ResolvedArticlePath {
	private readonly steps: ResolvedArticlePathStep[]

	constructor(steps: ResolvedArticlePathStep[]) {
		this.steps = steps
	}

	plus(branch: ResolvedArticlePathStep): ResolvedArticlePath {
		return new ResolvedArticlePath([...this.steps, branch])
	}

	asShortPath(): string {
		return toShortArticlePath(this.steps)
	}

	findStep(targetKey: BranchTargetKeyEnum): ResolvedArticlePathStep | null {
		return (
			this.steps.find((step) => {
				const condition = step.condition
				if (!condition) return false

				return when(condition.type, {
					Equals: () => {
						return condition.key === targetKey
					},
				})
			}) ?? null
		)
	}

	toArticlePaths(): ArticlePathStep[] {
		return this.steps.map((step): ArticlePathStep => {
			const [key, value] = lets(step.condition, (cond): [BranchTargetKeyEnum, string] =>
				exhaustive(cond, "type", {
					Equals: (cond) => {
						return [cond.key, cond.value]
					},
				}),
			) ?? [null, null]

			return {
				name: step.name,
				pathKey: step.pathKey,
				targetKey: key,
				targetValue: value,
			}
		})
	}

	static ofTree(tree: ArticleTree): ResolvedArticlePath {
		return new ResolvedArticlePath([
			new ResolvedArticlePathStep(tree.pathKey, tree.name, {
				type: "Equals",
				key: BranchTargetKeyEnum.Tree,
				value: tree.name,
			}),
		])
	}
}

export class ResolvedArticlePathStep {
	readonly pathKey: string
	readonly name: string
	readonly condition: BranchCondition | null

	constructor(pathKey: string, name: string, condition: BranchCondition | null) {
		this.pathKey = pathKey
		this.name = name
		this.condition = condition
	}

	static ofBranch(branch: Branch): ResolvedArticlePathStep {
		return new ResolvedArticlePathStep(branch.pathKey, branch.name, branch.condition)
	}
}

type WithPathKeys = {
	pathKey: string
}

export function toShortArticlePath(withPathKeys: WithPathKeys[]): string {
	return withPathKeys.map((it) => it.pathKey).join("-")
}

/**
 * Create an Article 'name' from all the names of the steps in the ArticlePath.
 */
export function getArticlePathName(articlePath: ArticlePath): string {
	return articlePath
		.filter((it) => {
			//Let's ignore the tree name, at least for now :/
			return it.targetKey !== BranchTargetKeyEnum.Tree
		})
		.map((it) => it.name)
		.join(" ")
}
