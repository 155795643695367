import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { FC, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useClient } from "../../Client/ClientAndUserProvider"
import { TrashIcon } from "../../Icons/Icon"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import orderConfirmStyle from "../../Orders/Components/OrderConfirmCustomerInformation/OrderConfirmCustomerInformation.module.css"
import { SbtH4 } from "../../Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { cls } from "../../Shared/cls"
import { GetConsumer } from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import editUserStyle from "../EditUser/EditUser.module.css"
import style from "./EditConsumerDetails.module.css"

type EditCustomerDetailsProps = {
	consumer: GetConsumer
	onClose: () => void
	onDone: () => void
}
const personNumberRegex = new RegExp("^(19|20)?[0-9]{6}[- ]?[0-9]{4}$")

export const IndividualInfo = z.object({
	name: z.string().min(1),
	personNumber: z.string().regex(personNumberRegex).min(10),
	customerNumber: z.string().optional(),
})

export const OrganizationInfo = z.object({
	companyName: z.string().min(1),
	organizationNumber: z.string(),
	customerNumber: z.string().optional(),
})

export type IndividualInfoType = z.input<typeof IndividualInfo>
export type OrganizationInfoType = z.input<typeof OrganizationInfo>

type UpdateConsumerDetails = {
	name: string
	customerNumber?: string
}

type UpdateConsumerDetailsCompany = {
	type: "Company"
	organizationNumber: string
} & UpdateConsumerDetails

type UpdateConsumerDetailsPrivatePerson = {
	type: "PrivatePerson"
	personNumber: string
} & UpdateConsumerDetails

export const EditConsumerDetails: FC<EditCustomerDetailsProps> = ({ consumer, onClose: extOnClose, onDone }) => {
	const client = useClient()

	const [isIndividual, setIsIndividual] = useState<boolean>(consumer.consumerDetails.type === "PrivateConsumerDetails")
	const [submitting, setSubmitting] = useState<boolean>(false)

	const {
		register: registerIndividual,
		handleSubmit: handleSubmitIndividual,
		formState: { errors: errorsIndividual, isValid: isValidIndividual },
	} = useForm<IndividualInfoType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(IndividualInfo)(data, context, options)
		},
		defaultValues:
			consumer.consumerDetails.type === "PrivateConsumerDetails"
				? {
						name: consumer.consumerDetails.name,
						personNumber: consumer.consumerDetails.personNumber,
						customerNumber: consumer.customerNumber,
				  }
				: undefined,
	})

	const {
		register: registerOrg,
		handleSubmit: handleSubmitOrg,
		formState: { errors: errorsOrg, isValid: isValidOrganization },
	} = useForm<OrganizationInfoType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(OrganizationInfo)(data, context, options)
		},
		defaultValues:
			consumer.consumerDetails.type === "CompanyConsumerDetails"
				? {
						companyName: consumer.consumerDetails.name,
						organizationNumber: consumer.consumerDetails.orgNumber,
						customerNumber: consumer.customerNumber,
				  }
				: undefined,
	})

	const onSubmit: SubmitHandler<IndividualInfoType | OrganizationInfoType> = async (data) => {
		setSubmitting(true)
		let req: UpdateConsumerDetailsCompany | UpdateConsumerDetailsPrivatePerson
		if ("personNumber" in data) {
			req = {
				type: "PrivatePerson",
				name: data.name,
				personNumber: data.personNumber,
				customerNumber: data.customerNumber,
			}
		} else {
			req = {
				type: "Company",
				name: data.companyName,
				organizationNumber: data.organizationNumber,
				customerNumber: data.customerNumber,
			}
		}

		API.postWithRetries(`/customer-portal/consumers-v1/${client.identifier}/update/${consumer.id}`, req)
			.then(() => {
				onClose()
				onDone()
			})
			.catch(() => {})
	}

	function onClose() {
		extOnClose()
	}

	function inactivateConsumer() {
		if (window.confirm("Är du säker att du vill inaktivera kunden?")) {
			setSubmitting(true)
			API.deleteWithRetries<void>(`/customer-portal/consumers-v1/${client.identifier}/delete/${consumer.id}`)
				.then(() => {
					onDone()
				})
				.catch(() => {
					setSubmitting(false)
				})
		}
	}

	return (
		<ModulePopup onClose={onClose} className={style.modal} disableCloseWithEsc={true} disableClose={submitting}>
			<div className={style.wrapper}>
				<div className={style.header}>Redigera kunduppgifter</div>
				<div style={{ marginTop: "20px" }}>
					<div className={orderConfirmStyle.btnContainer}>
						<label className={orderConfirmStyle.btnIndividualOrCompanySwitch}>
							<input
								type="checkbox"
								name="individualOrCompany"
								id="individualOrCompany"
								value="1"
								defaultChecked={isIndividual}
								onChange={(x) => setIsIndividual(x.target.checked)}
							/>
							<label
								style={{ cursor: "pointer" }}
								htmlFor="individualOrCompany"
								data-on="Privatperson"
								data-off="Företag"
								className={orderConfirmStyle.btnIndividualOrCompanySwitchInner}
							/>
						</label>
					</div>
				</div>
				{isIndividual ? (
					<>
						<form
							style={{ marginTop: "20px" }}
							key="form_individual"
							onSubmit={handleSubmitIndividual(onSubmit)}
							id="form_individual">
							<div className={orderConfirmStyle.fields}>
								<label>
									<SbtH4>Fullständigt namn*</SbtH4>
									<input
										className={orderConfirmStyle.input}
										{...registerIndividual("name")}
										placeholder="Ditt Namn"
									/>
									<SbtRHFError error={errorsIndividual.name} />
								</label>
								<label>
									<SbtH4>Personnummer*</SbtH4>
									<input
										className={orderConfirmStyle.input}
										{...registerIndividual("personNumber")}
										placeholder="19800808-8080"
									/>
									<SbtRHFError error={errorsIndividual.personNumber} />
								</label>
								<label>
									<SbtH4>Kundnummer</SbtH4>
									<input
										className={orderConfirmStyle.input}
										{...registerIndividual("customerNumber")}
										placeholder="ABC123"
									/>
									<SbtRHFError error={errorsIndividual.customerNumber} />
								</label>
							</div>
							<p className={orderConfirmStyle.mandatoryFieldInformation}>*Obligatoriskt fält</p>
						</form>
						<div
							className={editUserStyle.deleteUser}
							onClick={() => {
								inactivateConsumer()
							}}>
							<TrashIcon
								size={22}
								iconClassName={cls(editUserStyle.iconColorStroke, editUserStyle.iconColorFill)}
							/>
							<span style={{ lineHeight: "16px" }}>Inaktivera kund</span>
						</div>
						<FinalizeButton
							className={style.finalize}
							onClick={() => {
								;(document.getElementById("form_individual") as HTMLFormElement).requestSubmit()
							}}
							disabled={!isValidIndividual || submitting}
							type="submit">
							Spara
						</FinalizeButton>
					</>
				) : (
					<>
						<form
							style={{ marginTop: "20px" }}
							key="form_org"
							onSubmit={handleSubmitOrg(onSubmit)}
							id="form_org">
							<div className={orderConfirmStyle.fields}>
								<label>
									<SbtH4>Företagsnamn*</SbtH4>
									<input
										className={orderConfirmStyle.input}
										{...registerOrg("companyName")}
										placeholder="Ditt Företag"
									/>
									<SbtRHFError error={errorsOrg.companyName} />
								</label>
								<label>
									<SbtH4>Organisations&#8203;nummer</SbtH4>
									<input
										className={orderConfirmStyle.input}
										{...registerOrg("organizationNumber")}
										placeholder="555555-5555"
									/>
									<SbtRHFError error={errorsOrg.organizationNumber} />
								</label>
								<label>
									<SbtH4>Kundnummer</SbtH4>
									<input
										className={orderConfirmStyle.input}
										{...registerOrg("customerNumber")}
										placeholder="ABC123"
									/>
									<SbtRHFError error={errorsOrg.customerNumber} />
								</label>
							</div>
							<p className={orderConfirmStyle.mandatoryFieldInformation}>*Obligatoriskt fält</p>
						</form>
						<div
							className={editUserStyle.deleteUser}
							onClick={() => {
								inactivateConsumer()
							}}>
							<TrashIcon
								size={22}
								iconClassName={cls(editUserStyle.iconColorStroke, editUserStyle.iconColorFill)}
							/>
							<span style={{ lineHeight: "16px" }}>Inaktivera kund</span>
						</div>
						<FinalizeButton
							className={style.finalize}
							onClick={() => {
								;(document.getElementById("form_org") as HTMLFormElement).requestSubmit()
							}}
							disabled={!isValidOrganization || submitting}
							type="submit">
							Spara
						</FinalizeButton>
					</>
				)}
			</div>
		</ModulePopup>
	)
}
