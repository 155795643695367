import { ClientInstance } from "Client/ClientInstance"
import { forEach, isUndefined } from "lodash"
import { useEffect } from "react"
import { v4 } from "uuid"
import { getLogger } from "../../../Logging/getLogger"
import { ProductImageType } from "../../../ProductDefinitions"
import { cls } from "../../../Shared/cls"
import { lets } from "../../../Shared/lets"
import { SvgComponent } from "../../../Shared/svgComponent"
import { ReactComponent as BigBag_ExtraLarge_Img } from "./Images/BigBag_ExtraLarge.svg"
import { ReactComponent as BigBag_Large_Img } from "./Images/BigBag_Large.svg"
import { ReactComponent as BigBag_Medium_Img } from "./Images/BigBag_Medium.svg"
import { ReactComponent as BigBag_Mega_Img } from "./Images/BigBag_Mega.svg"
import { ReactComponent as BigBag_Small_Img } from "./Images/BigBag_Small.svg"
import { ReactComponent as Roll_Of_Dumpster_11m3_Short_LV11K_Img } from "./Images/Roll_Of_Dumpster_11m3_Short_LV11K.svg"
import { ReactComponent as Roll_Of_Dumpster_12m3_LV12_Img } from "./Images/Roll_Of_Dumpster_12m3_LV12.svg"
import { ReactComponent as Roll_Of_Dumpster_15m3_Short_LV15K_Img } from "./Images/Roll_Of_Dumpster_15m3_Short_LV15K.svg"
import { ReactComponent as Roll_Of_Dumpster_22m3_LV22_Img } from "./Images/Roll_Of_Dumpster_22m3_LV22.svg"
import { ReactComponent as Roll_Of_Dumpster_30m3_LV30_Img } from "./Images/Roll_Of_Dumpster_30m3_LV30.svg"
import { ReactComponent as Roll_Of_Dumpster_Locked_15m3_LVT15_Img } from "./Images/Roll_Of_Dumpster_Locked_15m3_LVT15.svg"
import { ReactComponent as Roll_Of_Dumpster_Locked_15m3_Short_LVT15K_Img } from "./Images/Roll_Of_Dumpster_Locked_15m3_Short_LVT15K.svg"
import { ReactComponent as Roll_Of_Dumpster_Locked_25m3_LVT25_Img } from "./Images/Roll_Of_Dumpster_Locked_25m3_LVT25.svg"
import { ReactComponent as Skip_Loader_10m3_LD10_Img } from "./Images/Skip_Loader_10m3_LD10.svg"
import { ReactComponent as Skip_Loader_5m3_LD5_Img } from "./Images/Skip_Loader_5m3_LD5.svg"
import { ReactComponent as Skip_Loader_Doors_10m3_LDD10_Img } from "./Images/Skip_Loader_Doors_10m3_LDD10.svg"
import { ReactComponent as Skip_Loader_Lift_8m3_LDL8_Img } from "./Images/Skip_Loader_Lift_8m3_LDL8.svg"
import { ReactComponent as Skip_Loader_Lift_8m3_LDL8WOH_Img } from "./Images/Skip_Loader_Lift_8m3_LDL8WOH.svg"
import { ReactComponent as Skip_Loader_Locked_10m3_LDT10_Img } from "./Images/Skip_Loader_Locked_10m3_LDT10.svg"
import { ReactComponent as VesselBag_Medium_Img } from "./Images/VesselBag_Medium.svg"
import { ReactComponent as Vessel_190L_Img } from "./Images/Vessel_190L.svg"
import { ReactComponent as Vessel_370L_Img } from "./Images/Vessel_370L.svg"
import { ReactComponent as Vessel_600L_WOL_Img } from "./Images/Vessel_600L_WOL.svg"
import { ReactComponent as Vessel_660L_Img } from "./Images/Vessel_660L.svg"
import { ReactComponent as NoImg } from "./NoImage.svg"
import style from "./ProductImage.module.css"

const logger = getLogger("ProductImage")

type Props = {
	categoryName?: string
	className?: string
	wrapperClassName?: string
	image?: string
	client?: ClientInstance
	onClick?: () => void
}

const imgMapping: Record<ProductImageType, SvgComponent> = {
	BagLarge: BigBag_Large_Img,
	BagMedium: BigBag_Medium_Img,
	BagSmall: BigBag_Small_Img,
	BigBagExtraLarge: BigBag_ExtraLarge_Img,
	BigBagMega: BigBag_Mega_Img,
	LD10: Skip_Loader_10m3_LD10_Img,
	LD5: Skip_Loader_5m3_LD5_Img,
	LDD10: Skip_Loader_Doors_10m3_LDD10_Img,
	LDL8: Skip_Loader_Lift_8m3_LDL8_Img,
	LDL8WOH: Skip_Loader_Lift_8m3_LDL8WOH_Img,
	LDT10: Skip_Loader_Locked_10m3_LDT10_Img,
	LV11K: Roll_Of_Dumpster_11m3_Short_LV11K_Img,
	LV12: Roll_Of_Dumpster_12m3_LV12_Img,
	LV15K: Roll_Of_Dumpster_15m3_Short_LV15K_Img,
	LV22: Roll_Of_Dumpster_22m3_LV22_Img,
	LV30: Roll_Of_Dumpster_30m3_LV30_Img,
	LVT15: Roll_Of_Dumpster_Locked_15m3_LVT15_Img,
	LVT15K: Roll_Of_Dumpster_Locked_15m3_Short_LVT15K_Img,
	LVT25: Roll_Of_Dumpster_Locked_25m3_LVT25_Img,
	Vessel190L: Vessel_190L_Img,
	Vessel370L: Vessel_370L_Img,
	Vessel600LWOL: Vessel_600L_WOL_Img,
	Vessel660L: Vessel_660L_Img,
	VesselBagMedium: VesselBag_Medium_Img,
}

const transformIdMapping: Record<string, string> = {
	BigBag_Large_LogoA: "rotateX(-9deg) rotateY(-47deg) rotateZ(0deg) translate(177px, -59px)",
	BigBag_Large_LogoB: "rotateX(0deg) rotateY(-50deg) rotateZ(0deg) translate(331px, 41px) skew(0deg, -4deg)",
	Skip_Loader_10m3_LD10_Logo: "rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(380px, 94px) skew(0deg, 7deg)",
	Skip_Loader_5m3_LD5_Logo: "rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(316px, 120px) skew(0deg, 7deg)",
	Skip_Loader_Lift_8m3_LDL8_Logo: "rotateX(0deg) rotateY(62deg) rotateZ(0deg) translate(484px, 106px) skew(0deg, 5deg)",
	Skip_Loader_Locked_10m3_LDT10_LogoA:
		"rotateX(0deg) rotateY(62deg) rotateZ(0deg) translate(585px, 116px) skew(0deg, 6deg)",
	Skip_Loader_Locked_10m3_LDT10_LogoB:
		"rotateX(0deg) rotateY(-39deg) rotateZ(0deg) translate(401px, 215px) skew(-13deg, -10deg)",
	Roll_Of_Dumpster_22m3_LV22_LogoA: "rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(357px, 152px) skew(0deg, 7deg)",
	Roll_Of_Dumpster_22m3_LV22_LogoB:
		"rotateX(0deg) rotateY(-39deg) rotateZ(0deg) translate(263px, 274px) skew(-3deg, -10deg)",
	Roll_Of_Dumpster_30m3_LV30_LogoA: "rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(342px, 62px) skew(0deg, 5deg)",
	Roll_Of_Dumpster_30m3_LV30_LogoB:
		"rotateX(0deg) rotateY(-39deg) rotateZ(0deg) translate(254px, 178px) skew(-1deg, -8deg)",
	Roll_Of_Dumpster_Locked_15m3_LVT15_LogoA:
		"rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(358px, 191px) skew(-27deg, 3deg)",
	Roll_Of_Dumpster_Locked_15m3_LVT15_LogoB:
		"rotateX(0deg) rotateY(-39deg) rotateZ(0deg) translate(280px, 249px) skew(-2deg, -4deg)",
	Roll_Of_Dumpster_Locked_25m3_LVT25_LogoA:
		"rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(401px, 105px) skew(-27deg, 5deg)",
	Roll_Of_Dumpster_Locked_25m3_LVT25_LogoB:
		"rotateX(0deg) rotateY(-39deg) rotateZ(0deg) translate(290px, 169px) skew(0deg, -4deg)",
	Vessel_190L_Logo: "rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(45px, -26px) skew(1deg, 9deg)",
	Vessel_370L_Logo: "rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(302px, 138px) skew(0deg, 9deg)",
	Vessel_660L_Logo: "rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(267px, 145px) skew(1deg, 12deg)",
	K__rl_stor_utan_lock_Logo: "rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate(267px, 145px) skew(1deg, 12deg)",
	BigBag_ExtraLarge_2C_LogoA: "rotateX(-9deg) rotateY(-47deg) rotateZ(0deg) translate(387px, 0px) skew(-1deg, 0deg)",
	BigBag_ExtraLarge_2C_LogoB: "rotateX(0deg) rotateY(-50deg) rotateZ(0deg) translate(533px, 85px) skew(0deg, -5deg)",
	BigBag_Mega_2C_LogoA: "rotateX(-9deg) rotateY(-47deg) rotateZ(0deg) translate(277px, 0px) skew(-2deg, -1deg)",
	BigBag_Mega_2C_LogoB: "rotateX(0deg) rotateY(-50deg) rotateZ(0deg) translate(466px, 85px) skew(0deg, -5deg)",
	BigBag_Small_2C_LogoA: "rotateX(-9deg) rotateY(-47deg) rotateZ(0deg) translate(187px, -59px)",
	BigBag_Small_2C_LogoB: "rotateX(0deg) rotateY(-50deg) rotateZ(0deg) translate(293px, 41px) skew(0deg, -4deg)",
	BigBag_Medium_2C_LogoA: "rotateX(-9deg) rotateY(-47deg) rotateZ(0deg) translate(391px, 8px)",
	BigBag_Medium_2C_LogoB: "rotateX(0deg) rotateY(-50deg) rotateZ(0deg) translate(512px, 80px) skew(0deg, -7deg)",
	Skip_Loader_Doors_10m3_Logo: "rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(331px, 90px) skew(0deg, 8deg)",
	Roll_Of_Dumpster_Locked_15m3_Short_LVT15K_LogoA:
		"rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(365px, -28px) skew(-33deg, 5deg)",
	Roll_Of_Dumpster_Locked_15m3_Short_LVT15K_LogoB:
		"rotateX(0deg) rotateY(-39deg) rotateZ(0deg) translate(177px, 44px) skew(-1deg, -4deg)",
	Roll_Of_Dumpster_15m3_Short_LV15K_LogoA:
		"rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(293px, 162px) skew(-2deg, 6deg)",
	Roll_Of_Dumpster_15m3_Short_LV15K_LogoB:
		"rotateX(0deg) rotateY(-39deg) rotateZ(0deg) translate(154px, 105px) skew(-1deg, -11deg)",
	Roll_Of_Dumpster_11m3_Short_LV11K_LogoA:
		"rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(287px, 142px) skew(-2deg, 6deg)",
	Roll_Of_Dumpster_11m3_Short_LV11K_LogoB:
		"rotateX(0deg) rotateY(-39deg) rotateZ(0deg) translate(224px, 279px) skew(-1deg, -11deg)",
	Skip_Loader_Lift_8m3_LDL8WOH_Logo:
		"rotateX(6deg) rotateY(60deg) rotateZ(0deg) translate(455px, 58px) skew(-1deg, 0deg)",
	Roll_Of_Dumpster_12m3_LV12_LogoA:
		"rotateX(0deg) rotateY(56deg) rotateZ(0deg) translate(287px, 142px) skew(-2deg, 6deg)",
	Roll_Of_Dumpster_12m3_LV12_LogoB:
		"rotateX(0deg) rotateY(-39deg) rotateZ(0deg) translate(369px, 284px) skew(-1deg, -11deg)",
	VesselBag_Medium_Logo: "rotateX(0deg) rotateY(50deg) rotateZ(0deg) translate(490px, 109px) skew(0deg, 5deg)",
}

export const ProductImage = (props: Props) => {
	let colorOverride = props.client?.getBrandColorOverrideOrNull(props.categoryName)

	const wrapperId = v4()
	useEffect(() => {
		const wrapper = document.getElementById(wrapperId)
		const svgElement: SVGElement | undefined = wrapper?.getElementsByTagName("svg")[0]

		if (svgElement && colorOverride) {
			const defs: SVGDefsElement | null = svgElement.querySelector("defs")
			const style: HTMLStyleElement | null | undefined =
				defs?.querySelector("style") || svgElement.querySelector("style")
			if (style) {
				if (colorOverride.productBaseColor) {
					style.textContent =
						style.textContent?.replace(
							"fill:var(--product-base-color)",
							"fill:" + colorOverride.productBaseColor,
						) ?? null
				}

				if (colorOverride.productSecondaryColor) {
					style.textContent =
						style.textContent?.replace(
							"fill:var(--product-secondary-color)",
							"fill:" + colorOverride.productSecondaryColor,
						) ?? null
				}

				if (colorOverride.productDetailsColor) {
					style.textContent =
						style.textContent?.replace(
							"fill:var(--product-details-color)",
							"fill:" + colorOverride.productDetailsColor,
						) ?? null
				}
			}
		}

		if (!isUndefined(props.client) && props.client.features.orderUIProductImagesWithLogos) {
			const patternImage1: SVGImageElement | undefined = wrapper
				?.getElementsByTagName("svg")[0]
				?.getElementById("pattern")
				?.getElementsByTagName("image")[0]
			const patternImage2: SVGImageElement | undefined = wrapper
				?.getElementsByTagName("svg")[0]
				?.getElementById("pattern-2")
				?.getElementsByTagName("image")[0]
			const logoHref = props.client.getLogoUrlWithCategoryOverride(props.categoryName)
			if (patternImage1) {
				lets(logoHref, (it) => patternImage1.setAttribute("href", it))
			}
			if (patternImage2) {
				lets(logoHref, (it) => patternImage2.setAttribute("href", it))
			}

			Object.keys(transformIdMapping).forEach((x) => {
				const elements = document.getElementsByClassName(x)

				if (elements && elements.length > 0) {
					const transformIdMappingElement = transformIdMapping[x]
					if (transformIdMappingElement != null) {
						forEach(elements, (element) => {
							;(element as HTMLElement).style.transform = transformIdMappingElement
						})
					}
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const getImg = () => {
		const key = props.image as keyof typeof imgMapping
		const img = imgMapping[key]

		if (!img) {
			logger.debug("Image missing for key: " + key)
			return NoImg
		}
		return img
	}

	const Image = getImg()

	return (
		<div onClick={props.onClick} id={wrapperId} className={cls(style.wrapper, props.wrapperClassName)}>
			<Image
				className={cls(style.productImage, props.className)}
				style={{ color: colorOverride?.productBaseColor ?? "var(--product-base-color)" }}
			/>
		</div>
	)
}
