import { createRef, useEffect } from "react"
import { getLogger } from "../../../Logging/getLogger"
import { TransportationType } from "../../../ProductDefinitions"
import { SvgComponent } from "../../../Shared/svgComponent"
import { ReactComponent as CraneTruckSvg } from "./crane_truck.svg"
import { ReactComponent as DeliveryTruckSvg } from "./delivery_truck.svg"
import { ReactComponent as RollOfDumpsterTruckSvg } from "./roll_of_dumpster_truck.svg"
import { ReactComponent as SkipLoaderTruckSvg } from "./skip_loader_truck.svg"
import { ReactComponent as TailGateLiftTruckSvg } from "./tail_gate_lift_truck.svg"
import { ReactComponent as TipperTruckSvg } from "./tipper_truck.svg"
import style from "./TransportDescription.module.css"

const logger = getLogger("TransportDescription")

const transportTypeToSvg: Record<TransportationType, SvgComponent> = {
	CraneTruck: CraneTruckSvg,
	SkipLoaderTruck: SkipLoaderTruckSvg,
	RollOfDumpsterTruck: RollOfDumpsterTruckSvg,
	TailGateLiftTruck: TailGateLiftTruckSvg,
	DeliveryTruck: DeliveryTruckSvg,
	TipperTruck: TipperTruckSvg,
}

type Props = {
	type: TransportationType
	data: Record<string, string>
	id?: string
}

/**
 * Sets height, width, length etc on a transport definition SVG
 *
 * @param svgElement
 * @param data
 */
export function setTransportSvgDetails(svgElement: SVGSVGElement, data: Record<string, string>) {
	const allKeys = Object.getOwnPropertyNames(data)
	const tags = svgElement.getElementsByTagName("tspan") as HTMLCollectionOf<SVGTSpanElement>

	const unmatchedTags = [...tags].filter((tag) => {
		let notFound = true
		for (const key of allKeys) {
			const value = data[key]
			if (value === undefined) {
				logger.error(`Undefined value for key: ${key}`)
				continue
			}

			if (tag.textContent === undefined) {
				logger.debug("textContent not defined on tag: " + JSON.stringify(tag))
				notFound = notFound && true
				break
			}

			if (tag.textContent?.includes(key)) {
				const oldText = tag.textContent ?? ""
				const newText = oldText.replace(key, value)
				tag.textContent = newText

				logger.debug(`Found '${key}', replaced: '${oldText}', with: '${newText}'`)
				notFound = notFound && false
				break
			}

			notFound = notFound && true
		}
		return notFound
	})

	if (unmatchedTags.length > 0) {
		logger.debug(`unMatchedTags: ${unmatchedTags.map((tag) => tag.textContent)}`)
	}
}

export const TransportDescription = (props: Props) => {
	const ref = createRef<SVGSVGElement>()

	useEffect(() => {
		if (ref.current === null) {
			logger.error("Ref not set yet")
			return
		}

		setTransportSvgDetails(ref.current, props.data)
	}, [props.data, ref])

	const Svg = transportTypeToSvg[props.type]

	return <>{Svg ? <Svg id={props.id} width={undefined} height={undefined} ref={ref} className={style.svg} /> : null}</>
}
