import { ProductServiceUnit } from "../../Client/ProductDefinitionsByCategories"

export type NewOrderRequest = {
	consumer: Consumer
	payment: OrderPayment
	licenseAgreement: OrderLicenseAgreements
	orders: NewOrder[]
	consumerId: string | null
	discountCodeIdentifier: string | null
}

export type OrderPayment = {
	paymentMethod: PaymentMethod
}

export type PaymentMethod = Other | KlarnaPayments

/**
 * A payment method out of Skrappy's control, this is the "old" way.
 */
export type Other = {
	type: "Other"
}

/**
 * The Order is to be paid with Klarna, and the supplied token is to be used to finalize the Klarna order.
 */
export type KlarnaPayments = {
	type: "KlarnaPayments"
	authorizationToken: string
}

export type NewOrder = {
	clientSideKey: string
	marking: string
	date: string | null
	dateSlot: NewOrderDate | null
	time: NewOrderTime
	/**
	 * @Deprecated
	 */
	contact?: NewOrderContact
	contacts: NewOrderContact[]
	comment: string
	address: NewOrderAddress
	expectedArticles: ExpectedNewOrderArticle[]
	products: NewOrderProduct[]
	execution: NewOrderExecution
	files: NewOrderFile[] | null
}

export type NewOrderDate = {
	dateSlotId: string
}

export type NewOrderFile = NewOrderFileImage

export type NewOrderFileImage = {
	type: "NewOrderFileImage"
	id: string
}

export type NewOrderProduct = {
	productDefinitionId: ProductDefinitionId
	amount: number
	serviceId?: ProductServiceId
	wasteTypeId?: ProductWasteTypeId
	packagingMethod?: NewOrderProductPackagingMethod
	expectedArticles: ExpectedNewOrderArticle[]
}

export type NewOrderProductPackagingMethod = {
	name: string
	multiplier: number
	quantity: number
	id: string
	transportId: string
	unit: ProductServiceUnit
}

export type NewOrderTime = {
	timeSlotId: ProductTimeSlotId
	timeExact: string | null
}

export type NewOrderAddress = {
	street: string
	zipcode: string | null
	city: string
	country: string | null
	coordinates: google.maps.LatLngLiteral | null
}

export type NewOrderExecution = {
	deliveryCoordinates: google.maps.LatLngLiteral | null
}

export type OrderLicenseAgreements = {
	skrappy: AgreementOptions
	client?: AgreementOptions
	dynamic?: DynamicOrderAgreement[]
}

export type DynamicOrderAgreement = DynamicOrderUrlAgreement | DynamicOrderTextAgreement
export enum DynamicOrderAgreementTypes {
	URL = "URL",
	Text = "Text",
}
export type DynamicOrderUrlAgreement = {
	type: DynamicOrderAgreementTypes.URL
	url: string
	value: AgreementOptions
}
export type DynamicOrderTextAgreement = {
	type: DynamicOrderAgreementTypes.Text
	text: string
	value: AgreementOptions
}

export enum AgreementOptions {
	AGREE = "AGREE",
	DECLINED = "DECLINED",
}

export type Consumer = Company | Private

export type Company = {
	type: "Company"
	name: string
	orgNumber: string | null
	email: string | null
	phone: string | null
	ordererName: string | null
}

export type Private = {
	type: "Private"
	name: string
	personNumber: string | null
	email: string | null
	phone: string | null
	ordererName: string | null
}

export type NewOrderContact = {
	name: string
	phone: string
}

export type ProductDefinitionId = string

export type ProductServiceId = string

export type ProductTimeSlotId = string

export type ProductWasteTypeId = string

export type ExpectedNewOrderArticle = {
	articlePath: string
	/**
	 * A number typed as a string to not lose precision.
	 */
	price: string
	/**
	 * Number typed as a string to not lose precision.
	 */
	taxPercent: string
}

export type NewOrderResponse = {
	anonymousId: string
	clientIdentifier: string
	collectionNumber: number
	orderNumbers: number[]
}
