import { sortBy } from "lodash"
import { FieldModuleBox } from "Orders/Components/FieldModuleBox/FieldModuleBox"
import { FieldModuleBoxWrapper } from "Orders/Components/FieldModuleBox/FieldModuleBoxWrapper"
import { FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { ModulChooserButton } from "Orders/Components/ModulChooserButton/ModulChooserButton"
import { ModulePopup, ModulePopupProps } from "Orders/Components/ModulePopup/ModulePopup"
import { MbtH5 } from "Orders/Components/Text/MbtH5/MbtH5"
import { SbtH2 } from "Orders/Components/Text/SbtH2/SbtH2"
import { FC, useRef, useState } from "react"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ProductTimeSlot, TimeSlotId } from "../../Client/ProductDefinitionsByCategories"
import { NativeModuleTimePicker } from "../Components/Form/NativeModuleTimePicker/NativeModuleTimePicker"
import { SbtH6 } from "../Components/Text/SbtH6/SbtH6"
import { OrderItemTime } from "../order-data-model"
import style from "./TimeSelectModule.module.css"

type Props = ModulePopupProps & {
	onTimeSelected: (timeslotId: string, timeName: string, specificTime: boolean) => void
	allowedValues: string[]
	allTimeSlots: { [key: TimeSlotId]: ProductTimeSlot }
	defaultValue: OrderItemTime | undefined
}

export const TimeSelectModule: FC<Props> = ({
	onTimeSelected,
	allowedValues,
	defaultValue,
	onClose,
	allTimeSlots,
	...props
}) => {
	const client = useClient()
	const timeSlots = useRef(
		sortBy(
			(allowedValues ?? []).map((x) => client.possibleTimeSlots[x]),
			"order",
		),
	)

	const [selected, setSelected] = useState<OrderItemTime | null>(() => {
		if (defaultValue && allowedValues.includes(defaultValue.timeslotId)) {
			return defaultValue
		}

		return null
	})

	const [specificTime, setSpecificTime] = useState<string | null>(null)

	const onDone = () => {
		if (selected) {
			if (selected.specificTime && specificTime) {
				onTimeSelected(selected.timeslotId, specificTime, true)
			} else if (!selected.specificTime) {
				onTimeSelected(selected!.timeslotId, selected.timeName, false)
			}
		}

		onClose()
	}

	return (
		<ModulePopup onClose={onClose} {...props}>
			<div>
				<SbtH2>Tid</SbtH2>
				<SbtH6>
					<span style={{ fontWeight: "normal" }}>Välj den tid du önskar att din beställning utförs</span>
				</SbtH6>
				<FieldModuleBoxWrapper className={style.timeSlotsWrapper}>
					{timeSlots.current.map((timeSlot) => {
						return (
							<FieldModuleBox
								className={timeSlot.specificTime ? style.specificTimeslot : style.normalTimeslot}
								key={timeSlot.id}
								selected={selected !== null && selected.timeslotId === timeSlot.id}>
								<div>
									<MbtH5>{timeSlot.name}</MbtH5>
								</div>
								{timeSlot.specificTime ? (
									<NativeModuleTimePicker
										className={style.timeSelectInput}
										selected={selected !== null && selected.timeslotId === timeSlot.id}
										onTimeSelected={(time) => {
											setSelected({
												timeslotId: timeSlot.id,
												timeValue: time,
												specificTime: true,
											})
											setSpecificTime(time)
										}}
										defaultValue={
											defaultValue
												? defaultValue.specificTime
													? defaultValue.timeValue
													: defaultValue.timeName
												: null
										}
									/>
								) : (
									<ModulChooserButton
										selected={selected !== null && selected.timeslotId === timeSlot.id}
										onClick={() => {
											setSelected({
												timeslotId: timeSlot.id,
												timeName: timeSlot.name,
												specificTime: false,
											})
										}}>
										{selected !== null && selected.timeslotId === timeSlot.id ? "Vald" : "Välj"}
									</ModulChooserButton>
								)}
								<span className={style.timeslotDescription}>{timeSlot.description}</span>
							</FieldModuleBox>
						)
					})}
				</FieldModuleBoxWrapper>
				<FinalizeButton
					className={style.finalize}
					disabled={!selected || (allTimeSlots[selected.timeslotId].specificTime && !specificTime)}
					onClick={() => onDone()}>
					Klar
				</FinalizeButton>
			</div>
		</ModulePopup>
	)
}
