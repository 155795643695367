import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { FC, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useClient } from "../../Client/ClientAndUserProvider"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ToggleSwitch } from "../../Orders/Components/Form/ToggleSwitch/ToggleSwitch"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import { SbtH4 } from "../../Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { GetProject } from "../CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import style from "./EditProjectMarkingModal.module.css"

type Props = {
	marking: string
	projectId: string
	consumerId: string
	orderAmount: number
	onClose: () => void
	onDone: (project: GetProject) => void
}

const EditMarkingSchema = z.object({
	marking: z.string().min(1, "En märkning krävs"),
	updateRelatedOrders: z.boolean(),
})

type EditMarkingSchemaType = z.input<typeof EditMarkingSchema>

type UpdateProjectMarking = {
	id: string
	marking: string
	updateRelatedOrders: boolean
}

export const EditProjectMarkingModal: FC<Props> = (props: Props) => {
	const client = useClient()

	const [submitting, setSubmitting] = useState(false)

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors, isValid },
	} = useForm<EditMarkingSchemaType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(EditMarkingSchema)(data, context, options)
		},
		defaultValues: {
			marking: props.marking,
			updateRelatedOrders: true,
		},
	})

	const onSubmit: SubmitHandler<EditMarkingSchemaType> = async (formData: EditMarkingSchemaType) => {
		setSubmitting(true)

		const request: UpdateProjectMarking = {
			id: props.projectId,
			marking: formData.marking,
			updateRelatedOrders: formData.updateRelatedOrders,
		}

		API.patch<GetProject, UpdateProjectMarking>(
			`/customer-portal/project-definitions-v1/${client.identifier}/${props.consumerId}/${props.projectId}/marking`,
			request,
		).then(
			(res) => {
				props.onDone(res)
				props.onClose()
			},
			() => {
				setSubmitting(false)
			},
		)
	}

	return (
		<ModulePopup
			disableClose={false}
			className={style.modal}
			onClose={() => {
				props.onClose()
			}}>
			<div className={style.wrapper}>
				<div className={style.header}>Redigera märkning</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div style={{ overflowY: "auto" }}>
						<label className={style.inputField}>
							<SbtH4>Märkning*</SbtH4>
							<input {...register("marking")} placeholder="Märkning/projektnamn/littera" />
							<SbtRHFError error={errors.marking} />
						</label>
					</div>

					<div className={style.toggleWrapper}>
						<ToggleSwitch
							checked={watch("updateRelatedOrders")}
							text={"Uppdatera märkning på relaterade ordrar"}
							onChange={(x) => {
								setValue("updateRelatedOrders", x.target.checked)
							}}
						/>
						<span className={style.emphasisText}>
							Detta projekt har {props.orderAmount} ordrar kopplade till sig
						</span>
						<span className={style.warningText}>
							Observera att om ni inte uppdaterar märkningen på ordrarna så kan kopplingen mellan ordrarna och
							projektet försvinna, och inte längre synas i projekt-vyn
						</span>
					</div>

					<FinalizeButton className={style.finalize} disabled={!isValid || submitting} type="submit">
						Spara
						{submitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
				</form>
			</div>
		</ModulePopup>
	)
}
